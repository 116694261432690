import React, { useRef } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from "chart.js";
import {
    Chart,
    getDatasetAtEvent,
    getElementAtEvent,
} from "react-chartjs-2";
import PieChart from "./PieChart";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: "right" as const
        },
        title: {
            display: true,
            text: "Visit Counts By Provider from the selected Time Frame"
        }
    }
};



const BarChart = ({ data, pieData, handleClick }) => {
    const chartRef = useRef<ChartJS>(null);

    const labels = data?.label || []
    const newData = data?.data || []

    const onClick = (event) => {

        const { current: chart } = chartRef;

        if (!chart) {
            return;
        }
        getValues(getElementAtEvent(chart, event));
    };

    const getValues = (dataset) => {

        if (!dataset.length) return;

        const datasetIndex = dataset[0].datasetIndex;
        const labelIndex = datasetIndex !== 0 ? 1 + dataset[0].datasetIndex : dataset[0].datasetIndex
        // provider name & date
        handleClick({ providerName: dataSet?.datasets[datasetIndex].label, date: dataSet.labels[labelIndex] })
    };

    const dataSet = {
        labels,
        datasets: newData
    };

    if (!data.data) {
        return null
    }

    return (
        <div className="bar-withPie-wrapper" >
            <Chart
                type="bar"
                options={options}
                data={dataSet}
                ref={chartRef}
                onClick={onClick}
            />
            <PieChart data={pieData} />
        </div>
    )
}

export default BarChart