import React, { useEffect, useState, Fragment } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import Caret from "../../../images/Caret01.svg";
import ArrowOut from "../../../images/ArrowSquareOut.svg";
import CircleCheck from "../../../images/CircleCheck.svg";
import WarningCircle from "../../../images/WarningCircle.svg";
import "./Prescription.scss";
import api from "../../../api";
import Question from "../../../images/Question.svg";

import {
  STOP_PRESCRIPTION,
  STOP_PRESCRIPTION_NO,
  STOP_PRESCRIPTION_NEW,
  DECLINED,
  NO_PRESCRIPTION,
  initialPresStatus,
  followUpPresStatus,
  PRESCRIPTION_WRITTEN,
  NEW_PRESCRIPTION,
  GetStatusString,
} from "../../../constants/Encounter";
import { formatPhoneNumber } from "../../../utils";
import { IPrescriptionDetails } from "../../../constants/Types";
import {
  hasAnyPermission,
  hasPermission,
  PERMISSION_SEE_PATIENTS,
  PERMISSION_THERAPIST,
} from "../../../constants/Permissions";

const cleanText = (value) => {
  let str = value;
  if (!str) {
    return value;
  }
  str = str.toLowerCase();
  str = str.replace(/\s/g, "");
  return str;
};

const PrecribeView = ({ additionalStatus, permissions, selectedStatus, ...props }) => {
  const disabled =
    additionalStatus === "No Prescription" || selectedStatus === "No Prescription" || props.loading || !hasPermission(permissions, PERMISSION_SEE_PATIENTS);
  return (
    <div className="prescribe ml-4 mt-2">
      <button
        disabled={disabled}
        className={` prescribe-btn ${props.prescriptionDetails?.length > 0 && props.cn} ${disabled && "disabled"
          }`}
        onClick={props.onPrescriptionLink}
      >
        Write Prescription
        <ArrowOut />
      </button>
    </div>
  );
};

let dosespotStatus: string[] = [];
let dosespotStatusIcon: (string | null)[] = [];
let dosespotStatusBold: string[] = [];
let dosespotRemove: boolean[] = [];
let dosespotOrigin: IPrescriptionDetails[] = [];

function Prescription({ chiefComplaint = "", encounterID, ...props }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedFormulary, setSelectedFormulary] = useState<any[]>([]);
  const [formularyOptions, setFormularyOptions] = useState<any>([]);
  const [formularyName, setFormularyName] = useState("");

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [loading, setIsLoading] = useState(false);
  const [pharmacyMatch, setPharmacyMatch] = useState<Boolean>(false);
  const [prescribeOutside, setPrescribeOutside] = useState<Boolean>(false);
  const [description, setDescription] = useState<Boolean>(false);

  const prescriptionDetails = props.note.prescriptionDetails;
  const additionalStatus =
    props.details.additionalStatus && props.details.additionalStatus.length > 0
      ? props.details.additionalStatus[0]
      : "";

  const isFollowup = props.details?.followUp || false;
  const pharmacyID = props.details?.medicalProfile?.preferredPharmacy?.pharmacyID;
  const formularyPharmacyMatch = props.details?.prescribeOutsideFormulary || false;
  const pharmacyName = props.details?.medicalProfile?.preferredPharmacy?.storeName;
  const pharmacyAddress = props.details?.medicalProfile?.preferredPharmacy?.address1;
  const pharmacyIndex = props.details?.medicalProfile?.preferredPharmacy?.zip;
  const pharmacyState = props.details?.medicalProfile?.preferredPharmacy?.state;
  const pharmacyCity = props.details?.medicalProfile?.preferredPharmacy?.city;
  const pharmacyPhone = formatPhoneNumber(
    props.details?.medicalProfile?.preferredPharmacy?.primaryPhone,
  );

  const presStatus = !!isFollowup ? followUpPresStatus : initialPresStatus;

  let selectedStatus = localStorage.getItem("prescriptionStatus" + encounterID);

  // Check if the chief complaint matched to a category in the formulary of the preferred pharmacy
  let formularyMatched = formularyOptions?.some((item) =>
    item?.Condition?.some((condition) => cleanText(condition) === cleanText(chiefComplaint)),
  );
  const defineDosespotStatus = () => {
    // no prescription => set default status
    dosespotStatusIcon = selectedFormulary?.map(() => null);
    dosespotRemove = selectedFormulary?.map(() => true);
    dosespotStatusBold = selectedFormulary?.map(() => " ");

    const unselectedPrescriptionItems = prescriptionDetails?.filter(
      (o1) => !selectedFormulary?.some((o2) => o1.rxcui === o2.RXCUI),
    );
    dosespotOrigin = unselectedPrescriptionItems ? [...unselectedPrescriptionItems] : [];

    if (!prescriptionDetails || !prescriptionDetails?.length) {
      dosespotStatus = selectedFormulary?.map(() => "Not yet entered in Dosespot");
    } else {
      // dosespot info available
      // check rxcui
      selectedFormulary.map((formularyItem, idx) => {
        let dosespotPrescriptionItem = prescriptionDetails.find(
          (prescription) => prescription.rxcui == formularyItem.RXCUI,
        );
        if (!selectedFormulary && prescriptionDetails && !!prescriptionDetails.length) {
        }
        if (dosespotPrescriptionItem) {
          if (dosespotPrescriptionItem.rxcui.includes('999999')){
            dosespotStatus[idx] =
            dosespotPrescriptionItem.status === "Entered" ||
              dosespotPrescriptionItem.status === "Edited"
              ? "Entered in Dosespot"
              : dosespotPrescriptionItem.status === "Sending"
                ? "Sending in Dosespot"
                : "eRx Sent in Dosespot";

            dosespotStatusBold[idx] = " bold";
            dosespotStatusIcon[idx] = "ok";
            if (dosespotStatus[idx] === "eRx Sent in Dosespot") {
              dosespotRemove[idx] = false;
            }
          }else{
            // check fields
            const quantity = formularyItem.Quantity == dosespotPrescriptionItem.quantity;
            const refillqty = formularyItem.Refills == dosespotPrescriptionItem.refillqty;
            const daysSupply = formularyItem.DaysSupply == dosespotPrescriptionItem.daysSupply;
            const error = dosespotPrescriptionItem.error;

            if (error) {
              dosespotRemove[idx] = false;
            }
            // all match -> show dosespot status
            if (quantity && refillqty && daysSupply) {
              dosespotStatus[idx] =
                dosespotPrescriptionItem.status === "Entered" ||
                  dosespotPrescriptionItem.status === "Edited"
                  ? "Entered in Dosespot"
                  : dosespotPrescriptionItem.status === "Sending"
                    ? "Sending in Dosespot"
                    : "eRx Sent in Dosespot";

              dosespotStatusBold[idx] = " bold";
              dosespotStatusIcon[idx] = "ok";
              if (dosespotStatus[idx] === "eRx Sent in Dosespot") {
                dosespotRemove[idx] = false;
              }
            } else {
              const errors: string[] = [];
              if (!quantity) errors.push("‘Quantity’");
              if (!refillqty) errors.push("‘Refills’");
              if (!daysSupply) errors.push("‘Days Supply’");
              const message =
                errors.join(", ") +
                " in Dosespot does not match the EMR formulary. Please correct errors or remove this entry.";
              dosespotStatus[idx] = message;

              dosespotStatusIcon[idx] = "warning";
            }
          }
        } else {
          // error
          dosespotStatusIcon[idx] = "warning";
          dosespotStatus[idx] =
            "Prescription not matched in Dosespot. Please correct errors or remove this entry.";
        }
      });
    }
    const prescriptionValidated = dosespotRemove?.reduce((prev, current) => {
      return !current && prev;
    }, true);

    props.onPrescriptionValidated(prescriptionValidated);
  };

  const checkContents = (expected, value) => {
    return value.every((item) => expected.includes(item));
  };

  const checkStatuses = (pd) => {
    return pd.every((item) => item.status === "PharmacyVerified");
  };

  useEffect(() => {
    defineDosespotStatus();
  }, [prescriptionDetails, selectedFormulary]);

  useEffect(() => {
    if (
      prescriptionDetails?.length > 0 &&
      (additionalStatus === STOP_PRESCRIPTION || additionalStatus.includes(STOP_PRESCRIPTION))
    ) {
      props.saveAddtionalStatus(STOP_PRESCRIPTION_NEW);
      window.localStorage.setItem("prescriptionStatus" + encounterID, STOP_PRESCRIPTION_NEW);
    } else if (
      prescriptionDetails?.length > 0 &&
      dosespotStatus.length > 0 &&
      !!checkContents(["eRx Sent in Dosespot"], dosespotStatus)
    ) {
      props.saveAddtionalStatus(PRESCRIPTION_WRITTEN);
      window.localStorage.setItem("prescriptionStatus" + encounterID, PRESCRIPTION_WRITTEN);
    } else if (
      prescriptionDetails?.length > 0 &&
      !!prescribeOutside &&
      !checkStatuses(prescriptionDetails)
    ) {
      props.saveAddtionalStatus("");
      window.localStorage.setItem("prescriptionStatus" + encounterID, "");
    } else if (
      prescriptionDetails?.length > 0 &&
      !!prescribeOutside &&
      !!checkStatuses(prescriptionDetails)
    ) {
      props.saveAddtionalStatus(PRESCRIPTION_WRITTEN);
      window.localStorage.setItem("prescriptionStatus" + encounterID, PRESCRIPTION_WRITTEN);
    } else if (prescriptionDetails?.length > 0 && !!checkStatuses(prescriptionDetails)) {
      props.saveAddtionalStatus(PRESCRIPTION_WRITTEN);
      window.localStorage.setItem("prescriptionStatus" + encounterID, PRESCRIPTION_WRITTEN);
    }
  }, [prescriptionDetails]);

  useEffect(() => {
    if (props.note.selectedFomulary) {
      setSelectedFormulary(props.note.selectedFomulary.map((item) => JSON.parse(item)));
    } else {
      setSelectedFormulary([]);
    }

    let prevStatus = window.localStorage.getItem("prescriptionStatus" + encounterID);

    if (selectedFormulary?.length > 0 && prevStatus === STOP_PRESCRIPTION_NO) {
      window.localStorage.setItem("prescriptionStatus" + encounterID, STOP_PRESCRIPTION);
    }
  }, [props.note.selectedFomulary, encounterID]);

  useEffect(() => {
    //load formulary list
    async function getFormularyList() {
      try {
        if (props?.currentUser?.currentPracticeID, chiefComplaint) {
          const singleForm = await api.Encounters.getVisitFormularies(props?.currentUser?.currentPracticeID, chiefComplaint)
          let alphabetOrder = singleForm?.Items.sort((a, b) => {
            a.ProductName.localeCompare(b.ProductName)
          })
          let formName = singleForm?.Name ? singleForm.Name : ""
          setFormularyName(formName);
          setFormularyOptions(alphabetOrder)

        }
      } catch (e) {
        console.log(e);
      }
    }
    setPrescribeOutside(formularyPharmacyMatch);
    getFormularyList();
  }, []);

  useEffect(() => {
    //filter by chief complaint
    let formularyList = formularyOptions?.filter(
      (item) =>
        !!chiefComplaint &&
        item?.Condition?.some((condition) => cleanText(condition) === cleanText(chiefComplaint)),
    );

    if (formularyList.length > 0) {
      let alphabetizeList = formularyList.sort((a, b) =>
        a.ProductName.localeCompare(b.ProductName),
      );
      setFormularyOptions(alphabetizeList);
    }
  }, [chiefComplaint, formularyName]);

  const onSelect = (idx: number) => () => {
    let formulary = formularyOptions[idx];
    let options = [...selectedFormulary];
    options.push(formulary);

    let index = selectedFormulary.findIndex((item) => item.ProductName === formulary.ProductName);

    if (index > -1) {
      return;
    }

    setSelectedFormulary(options);
    props.onNoteUpdated(
      "selectedFomulary",
      options.map((option) => JSON.stringify(option)),
    );
    if (
      additionalStatus === STOP_PRESCRIPTION ||
      additionalStatus.includes(STOP_PRESCRIPTION) ||
      additionalStatus === STOP_PRESCRIPTION_NEW ||
      additionalStatus.includes(STOP_PRESCRIPTION_NEW)
    ) {
      props.saveAddtionalStatus(STOP_PRESCRIPTION_NEW);
    } else {
      props.saveAddtionalStatus("");
    }
  };

  const onClick = (status: string) => () => {
    if (selectedFormulary?.length > 0 || selectedStatus === STOP_PRESCRIPTION_NO) {
      return;
    } else if (
      status === DECLINED &&
      (additionalStatus === DECLINED || additionalStatus.includes(DECLINED))
    ) {
      props.saveAddtionalStatus("");
    } else if (
      (additionalStatus === STOP_PRESCRIPTION ||
        additionalStatus?.status === STOP_PRESCRIPTION ||
        additionalStatus.includes(STOP_PRESCRIPTION)) &&
      selectedStatus != STOP_PRESCRIPTION_NO
    ) {
      props.saveAddtionalStatus(STOP_PRESCRIPTION, prescribeOutside);
      window.localStorage.setItem("prescriptionStatus" + encounterID, STOP_PRESCRIPTION_NO);
    } else if (
      (status == additionalStatus ||
        status === additionalStatus?.status ||
        additionalStatus.includes(status)) &&
      selectedStatus === NO_PRESCRIPTION
    ) {
      props.saveAddtionalStatus("", prescribeOutside);
      window.localStorage.setItem("prescriptionStatus" + encounterID, "");
    } else {
      props.saveAddtionalStatus(status, prescribeOutside);
      window.localStorage.setItem("prescriptionStatus" + encounterID, status);
    }
  };

  //"Stop Prescription + No" state is just for rendering the UI views
  const onStatusChange = (status) => () => {
    if (loading) {
      return;
    }

    let prevStatus = window.localStorage.getItem("prescriptionStatus" + encounterID);
    let newStatus = status;

    switch (true) {
      case status === STOP_PRESCRIPTION && prevStatus === "":
        newStatus = STOP_PRESCRIPTION;
        break;
      case status === STOP_PRESCRIPTION && prevStatus === NO_PRESCRIPTION:
      case status === NO_PRESCRIPTION && prevStatus === STOP_PRESCRIPTION:
        newStatus = STOP_PRESCRIPTION_NO;
        break;
      case prevStatus === STOP_PRESCRIPTION_NO && status === NO_PRESCRIPTION:
        newStatus = STOP_PRESCRIPTION;
        break;
      case prevStatus === STOP_PRESCRIPTION_NO && status === STOP_PRESCRIPTION:
        newStatus = STOP_PRESCRIPTION;
        break;
      case status === STOP_PRESCRIPTION && selectedFormulary.length > 0:
        newStatus = STOP_PRESCRIPTION_NEW;
        break;
      case prevStatus === status:
      case prevStatus === STOP_PRESCRIPTION_NEW && status === STOP_PRESCRIPTION:
        if (selectedFormulary?.length > 0) {
          newStatus = NEW_PRESCRIPTION;
        } else {
          newStatus = "";
        }

        break;
    }

    if (newStatus === STOP_PRESCRIPTION_NO) {
      props.saveAddtionalStatus(STOP_PRESCRIPTION);
    } else if (!pharmacyMatch && newStatus === STOP_PRESCRIPTION_NO) {
      props.saveAddtionalStatus(STOP_PRESCRIPTION, prescribeOutside);
    } else if (selectedFormulary?.length > 0) {
      props.saveAddtionalStatus(newStatus);
    } else {
      props.saveAddtionalStatus(newStatus, prescribeOutside);
    }
    window.localStorage.setItem("prescriptionStatus" + encounterID, newStatus);
  };

  const onRemove = (idx) => () => {
    let options = [...selectedFormulary];
    options.splice(idx, 1);
    setSelectedFormulary(options);
    props.onNoteUpdated(
      "selectedFomulary",
      options.map((option) => JSON.stringify(option)),
    );
  };

  const disabled = (name) => {
    const index = selectedFormulary.findIndex((item) => item.ProductName === name);
    if (index === -1) {
      return false;
    }
    return true;
  };

  const handlePrescribeOutside = (value) => {
    if (
      additionalStatus.includes(STOP_PRESCRIPTION) ||
      additionalStatus === STOP_PRESCRIPTION ||
      additionalStatus?.status === STOP_PRESCRIPTION ||
      selectedStatus === STOP_PRESCRIPTION_NO
    ) {
      props.saveAddtionalStatus(additionalStatus, value);
      window.localStorage.setItem("prescriptionStatus" + encounterID, additionalStatus);
    } else {
      props.saveAddtionalStatus("", value);
    }
    setPrescribeOutside(value);
  };

  const onClickQ = (e) => {
    e.stopPropagation();
    if (selectedFormulary?.length > 0) {
      return;
    } else {
      setDescription(!description);
    }
  };

  const displayRXBtns = (
    formularyOptions: any,
    selectedFormulary: any,
    prescriptionDetails: any,
    prescribeOutside: Boolean,
  ) => {
    if (
      (!selectedFormulary || selectedFormulary.length == 0) &&
      formularyOptions?.length > 0 &&
      !prescribeOutside
    )
      return true;
    else if (
      formularyOptions?.length > 0 &&
      (!prescriptionDetails || prescriptionDetails?.length === 0) &&
      (!selectedFormulary || selectedFormulary?.length === 0)
    )
      return true;
    else if (
      (!formularyOptions || formularyOptions?.length == 0) &&
      (!prescriptionDetails || prescriptionDetails?.length === 0) &&
      !prescribeOutside
    ) {
      return true;
    }
    return false;
  };

  const lengthCheck = formularyName.concat(chiefComplaint!).length > 30;
  const showRXBtns = displayRXBtns(
    formularyOptions,
    selectedFormulary,
    prescriptionDetails,
    prescribeOutside,
  );

  return (
    <div className="prescription">
      <div className="prescription-options">
        <div>
          <p style={{ fontSize: 12 }}>
            <span className="font-weight-bold">Patient-Selected Pharmacy - </span>
            {!!pharmacyID
              ? `${pharmacyName} - ${pharmacyID} ${pharmacyAddress}, ${pharmacyCity}, ${pharmacyState}, ${pharmacyIndex} - ${pharmacyPhone}`
              : "None"}
          </p>
          <div className="prescribeGroup">
            {formularyOptions.length > 0 && !prescribeOutside && (
              <Dropdown isOpen={dropdownOpen} toggle={toggle} className="mr-0 mt-2">
                <div className="prescription-dropdown">
                  <DropdownToggle
                    className={lengthCheck ? "dropdown-toggle longer" : "dropdown-toggle shorter"}
                    disabled={
                      ((additionalStatus === DECLINED ||
                        selectedStatus === STOP_PRESCRIPTION_NO ||
                        additionalStatus?.status === NO_PRESCRIPTION) &&
                        isFollowup) ||
                      loading
                    }
                  >
                    <span>
                      {formularyName}&nbsp;{formularyMatched && chiefComplaint} Formulary
                    </span>
                    <span>
                      <Caret />
                    </span>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-formulary">
                    {formularyOptions.map((item, idx) => {
                      return (
                        <DropdownItem
                          key={`${idx}-${item.ProductDisplayName}`}
                          onClick={onSelect(idx)}
                          disabled={disabled(item.ProductDisplayName)}
                        >
                          {item.ProductDisplayName || ""},&nbsp;
                          {`${item.Route}-${item.Doseform} ` || ""},&nbsp;
                          {`Qty ${item.Quantity} ${item.Unit}` || ""},&nbsp;
                          {`${item.Refills} Refill(s)`},&nbsp;
                          {`${item.DaysSupply} Days Supply`}{" "}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </div>
              </Dropdown>
            )}
            {(!!prescribeOutside ||
              selectedFormulary.length > 0 ||
              formularyOptions.length === 0) && (
                <PrecribeView
                  permissions={props?.currentUser?.permissions}
                  prescriptionDetails={prescriptionDetails}
                  onPrescriptionLink={props.onPrescriptionLink}
                  cn="active"
                  additionalStatus={additionalStatus}
                  selectedStatus={selectedStatus}
                  loading={loading}
                />
              )}
            {showRXBtns && (
              <>
                <div className=" ml-5 mt-3">OR</div>
                <div className="buttons ml-5 mt-2">
                  {presStatus.map((item) => {
                    return (
                      <button
                        className={`${additionalStatus === item.value || additionalStatus?.status === item.value
                          ? "active"
                          : !!isFollowup && selectedStatus === STOP_PRESCRIPTION_NO
                            ? "active"
                            : ""
                          }`}
                        onClick={onClick(item.value)}
                      >
                        {item.label}
                      </button>
                    );
                  })}
                </div>
              </>
            )}
          </div>
          {!pharmacyMatch && formularyOptions.length > 0 && (
            <div className="d-flex mt-2">
              <div>
                <FormGroup check className="d-flex">
                  <Label
                    check
                    className={
                      selectedFormulary.length > 0 || (additionalStatus === DECLINED && isFollowup)
                        ? "disabled mt-2 text-nowrap"
                        : "mt-2 text-nowrap"
                    }
                  >
                    <Input
                      type="checkbox"
                      onChange={() => handlePrescribeOutside(!prescribeOutside)}
                      disabled={
                        selectedFormulary.length > 0 ||
                        (additionalStatus === DECLINED && isFollowup) ||
                        (prescriptionDetails && prescriptionDetails.length > 0)
                      }
                      checked={!!prescribeOutside}
                    />
                    {`I'll prescribe outside of the ${formularyName} ${chiefComplaint} formulary`}
                  </Label>
                  <div className="d-flex align-items-end ml-2" onClick={(e) => onClickQ(e)}>
                    <Question />
                  </div>
                </FormGroup>
              </div>
              <div>
                {description && (
                  <div className="descriptionText">
                    This patients pharmacy is not constrained to a formulary and you may write any
                    prescription. With this checked the Reliant EMR does not validate the written
                    prescription in Dosespot against any formulary selection.
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="formulary-list ml-4">
        {selectedFormulary.map((item, idx) => {
          return (
            <span key={`selectedFormulary-${idx}`} className={dosespotStatusBold[idx]}>
              {item.ProductDisplayName || ""},&nbsp;
              {`${item.Route}-${item.Doseform} ` || ""},&nbsp;
              {`Qty ${item.Quantity} ${item.Unit}` || ""},&nbsp;
              {`${item.Refills} Refills`},&nbsp;
              {`${item.DaysSupply} Days Supply`}{" "}
              {dosespotRemove[idx] && <button onClick={onRemove(idx)}>Remove</button>}
              <h1 className="dosespotStatus">
                {dosespotStatusIcon[idx] === "warning" ? (
                  <WarningCircle />
                ) : dosespotStatusIcon[idx] === "ok" ? (
                  <CircleCheck />
                ) : null}{" "}
                {dosespotStatus[idx]}
              </h1>
              {item.Protocol && <p className="blueWindow">Provider protocol: {item.Protocol}</p>}
            </span>
          );
        })}
        {!prescribeOutside &&
          dosespotOrigin.map((item, idx) => {
            return (
              <span key={`dosespotOrigin-${idx}`} className={dosespotStatusBold[idx]}>
                {item.name || ""},&nbsp;
                {`${item.route}-${item.doseform} ` || ""},&nbsp;
                {`Qty ${item.quantity} ${item.doseform}` || ""},&nbsp;
                {`${item.refillqty} Refills`},&nbsp;
                {`${item.daysSupply} Days Supply`}{" "}
                <h1 className="dosespotStatus">
                  <WarningCircle />
                  Prescription entered in Dosespot was not selected in the EMR formulary. Please
                  select it from the formulary or remove the entry in Dosespot.
                </h1>
              </span>
            );
          })}
      </div>
      {(!!prescribeOutside || formularyOptions.length == 0) &&
        !!prescriptionDetails &&
        prescriptionDetails.length > 0 && (
          <div className="prescriptions-list ml-4">
            <h1 className="prescriptionWritten">Prescriptions Written</h1>
            {prescriptionDetails.map((item, idx) => {
              let statusObj = GetStatusString(item.status);
              return (
                <Fragment key={`prescriptionDetails-${idx}`}>
                  <span>
                    {item.name || ""},&nbsp;
                    {`${item.route}-${item.doseform} ` || ""},&nbsp;
                    {`Qty ${item.quantity} ${item.dispenseqty}` || ""},&nbsp;
                    {`${item.refillqty} Refills`},&nbsp;
                    {`${item.daysSupply} Days Supply`}{" "}
                    <h1 className="dosespotStatus">
                      {statusObj.error ? (
                        <span>
                          <WarningCircle /> {statusObj.statusString}
                        </span>
                      ) : (
                        <span>
                          <CircleCheck /> {statusObj.statusString}
                        </span>
                      )}
                    </h1>
                  </span>
                </Fragment>
              );
            })}
          </div>
        )}

      {isFollowup && (
        <div className="prescribe-checkboxes mt-1">
          <FormGroup check className="d-flex flex-column">
            <div className="mt-1">
              <Label check>
                <Input
                  type="checkbox"
                  onChange={onStatusChange(STOP_PRESCRIPTION)}
                  checked={
                    [STOP_PRESCRIPTION, STOP_PRESCRIPTION_NEW].includes(additionalStatus) ||
                    [STOP_PRESCRIPTION, STOP_PRESCRIPTION_NEW].includes(additionalStatus?.status) ||
                    selectedStatus === STOP_PRESCRIPTION_NO
                  }
                />
                I instructed patient to discontinue one or more of their current medications
              </Label>
            </div>
          </FormGroup>
        </div>
      )}
    </div>
  );
}

export default Prescription;
