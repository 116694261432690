import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PropTypes from "prop-types";

import api from "../../api";
import NavBar from "../Provider/NavBar";
import bugsnagClient from "../../services/bugsnag";
import UploadFileModal from "./UploadFileModal";
import PendingModal from "./PendingModal";
import Spinner from "../../images/Spinner.svg";

import { INITIATE_NO_PATIENT_ENCOUNTER } from "../../constants/actionTypes";
import { isPCCUser } from "../../constants/Providers";
import NeedReviewModal from "./NeedReviewModal";

import PatientSideBar from "./PatientSideBar";
import PCCPatientSideBar from "./PCCPatientSideBar";
import ChartTabs from "./ChartTabs";
import ConsentStatusModal from "./ConsentStatusModal";
import ConsentPopUp from "./ConsentPopUp";

import TreatmentStatusEditModal from "./TreatmentStatusEditModal";
import ImageGallergyModal, { IImageGalleryObject } from "../ImageGalleryModal/index";
import {IPatientDuplicateItem}  from "../../constants/Types"
import {
  IPractitioner,
  IPccPatientProfile,
  IChartEntry,
  ICoverage,
  IAllergy,
  IMedication,
  ICondition,
  I_Immunization,
  IMinProgressNote,
  IAssessment,
  IPatientProfile,
  IKeyedLabelEntry,
} from "../../constants/Types";
import { imagesToIImageGalleryObject } from "../Encounter/Encounter";
import { CURRENT } from "../Encounter/RxRequestVisit";
import { checkIsJSON, openInNewTab } from "../../utils";
import { toast } from "react-toastify";
import {
  PERMISSION_RECENT_ENCOUNTER,
  PERMISSION_RECENT_ENCOUNTER_LIMITED,
  PERMISSION_SEE_PATIENTS, PERMISSION_THERAPIST,
} from "../../constants/Permissions";

const DEFAULT_PHOTO_IMAGE = require("../../images/patientDefaultPhoto.png");

type AllEntriesType = IChartEntry & IMinProgressNote & { providerName: string };


const Chart = ({ userID, fromActiveEncounter, history, showCovidHistory = true, ...props }) => {
  const [profile, setProfile] = useState<IPatientProfile>({} as IPatientProfile);

  const [immunizationPCC, setImmunizationPCC] = useState<I_Immunization[] | null>([]);

  const [insurancePCC, setInsurancePCC] = useState<ICoverage | {}>({});
  const [profilePCC, setProfilePCC] = useState<IPccPatientProfile>({} as IPccPatientProfile);
  const [allergiesPCC, setAllergiesPCC] = useState<IAllergy[] | null>([]);
  const [medicationsPCC, setMedicationsPCC] = useState<IMedication[]>([]);
  const [diagnosisPCC, setDiagnosisPCC] = useState<ICondition[] | null>([]);
  const [physician, setPhysician] = useState<IPractitioner | null>(null);
  const [blocker, setBlocker] = useState(false);

  const [loaded, setLoaded] = useState(false);
  const [medicalCatalog, setMedicalCatalog] = useState<IKeyedLabelEntry[]>([]);
  const [surgicalCatalog, setSurgicalCatalog] = useState<IKeyedLabelEntry[]>([]);
  const [covidHistory, setCovidlHistory] = useState({});
  const [assessments, setAssessmentsPCC] = useState<IAssessment[]>([]);
  const [catalog, setCatalog] = useState({});
  const [loadError, setLoadError] = useState("");
  const [showUploadFileModal, setShowUploadFileModal] = useState<boolean>(false);
  const [needReviewModal, setNeedReviewModal] = useState<boolean>(false);
  const [showPendingModal, setShowPendingModal] = useState<boolean>(false);
  const [testID, setTestID] = useState("");
  const [testType, setTestType] = useState<string[]>([]);
  const [entries, setEntries] = useState<Partial<AllEntriesType>[]>([]);
  const [entriesLoaded, setEntriesLoaded] = useState(false);
  const [consent, setConsent] = useState<boolean | null>(false);
  const [consentDate, setConsentDate] = useState<string | null>("");
  const [consentModal, setConsentModal] = useState(false);
  const [editConsentModal, setEditConsentModal] = useState<boolean>(false);
  const [isConsentPopUp, setConsentPopUp] = useState<boolean>(false);
  const [adding, setAdding] = useState(false);
  const [resyncStatusMessage, setResyncStatusMessage] = useState("");
  const [showImages, setShowImages] = useState(false);
  const [images, setImages] = useState<IImageGalleryObject[]>([]);
  const [imageTitle, setImageTitle] = useState("");
  const name = profile.patient ? profile.patient.name : "";
  const [isBtnPending, setPending] = useState<boolean>(false);
  const [duplicates, setDuplicates] = useState<IPatientDuplicateItem[]>([])
  // if the current user is a pcc provider, then the patient should be one too
  const currentUser = props.currentUser || {};
  const isPccIntegrated = isPCCUser(currentUser);
  const canEditNote = !!(
    currentUser.permissions && currentUser?.permissions?.includes(PERMISSION_SEE_PATIENTS || PERMISSION_THERAPIST)
  );
  const isNursingHomeSite = !!currentUser && currentUser.isNursingHomeSite;
  const canRecentEncounter = currentUser?.permissions?.includes(PERMISSION_RECENT_ENCOUNTER) || currentUser?.permissions?.includes(PERMISSION_RECENT_ENCOUNTER_LIMITED)

  const viewHealthGorillaChart = async () => {
    setPending(true);
    try {
      const healthGorillaURL = await api.HealthGorilla.viewHealthGorillaChart(userID);

      if (profile.patient.healthGorillaUserID) {
        openInNewTab(healthGorillaURL.url);
        setPending(false);
      } else {
        setTimeout(() => {
          // with the initial HG chart call, we need more time to find a patient that matches
          openInNewTab(healthGorillaURL.url);
          setPending(false);
        }, 3000);
      }
    } catch (e: any) {
      const isJSON = checkIsJSON(e.response.body?.message);
      let errorMessage = isJSON
        ? JSON.parse(e.response.body?.message).responseBody
        : e.response.body?.message || e.response.text;
      if (typeof errorMessage === "object") {
        errorMessage = errorMessage.error_description || errorMessage.issue[0].diagnostics;
      }
      toast.error(`Error: ${errorMessage}`);
      setPending(false);
    }
  };
  function fetchCatalogsAndHistory() {
    return Promise.all([
      api.Catalog.medicalHistory(),
      api.UserRecords.covidlHistory(userID),
      api.Catalog.surgicalHistory(),
      api.Catalog.reviewOfSystems(),
    ]).then(([medicalHistory, covidHistory, surgicalHistory, reviewOfSystems]) => {
      return { medicalHistory, covidHistory, surgicalHistory, reviewOfSystems };
    });
  }
  function fetchPCCProfileData() {
    return Promise.all([
      api.PCC.patient(userID),
      api.PCC.coverage(userID),
      api.PCC.allergies(userID),
      api.PCC.medications(userID),
      api.PCC.conditions(userID),
      api.PCC.immunization(userID),
      api.PCC.assessments(userID),
    ]).then(
      ([
        patient,
        coverage,
        allergiesData,
        medicationsData,
        conditionsData,
        immunizationsData,
        assessmentsData,
      ]) => {
        return {
          patient,
          coverage,
          allergiesData,
          medicationsData,
          conditionsData,
          immunizationsData,
          assessmentsData,
        };
      },
    );
  }
  useEffect(() => {
    const loadProfile = async function () {
      try {
        let result = await api.UserRecords.profile(userID);

        setProfile(result);
        //setConsent off of patient profile
        setConsent(result.patient.consentDetails.consentStatus || null);
        setConsentDate(result.patient.consentDetails.consentStatusLastUpdate);
        if (result.patient.consentDetails.consentStatus === null) {
          setConsentPopUp(true);
        }

        if (isPccIntegrated) {
          const PCCPatientData = fetchPCCProfileData();
          PCCPatientData.then((data) => {
            setProfilePCC(data.patient);
            setInsurancePCC(data.coverage);
            setAllergiesPCC(data.allergiesData.allergies || []);
            setMedicationsPCC(data.medicationsData.medications || []);
            setDiagnosisPCC(data.conditionsData.conditions || []);
            setImmunizationPCC(data.immunizationsData.immunizations || []);
            setAssessmentsPCC(data.assessmentsData.assessments || []);
          });
          const practitionersRespone = await api.PCC.practitioner(userID);
          if (practitionersRespone.practitioners) {
            let PrimaryPhysician = practitionersRespone.practitioners.find(
              (el) => el.relation === "Primary",
            );
            PrimaryPhysician && setPhysician(PrimaryPhysician);
          }
        }
        const catalogsAndHistoryData = fetchCatalogsAndHistory();
        catalogsAndHistoryData.then((data) => {
          setMedicalCatalog(data.medicalHistory);
          setCovidlHistory(data.covidHistory);
          setSurgicalCatalog(data.surgicalHistory);
          setCatalog(data.reviewOfSystems);
        });
      } catch (err: any) {
        bugsnagClient.notify(err);
        setLoadError(err.toString());
      }
    };

    setLoaded(false);
    setEntriesLoaded(false);
    if (props.currentUser != null) {
      loadProfile().finally(() => {
        setLoaded(true);
      });
      loadEntries([]);
    }
    loadDuplicatePatients()
  }, [props.currentUser]);

  const loadEntries = async function (base: Partial<AllEntriesType>[], token?: string) {
    if (!token) {
      // only sync dosespot on the initial load entry request
      try {
        await api.Encounters.syncDosespotPrescription(userID);
      } catch (e) {
        //ignore error
      }
    }
    let result = await api.UserRecords.chart(userID, token);
    if (result.pageToken) {
      // another page of entries exist, load the next page
      loadEntries(base.concat(result.items), result.pageToken);
    } else {
      // received last page of entries
      let allEntries = base.concat(result.items);

      if (isPccIntegrated) {
        try {
          // for PCC integrated patients, pull any progress note from PCC and add it into the chart items
          const pccNotes = await api.PCC.progressNotes(userID);

          const notes = (pccNotes.Notes || []).map((note) => ({
            // refactor PCC notes to partially match the existing mdbox format
            isPCCNote: true,
            orgId: note.orgID,
            orgUuid: note.orgUuid,
            progressNoteId: note.progressNoteId,
            description: note.progressNoteType || "",
            createdAt: note.createdDate,
          }));

          // merge the mapped PCC notes into all patient notes
          allEntries = allEntries.concat(notes);

          // restort the entries by creation date, lastest first (descending order)
          allEntries.sort((a, b) => {
            if (moment(a.createdAt).isBefore(b.createdAt)) {
              return 1;
            } else if (moment(a.createdAt).isAfter(b.createdAt)) {
              return -1;
            }
            return 0;
          });
        } catch (err) {}
      }

      // include the provider name on entries, when applicable
      try {
        const result = await api.Providers.listnoAudit({ scope: "all" });
        const providers = result.items || [];
        allEntries.forEach((entry) => {
          if (!!entry.createdBy) {
            // find the matching provider by id
            const found = providers.find((provider) => provider.id === entry.createdBy);
            // inclue the provider name with the enry
            entry.providerName = (found && found.fullName) || "";
          }
        });
      } catch (err) {}

      // loading is complete
      setEntries(allEntries);
      setEntriesLoaded(true);
    }
  };

  const loadDuplicatePatients = async () => {
    try{
      let result = await api.UserRecords.duplicatePatients(userID)
      setDuplicates(result?.items || [])

    }catch(err) {
      console.log("Error fetching duplicate patients:: ", err)
    } 
    
    
  }

  const updateProfile = async () => {
    let result = await api.UserRecords.profile(userID);
    setProfile(result);

    setConsent(result.patient.consentDetails.consentStatus || null);
    //update date
    setConsentDate(result.patient.consentDetails.consentStatusLastUpdate);
  };

  const startEncounter = (visitType) => {
    localStorage.setItem("effectiveDate", "");
    props.intiateEncounter(userID, visitType);
  };
  const onReSync = async () => {
    try {
      const result = await api.PCC.reSyncPCC(userID, currentUser.currentPracticeID);
      const Status = ((result || {}) as any).Status;
      if (!!Status) {
        // the resync was not successful, display the error message
        setLoaded(true);
        setResyncStatusMessage(Status);
      } else {
        // the sync was successful, refresh the patient page
        setLoaded(false);
        setBlocker(true);
        setTimeout(() => {
          location.reload();
        }, 7000);
      }
    } catch (e: any) {
      bugsnagClient.notify(e);
    }
  };
  const handleBack = () => {
    history.goBack();
  };

  const onChangeCovidZone = async (zone) => {
    await api.Providers.changeCovidZone(userID, { covidZone: zone });
  };

  const restoreOldProfile = (profile) => {
    setProfile(profile);
  };
  const onDecline = () => {
    onChangeConsent(false, { consentStatus: false, userID: userID });
  };
  const onAccept = () => {
    onChangeConsent(true, { consentStatus: true, userID: userID });
  };
  const onChangeConsent = async (status, payload) => {
    try {
      await api.Providers.changeConsent(payload);
      //setConsent(status);
      await updateProfile();
      setConsentPopUp(false);
    } catch (e) {
      console.error(e);
    }
  };

  const onShowCovidUploadForEntry = (entry) => {
    if (!!entry) {
      if (!!entry.content) {
        setTestID(entry.content);
      }
      if (!!entry.isEnhanced) {
        setTestType(["SARS-CoV2 Nasal Swab Test Result"]);
      } else {
        setTestType(["SARS-CoV2 Saliva Test Result", "SARS-CoV2 Nasal Swab Test Result"]);
      }

      setShowUploadFileModal(true);
    }
  };

  const closeResyncStatus = () => {
    // close the status message modal by clearing the message
    setResyncStatusMessage("");
  };

  const handleImages = async (refKey, title) => {
    try {
      const result = await api.Encounters.encounterImages(refKey);
      console.log(result);

      setImages(imagesToIImageGalleryObject(CURRENT, result));
      setImageTitle(title);
      setShowImages(true);
    } catch (err) {
      console.log(err);
    }
  };

  const closeImages = () => {
    setShowImages(false);
    setImages([]);
    setImageTitle("");
  };

  return (
    <div>
      {!!isNursingHomeSite && (
        <ConsentPopUp visible={isConsentPopUp} onDecline={onDecline} onAccept={onAccept} />
      )}

      <Modal isOpen={!!resyncStatusMessage} toggle={closeResyncStatus}>
        <ModalHeader toggle={closeResyncStatus}>Resync Status</ModalHeader>
        <ModalBody>{resyncStatusMessage}</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={closeResyncStatus}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      {blocker && <div className="display-blocker" />}
      <PendingModal
        visible={showPendingModal}
        onCancel={() => setShowPendingModal(false)}
        onContinue={() => {
          setShowPendingModal(false);
          setShowUploadFileModal(true);
        }}
      />
      <UploadFileModal
        visible={showUploadFileModal}
        userID={userID}
        userName={name}
        testID={testID}
        testType={testType}
        onClose={() => setShowUploadFileModal(false)}
      />
      <ImageGallergyModal
        title={imageTitle}
        isOpen={showImages}
        images={images}
        onClose={closeImages}
      />
      {needReviewModal && <NeedReviewModal setNeedReviewModal={setNeedReviewModal} />}
      {!!isNursingHomeSite && consentModal && (
        <ConsentStatusModal
          setModal={() => setConsentModal(false)}
          userID={userID}
          consent={consent}
          setConsent={setConsent}
          setConsentDate={setConsentDate}
        />
      )}
      {!!isNursingHomeSite && editConsentModal && (
        <TreatmentStatusEditModal
          updateProfile={updateProfile}
          consentDetails={profile.patient.consentDetails}
          setModal={() => setEditConsentModal(false)}
          CovidZone={profile.patient.covidZone}
          userID={userID}
          consent={consent}
          setConsent={setConsent}
          setConsentDate={setConsentDate}
        />
      )}
      {!fromActiveEncounter && <NavBar />}
      <div className="dashboard-container">
        {!!loadError ? (
          <Alert color="danger">{loadError}</Alert>
        ) : !loaded ? (
          <div className="d-flex justify-content-center align-item-center">
            <Spinner />
          </div>
        ) : (
          <div className="d-flex flex-row">
            <div>
              {!!isPccIntegrated ? (
                <PCCPatientSideBar
                  viewHealthGorillaChart={viewHealthGorillaChart}
                  isBtnPending={isBtnPending}
                  setEditConsentModal={setEditConsentModal}
                  adding={adding}
                  setAdding={setAdding}
                  consent={consent}
                  setConsent={setConsent}
                  onReSync={onReSync}
                  permissions={props.permissions}
                  physician={physician}
                  fromActiveEncounter={fromActiveEncounter}
                  currentUser={props.currentUser}
                  consentDetails={profile.patient.consentDetails}
                  covidZone={profile.patient.covidZone}
                  isHealthGorillaUser={profile.patient.healthGorillaUserID}
                  profile={profilePCC}
                  allergies={allergiesPCC}
                  conditions={diagnosisPCC}
                  defaultImage={DEFAULT_PHOTO_IMAGE}
                  initiateEncounter={startEncounter}
                  handleBack={handleBack}
                  onChangeCovidZone={onChangeCovidZone}
                  userID={userID}
                  loadEntries={loadEntries}
                  isPCCPatient={isPccIntegrated}
                  setConsentModal={() => setConsentModal(true)}
                  consentDate={consentDate}
                  isNursingHomeSite={isNursingHomeSite}
                />
              ) : (
                <PatientSideBar
                  viewHealthGorillaChart={viewHealthGorillaChart}
                  isBtnPending={isBtnPending}
                  adding={adding}
                  setAdding={setAdding}
                  consent={consent}
                  setConsent={setConsent}
                  fromActiveEncounter={fromActiveEncounter}
                  userID={userID}
                  currentUser={props.currentUser}
                  profile={profile}
                  permissions={props.permissions}
                  image={DEFAULT_PHOTO_IMAGE}
                  defaultImage={DEFAULT_PHOTO_IMAGE}
                  handleBack={handleBack}
                  updateProfile={updateProfile}
                  initiateEncounter={startEncounter}
                  loadEntries={loadEntries}
                  setConsentModal={() => setConsentModal(true)}
                  isNursingHomeSite={isNursingHomeSite}
                  consentDate={consentDate}
                  isPCCPatient={isPccIntegrated}
                  setEditConsentModal={setEditConsentModal}
                />
              )}
            </div>
            <div className="flex-grow-1" style={{ marginLeft: 14 }}>
              <ChartTabs
                permissions={props.permissions}
                userID={userID}
                isPCCPatient={isPccIntegrated}
                currentUser={props.currentUser}
                profile={profile}
                medicalCatalog={medicalCatalog}
                surgicalCatalog={surgicalCatalog}
                covidHistory={covidHistory}
                catalog={catalog}
                profilePCC={profilePCC}
                allergiesPCC={allergiesPCC}
                conditionsPCC={diagnosisPCC}
                medicationsPCC={medicationsPCC}
                coveragePCC={insurancePCC}
                immunizationPCC={immunizationPCC}
                assessmentsPCC={assessments}
                setProfile={setProfile}
                onShowCovidUploadForEntry={onShowCovidUploadForEntry}
                restoreOldProfile={restoreOldProfile}
                entries={entries}
                setEntries={setEntries}
                entriesLoaded={entriesLoaded}
                loadEntries={loadEntries}
                canEditNote={canEditNote}
                onSaveTab={() => {}}
                handleImages={handleImages}
                duplicatePatients={duplicates}
                fromActiveEncounter={fromActiveEncounter}
                canRecentEncounter={canRecentEncounter}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userID: state.router.location.pathname.split("/").pop(),
  currentUser: state.common.currentUser,
  permissions: state.common.currentUser && state.common.currentUser.permissions,
});

const matchDispatchToProps = (dispatch) => ({
  intiateEncounter: (userID, visitType) =>
    dispatch({
      type: INITIATE_NO_PATIENT_ENCOUNTER,
      payload: api.Encounters.intiate(userID, visitType),
    }),
});

export default connect(mapStateToProps, matchDispatchToProps)(Chart);
