import { Button, Col, Row } from "reactstrap";
import ProviderSelect from "../Provider/ProviderSelect";
import React, { FC, useEffect, useState } from "react";
import api from "../../api";
import { errorLogger } from "../../utils";
import styles from "./InvalidStateModal.scss";

interface IProps {
  visible: boolean;
  handleCancel: () => void;
  state: string;
  message: string;
  referenceID: string;
}

const PracticeErrorModal: FC<IProps> = ({ visible, handleCancel, state, message, referenceID }) => {
  const [provider, setProvider] = useState("");

  const handleSelect = (provider: { target: { value: string } }) => {
    let providerID = provider?.target?.value;
    setProvider(providerID);
  };

  const onAssign = async (referenceID) => {
    try {
      await api.Encounters.assign(referenceID, provider!)
        .then(() => {
          setTimeout(() => {
            handleCancel()
          },3000)

        });
    } catch (error: any) {
      const status = error.response?.status;
      if (status === 422) {
        errorLogger(error);
      }
    }
  };

  if (visible) {
    return (
      <div className="modal-overlay d-flex flex-column">
        <div className={styles.modalContainer}>
          <h4>{message}</h4>
          <div>
            <p className="mt-3">
              This visit will be removed from your overview tab. Please assign this note to another provider now.
              You can contact support with any issues.
            </p>
          </div>
          <div>
            <ProviderSelect
              className={styles.assignSelect}
              selected={provider}
              onChange={handleSelect}
              encounterID={referenceID}
              state={state}
            />
          </div>
          <div className="d-flex justify-content-end w-100 mt-5">
            <Button className={styles.assignBtn} color="primary" onClick={() => onAssign(referenceID)}>
              Assign
            </Button>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default PracticeErrorModal;
