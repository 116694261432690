import React, { FC, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import "../../stylesheets/encounterMonitor.scss";
import { STATES } from "../StateSelector";

import DatePicker from "./EMDatePicker";
import moment from "moment";
import PaginationAE from "./PaginationBlock";
import Octicon, { ChevronDown, ChevronUp } from "@githubprimer/octicons-react";
import { DefaultSearchParams, DefaultChartParams, ICurrentUser } from "../../constants/Types";
import {
  defaultParams,
  medicalHistoryFields,
  methods,
  newMessages,
  exitedWaitingRoomPrem,
  neverEnteredWaitingRoom,
  timeOperator,
  defaultChartParams,
  statuses,
  timeLine,
  chartsTimeFrameOptionsDaily,
  orgTypes,
  timeFrameTypeOptions,
} from "../../constants/Encounter";
import { PERMISSION_NO_PHI, hasPermission } from "../../constants/Permissions";
import { minutesToSeconds } from "../../utils";
import MonitorStatsTable from "./EncounterStats";
import ApiSelect from "./ApiSelect";

import api from "../../api";


type Props = {
  //   props for getting encounter search results
  getEncounterSearchResults: (params: DefaultSearchParams) => void;
  // props for clearing encounter search results
  clearEncounterSearchResults: () => void;
  setShowModal: (showModal: boolean) => void;
  showModal: boolean;
  queryParams: DefaultSearchParams;
  totalCount?: number;
  pageSize?: number;
  pageStart?: number;
  onChange: (pageStart: number, pageSize: number, page: number) => void;
  page: number;
  setPage: (page: number) => void;
  paramsToObjet: (entries: any) => object;
  permissions: string[];
  loadDBCompare: () => void
  currentUser: ICurrentUser
};

const setMultiSelectInputValue = (
  value: string[],
  variants: { label: string; value: string }[],
) => {
  return variants.filter((el) => value?.includes(el.value)) || null;
};

const setSelectInputValue = (
  value: string,
  variants: { label: string; value: string | boolean }[],
) => {
  return variants.find((el) => el.value === value) || null;
};



const MonitorEncounterFilter: FC<Props> = ({
  getEncounterSearchResults,
  clearEncounterSearchResults,
  setShowModal,
  showModal,
  totalCount,
  pageSize,
  pageStart,
  onChange,
  queryParams,
  page,
  setPage,
  permissions,
  loadDBCompare,
  currentUser,
}) => {
  const [showCancel, setShowCancel] = useState(false);
  const [disableSearch, setDisableSearch] = useState(true);
  const [searchParams, setSearchParams] = useState<DefaultSearchParams>(queryParams);
  const [chartSearchParams, setChartSearchParams] = useState<DefaultChartParams>({});
  const [pWaitedTime, setPWaitedTime] = useState({ min: "", sec: "" });
  const [videoTime, setVideoTime] = useState({ min: "", sec: "" });
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [showFilterStats, setShowFilterStats] = useState(true);

  const [chartStats, setChartStats] = useState({});
  const [visitCount, setVisitCount] = useState<any[][]>([]);
  const [vCountProvider, setVCountProvider] = useState<any[][]>([]);
  const [countByProvider, setCountByProvider] = useState<any[]>([])
  const [providerPieTotal, setProviderPieTotal] = useState<any[][]>([]);
  const [vCountPractice, setVCountPractice] = useState<any[][]>([]);
  const [practicePieTotal, setPracticePieTotal] = useState<any[][]>([]);
  const [timeFrame, setTimeFrame] = useState(chartsTimeFrameOptionsDaily[1]);
  const [timeFrameType, setTimeFrameType] = useState(timeFrameTypeOptions[0])
  const [disableTimeFrame, setDisableTimeFrame] = useState(false)
  // queryselector for body useRef
  const bodyRef = useRef(document.querySelector("body"));
  const noPHI = hasPermission(permissions, PERMISSION_NO_PHI);



  useEffect(() => {
    setSearchParams(queryParams);
    const defaults = ['pageSize', 'pageStart', 'page', 'sortOrder', 'sortBy', 'patientWaitedTime']
    let queryValues: any = Object.keys(queryParams).filter((el) =>

      !defaults.includes(el) && Array.isArray(queryParams[el]) && queryParams[el].length > 0
        ? el
        : !defaults.includes(el) && !Array.isArray(queryParams[el]) && queryParams[el] !== ""
          ? el
          : !defaults.includes(el) && !Array.isArray(queryParams[el]) && queryParams[el] !== null
            ? queryParams[el]
            : null,
    );
    const { pageSize, pageStart, page, sortBy, sortOrder, ...rest } = queryValues;

    if (Object.keys(rest).length > 1) {
      setShowCancel(true);
      setChartSearchParams(queryParams);
      searchChartData(queryParams);
    }
  }, [queryParams]);

  useEffect(() => {
    getRecentVisitsCount();
    getVisitCountByProvider();
    getVisitCountByPractice();
  }, [timeFrame, queryParams])

  const searchHandler = () => {
    getEncounterSearchResults(searchParams);
    searchChartData(searchParams);
    getVisitCountByProvider()
    getVisitCountByPractice();
    getRecentVisitsCount();
  };

  const cancelHandler = () => {
    setSearchParams(defaultParams);
    setChartSearchParams(defaultChartParams);
    setPWaitedTime({ min: "", sec: "" });
    setVideoTime({ min: "", sec: "" });
    clearEncounterSearchResults();
    setDisableSearch(true);
    setShowCancel(false);
    setDisableTimeFrame(false)
  };
  const filterFromChart = (selected) => {

    const getDate = countByProvider.find((item) => item?.name === selected?.providerName && item?.date === selected?.date)
    const date = getDate?.unformattedDate && getDate?.unformattedDate.split("T")[0]

    const stringFormatted = selected?.providerName.split(',')[0]
    // remove started to started from and replace with the values that will come from the chartClick date selected
    const paramCopy = { ...searchParams, providerName: stringFormatted, startedTo: date, startedFrom: date }
    setSearchParams(paramCopy)
    getEncounterSearchResults(paramCopy);
  }

  const setSearchParamList = (value, key: keyof typeof queryParams) => {
    const updatedParams: DefaultSearchParams = { ...searchParams };
    const updatedChartParams: DefaultSearchParams = { ...chartSearchParams };

    updatedParams[key] = value;
    updatedChartParams[key] = value;

    if (value === "" && Object.values(updatedParams).every((val) => val === "" || val === [])) {
      setShowCancel(false);
      setDisableSearch(true);
    } else {
      setShowCancel(true);
      setDisableSearch(false);
    }
    setSearchParams(updatedParams);
    setChartSearchParams(updatedChartParams);
  };

  const getRecentVisitsCount = async () => {
    try {
      let params = { ...queryParams, startedFrom: timeFrame?.value?.startedFrom, startedTo: timeFrame?.value?.startedTo, datesBy: timeFrameType.value }
      const response = await api.Monitoring.recentVisitsCount(params);

      let visitCountValues = response?.data?.map((item, idx) => {
        let value = Object.values(item);
        let date = timeFrameType.value === "monthly" ? value[0].split(".")[0] : value[0]
        let valueWithTTData = [date, value[1], `<div className="tooltip-chart">${value[0]}, ${value[1]}</div>`, value[2], `<div className="tooltip-chart">${value[0]}, ${value[2]}</div>`, value[3], `<div className="tooltip-chart">${value[0]}, ${value[3]}</div>`]
        return valueWithTTData
      }) || [];
      // Makes an array of the keys of the object
      let keys = Object.keys(response?.data[0]) || [];

      let keysWithTTData = ["", keys[1], { role: "tooltip", type: "string" }, keys[2], { role: "tooltip", type: "string" }, keys[3], { role: "tooltip", type: "string" }];

      let data = [keysWithTTData, ...visitCountValues];

      setVisitCount(data)
    } catch (err: any) {
      console.log(err);
    }
  }

  const getVisitCountByProvider = async () => {
    try {
      let params = { ...queryParams, startedFrom: timeFrame?.value?.startedFrom, startedTo: timeFrame?.value?.startedTo, datesBy: timeFrameType.value }
      const response = await api.Monitoring.visitCountByProvider(params);
      setCountByProvider(response?.data)
      const dateGroups = response?.data?.map((item) => item.date);
      const cleanDateGroups = [...new Set(dateGroups)];


      const monthLabels = cleanDateGroups.map((date) => date.split(".")[0])
      const labels = cleanDateGroups;

      const groups = response?.data?.reduce((acc, { date, name, visits }) => {
        if (!acc[name]) {
          // Fill an array with zeroes, the length of labels
          acc[name] = labels.map(_ => 0);
        }
        // Find the index of the current label
        const labelIndex = labels.indexOf(date);
        // Replace the corresponding zero
        acc[name][labelIndex] = visits;

        return acc;
      }, {}) || {};

      let newData: { label: string, data: any, backgroundColor: string }[] = [];
      for (const [key, value] of Object.entries(groups)) {
        const randomBetween = (min, max) =>
          min + Math.floor(Math.random() * (max - min + 1));
        const r = randomBetween(0, 255);
        const g = randomBetween(0, 255);
        const b = randomBetween(0, 255);
        const rgb = `rgb(${r},${g},${b})`;
        newData.push({ label: key, data: value, backgroundColor: rgb });
      }

      const providerPieTotal = response?.data?.reduce((r: any, item) => {
        const { date, name, visits } = item;
        const existing = r.find((x) => x[0] === name);
        if (existing) {
          existing[1] += visits;
        } else {
          r.push([name, visits]);
        }
        return r;
      }, []) || [];

      const piePlusLabels = [["Name", "Visits"], ...providerPieTotal];
      const chartLabels = timeFrameType.value === "monthly" ? monthLabels : labels
      // let data = [finalNames, ...output];
      setVCountProvider({ label: chartLabels, data: newData })
      setProviderPieTotal(piePlusLabels)
    } catch (err: any) {
      console.log(err);
    }
  }

  const getVisitCountByPractice = async () => {
    try {
      let params = { ...queryParams, startedFrom: timeFrame?.value?.startedFrom, startedTo: timeFrame?.value?.startedTo, datesBy: timeFrameType.value }
      const response = await api.Monitoring.visitCountByPractice(params);
      //create an array with each practice
      const practiceGroups = response?.data?.map((item) => item.practice) || [];
      //remove duplicates from practiceGroups
      const cleanPracticeGroups = [...new Set(practiceGroups)] || [];
      // add empty string for axis label
      const finalPractices = ["", ...cleanPracticeGroups] || [];
      // loop over response create an array of arrays containing date, each practice without duplicates
      const newObj = Object.fromEntries(cleanPracticeGroups.map(name => [name, 0]))
      const dateGroups = response?.data?.reduce((acc, obj) => {
        let monthDate = obj.date.split(".")[0]

        if (timeFrameType.value === "monthly" && !acc[monthDate]) {
          acc[monthDate] = { ...newObj }
        } else if (timeFrameType.value !== "monthly" && !acc[obj.date]) {
          acc[obj.date] = { ...newObj }
        }

        if (timeFrameType.value === "monthly") {
          acc[monthDate][obj.practice] += obj.visits
        } else {
          acc[obj.date][obj.practice] += obj.visits;
        }

        return acc
      }, {}) || [];

      let output: any[] = []

      for (let date in dateGroups) {
        let visitArray = Object.values(dateGroups[date])
        output.push([date, ...visitArray])
      }

      const practicePieTotal = response?.data?.reduce((r: any, item) => {
        const { date, practice, visits } = item;
        const existing = r.find((x) => x[0] === practice);
        if (existing) {
          existing[1] += visits;
        } else {
          r.push([practice, visits]);
        }
        return r;
      }, []) || [];

      const piePlusLabels = [["Practice", "Visits"], ...practicePieTotal];
      let data = [finalPractices, ...output];
      setVCountPractice(data)
      setPracticePieTotal(piePlusLabels)
    } catch (err: any) {
      console.log(err);
    }
  }


  const asyncFields = ["chiefComplaint", "practiceName", "patientName", "chiefComplaint", "providerName"]
  const selectParamHandler = (
    item: { label: string; value: string }[] | { label: string; value: string },
    meta: { name: keyof typeof queryParams },
  ) => {
    if (asyncFields.includes(meta?.name) && item === null) {
      setSearchParamList("", meta.name);
    } else {
      let value = Array.isArray(item) ? item.map((el) => el.value) : item.value;
      setSearchParamList(value, meta.name);
    }
  };

  const inputParamHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchParamList(event.target.value, event.target.name as keyof typeof queryParams);
  };

  // handles disabling charts date range change
  const visitCreatedHandler = (event, fieldName) => {
    setDisableTimeFrame(true)
    datePickerParamHandler(event, fieldName)
  }

  const datePickerParamHandler = (event, fieldName) => {
    const date = moment(event).format("YYYY-MM-DD");
    setSearchParamList(date, fieldName);
  };

  const handleTimeInput = (field, type, event) => {
    const value = event.target.value;
    if (field === "patientWaitedTime") {
      setPWaitedTime({ ...pWaitedTime, [type]: value });
    } else if (field === "providerVideoChatTime") {
      setVideoTime({ ...videoTime, [type]: value });
    }

    if (type === "min") {
      const converted = minutesToSeconds(value);
      let total = pWaitedTime.sec ? Number(pWaitedTime.sec) + converted : converted;
      setSearchParamList(total, field);
    } else if (type === "sec") {
      let total = pWaitedTime.min ? minutesToSeconds(pWaitedTime.min) + Number(value) : value;

      setSearchParamList(total, field);
    }
  };

  const handleEnterPress = (e) => {
    const checkParams = Object.values(searchParams).filter((item) => item !== "");
    if (e.key === "Enter" && checkParams.length > 0) {
      e.preventDefault();
      searchHandler();
    }
  };

  const searchChartData = async (chartParams) => {
    try {
      // changing arrays into strings for api call
      for (const [key, value] of Object.entries(chartParams)) {
        if (Array.isArray(chartParams[key]) && chartParams[key].length > 0) {
          chartParams[key] = chartParams[key].toString();
        }
      }
      let params = { ...chartParams, startedFrom: timeFrame?.value?.startedFrom, startedTo: timeFrame?.value?.startedTo }
      let response = await api.Monitoring.encounterCharts(params);

      setChartStats(response);
    } catch (err) {
      console.log(err);
    }
  };

  // handles search when item is clicked in stats aka chart table
  const visitStatsClickHandler = (value: string[], field: string) => {
    const paramsWithTimeline = { ...searchParams, [field]: value };
    getEncounterSearchResults(paramsWithTimeline);
    searchChartData(paramsWithTimeline);
  };

  const customStyles = {
    valueContainer: (provided) => ({
      ...provided,
      maxHeight: "40px",
      overflow: "scroll",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: "8px 2px",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: "8px 2px",
      innerHeight: "9px",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      padding: "1px 0px",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      padding: "1px 0px",
    }),
  };


  return (
    <>
      <div className="align-content-stretch encounter-search-menu encounter-monitor-table" style={{ zIndex: -1 }}>
        <div className="d-flex">
          <form className="d-flex" onKeyPress={handleEnterPress}>
            <div
              className={
                !showAdvanced
                  ? "encounter-search-menu-block mt-2 mb-3"
                  : "encounter-search-menu-block mt-2 mb-5"
              }
            >
              <div className="encounter-search-menu-item">
                <div className="d-flex flex-column encounter-search-header">
                  <h1>Encounter Search</h1>
                  <button
                    color="link"
                    type="button"
                    className="advanced-search pl-0"
                    onClick={() => setShowAdvanced(!showAdvanced)}
                  >
                    Advanced Search{" "}
                    <span>
                      {!showAdvanced ? (
                        <Octicon size={16} icon={ChevronUp} />
                      ) : (
                        <Octicon size={16} icon={ChevronDown} />
                      )}
                    </span>
                  </button>
                </div>
              </div>
              <div className="encounter-search-menu-item api-select">
                <label htmlFor="patient-name">Patient Name or Initials</label>
                <input
                  type="text"
                  className="last-input-item input-text-item"
                  placeholder="Patient Name"
                  name="patientName"
                  onChange={inputParamHandler}
                  value={searchParams?.patientName}
                />
              </div>
              <div className="encounter-search-menu-item api-select">
                <label>Provider Name or Initials</label>
                <ApiSelect
                  handleChange={selectParamHandler}
                  field={"providerName"}
                  selectedItem={searchParams?.providerName ? { label: searchParams?.providerName, value: searchParams?.providerName } : null}

                />
              </div>
              <div className="encounter-search-menu-item">
                <label>Location</label>
                <Select
                  name="location"
                  isSearchable={true}
                  onChange={selectParamHandler as any}
                  placeholder="State"
                  value={setMultiSelectInputValue(
                    searchParams.location ? searchParams.location : [],
                    STATES,
                  )}
                  className="last-input-item"
                  menuPortalTarget={bodyRef.current}
                  options={STATES}
                  isMulti={true}
                  styles={customStyles}
                />
              </div>
              <div className="encounter-search-menu-item api-select">
                <label htmlFor="practice-name">Practice Name</label>
                <ApiSelect
                  handleChange={selectParamHandler}
                  field={"practiceName"}
                  selectedItem={searchParams?.practiceName ? { label: searchParams?.practiceName, value: searchParams?.practiceName } : null}

                />
              </div>
            </div>
            {showAdvanced && (
              <>
                <div className="encounter-search-menu-block mb-5">
                  <div className="encounter-search-menu-item mr-3">
                    <label>Visit Created</label>
                    <div className="d-flex flex-column">
                      <span className="dp-span">
                        <DatePicker
                          date={searchParams?.startedFrom ? moment(searchParams.startedFrom) : null}
                          handleDateChange={(e) => visitCreatedHandler(e, "startedFrom")}
                          placeholder="Start of Date Range"
                        />
                      </span>
                      <span className="dp-span">
                        <DatePicker
                          date={searchParams.startedTo ? moment(searchParams.startedTo) : null}
                          handleDateChange={(e) => visitCreatedHandler(e, "startedTo")}
                          placeholder="End of Date Range"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="encounter-search-menu-item api-select">
                    <label htmlFor="chief-complaint">Chief Complaint</label>
                    <ApiSelect
                      handleChange={selectParamHandler}
                      field={"chiefComplaint"}
                      selectedItem={searchParams?.chiefComplaint ? { label: searchParams?.chiefComplaint, value: searchParams?.chiefComplaint } : null}
                    />
                  </div>
                  <div className="encounter-search-menu-item waiting-room-time-input">
                    <label>Time in Waiting Room</label>
                    <div className="d-flex flex-column">
                      <span>
                        <Select
                          name="patientWaitedTimeOperator"
                          isSearchable={true}
                          onChange={selectParamHandler as any}
                          value={setSelectInputValue(
                            searchParams.patientWaitedTimeOperator
                              ? searchParams.patientWaitedTimeOperator
                              : "",
                            timeOperator,
                          )}
                          className="last-input-item"
                          menuPortalTarget={bodyRef.current}
                          placeholder="Select"
                          options={timeOperator}
                          isMulti={false}
                          styles={customStyles}
                        />
                      </span>
                      <span className="d-flex">
                        <input
                          type="number"
                          className="time-input-item input-text-item mt-1"
                          placeholder="Minutes"
                          name="patientWaitedTime"
                          onChange={(e) => handleTimeInput("patientWaitedTime", "min", e)}
                          value={pWaitedTime.min}
                        />
                        <span className=" pl-1 ">
                          <input
                            type="number"
                            className="time-input-item input-text-item mt-1"
                            placeholder="Seconds"
                            name="patientWaitedTime"
                            onChange={(e) => handleTimeInput("patientWaitedTime", "sec", e)}
                            value={pWaitedTime.sec}
                          />
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="encounter-search-menu-item">
                    <label>Method</label>
                    <Select
                      name="method"
                      isSearchable={true}
                      onChange={selectParamHandler as any}
                      value={setMultiSelectInputValue(
                        searchParams.method ? searchParams.method : [],
                        methods,
                      )}
                      className="last-input-item"
                      menuPortalTarget={bodyRef.current}
                      placeholder="Select"
                      options={methods}
                      isMulti={true}
                      styles={customStyles}
                    />
                  </div>
                  <div className="encounter-search-menu-item">
                    <label>Last Visit Timeline Event</label>
                    <Select
                      name="latestVisitStatus"
                      isSearchable={true}
                      onChange={selectParamHandler as any}
                      value={setMultiSelectInputValue(
                        searchParams.latestVisitStatus ? searchParams.latestVisitStatus : [],
                        timeLine,
                      )}
                      className="last-input-item"
                      menuPortalTarget={bodyRef.current}
                      placeholder="Select"
                      options={timeLine}
                      isMulti={true}
                      styles={customStyles}
                    />
                  </div>
                </div>

                <div className="encounter-search-menu-block mb-5">
                  <div className="encounter-search-menu-item">
                    <label>EMR Status</label>
                    <Select
                      name="visitStatus"
                      onChange={selectParamHandler as any}
                      value={setMultiSelectInputValue(
                        searchParams.visitStatus ? searchParams.visitStatus : [],
                        statuses,
                      )}
                      isSearchable={true}
                      className="last-input-item"
                      menuPortalTarget={bodyRef.current}
                      placeholder="Select"
                      options={statuses}
                      isMulti={true}
                      styles={customStyles}
                    />
                  </div>
                  <div className="encounter-search-menu-item ml-4">
                    <label>New Messages </label>
                    <Select
                      name="newMessages"
                      onChange={selectParamHandler as any}
                      value={setSelectInputValue(
                        searchParams.newMessages ? searchParams.newMessages : "",
                        newMessages,
                      )}
                      isSearchable={true}
                      className="last-input-item"
                      menuPortalTarget={bodyRef.current}
                      placeholder="Select"
                      options={newMessages}
                      isMulti={false}
                      styles={customStyles}
                    />
                  </div>
                  {/* last message */}
                  <div className="encounter-search-menu-item ">
                    <label>Last Message</label>
                    <div className="d-flex flex-column">
                      <span className="dp-span">
                        <DatePicker
                          date={
                            searchParams.lastContactFrom
                              ? moment(searchParams.lastContactFrom)
                              : null
                          }
                          handleDateChange={(e) => datePickerParamHandler(e, "lastContactFrom")}
                          placeholder="Start of Date Range"
                        />
                      </span>
                      <span className="dp-span">
                        <DatePicker
                          date={
                            searchParams.lastContactTo ? moment(searchParams.lastContactTo) : null
                          }
                          handleDateChange={(e) => datePickerParamHandler(e, "lastContactTo")}
                          placeholder="End of Date Range"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="encounter-search-menu-item pl-2">
                    <label>Date of Prescription</label>
                    <div className="d-flex flex-column">
                      <span className="dp-span ">
                        <DatePicker
                          date={
                            searchParams.prescribedFrom ? moment(searchParams.prescribedFrom) : null
                          }
                          handleDateChange={(e) => datePickerParamHandler(e, "prescribedFrom")}
                          placeholder="Start of Date Range"
                        />
                      </span>
                      <span className="dp-span">
                        <DatePicker
                          date={
                            searchParams.prescribedTo ? moment(searchParams.prescribedTo) : null
                          }
                          handleDateChange={(e) => datePickerParamHandler(e, "prescribedTo")}
                          placeholder="End of Date Range"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="encounter-search-menu-item ">
                    <label>Date of Scribe Handoff</label>
                    <div className="d-flex flex-column">
                      <span className="dp-span">
                        <DatePicker
                          date={searchParams.scribedFrom ? moment(searchParams.scribedFrom) : null}
                          handleDateChange={(e) => datePickerParamHandler(e, "scribedFrom")}
                          placeholder="Start of Date Range"
                        />
                      </span>
                      <span className="dp-span">
                        <DatePicker
                          date={searchParams.scribedTo ? moment(searchParams.scribedTo) : null}
                          handleDateChange={(e) => datePickerParamHandler(e, "scribedTo")}
                          placeholder="End of Date Range"
                        />
                      </span>
                    </div>
                  </div>
                </div>

                <div className="encounter-search-menu-block mb-5">
                  <div className="encounter-search-menu-item ">
                    <label>Date Provider Note Signed</label>
                    <div className="d-flex flex-column">
                      <span className="dp-span">
                        <DatePicker
                          date={
                            searchParams.signedNoteFrom ? moment(searchParams.signedNoteFrom) : null
                          }
                          handleDateChange={(e) => datePickerParamHandler(e, "signedNoteFrom")}
                          placeholder="Start of Date Range"
                        />
                      </span>
                      <span className="dp-span">
                        <DatePicker
                          date={
                            searchParams.signedNoteTo ? moment(searchParams.signedNoteTo) : null
                          }
                          handleDateChange={(e) => datePickerParamHandler(e, "signedNoteTo")}
                          placeholder="End of Date Range"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="encounter-search-menu-item">
                    <label>Patient Video Connection Attempts</label>
                    <div className="d-flex flex-column">
                      <span>
                        <Select
                          name="patientNumOfAttemptsOperator"
                          isSearchable={true}
                          onChange={selectParamHandler as any}
                          value={setSelectInputValue(
                            searchParams.patientNumOfAttemptsOperator
                              ? searchParams.patientNumOfAttemptsOperator
                              : "",
                            timeOperator,
                          )}
                          className="last-input-item"
                          menuPortalTarget={bodyRef.current}
                          placeholder="Select"
                          options={timeOperator}
                          isMulti={false}
                          styles={customStyles}
                        />
                      </span>
                      <span>
                        <input
                          type="number"
                          className="last-input-item input-text-item mt-1"
                          placeholder="Attempts to Connect"
                          name="patientNumOfAttempts"
                          onChange={inputParamHandler}
                          value={searchParams?.patientNumOfAttempts}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="encounter-search-menu-item">
                    <label>Patient Exited Waiting Room</label>
                    <Select
                      name="patientExitedWaitingRoomPrematured"
                      onChange={selectParamHandler as any}
                      value={setSelectInputValue(
                        searchParams.patientExitedWaitingRoomPrematured
                          ? searchParams.patientExitedWaitingRoomPrematured
                          : "",
                        exitedWaitingRoomPrem,
                      )}
                      isSearchable={true}
                      className="last-input-item"
                      menuPortalTarget={bodyRef.current}
                      placeholder="Select"
                      options={exitedWaitingRoomPrem}
                      styles={customStyles}
                      isMulti={false}
                    />
                  </div>
                  <div className="encounter-search-menu-item">
                    <label>Patient Entered Waiting Room</label>
                    <Select
                      name="neverEnteredWaitingRoom"
                      onChange={selectParamHandler as any}
                      value={setSelectInputValue(
                        searchParams.neverEnteredWaitingRoom
                          ? searchParams.neverEnteredWaitingRoom
                          : "",
                        neverEnteredWaitingRoom,
                      )}
                      isSearchable={true}
                      className="last-input-item"
                      menuPortalTarget={bodyRef.current}
                      placeholder="Select"
                      options={neverEnteredWaitingRoom}
                      isMulti={false}
                      styles={customStyles}
                    />
                  </div>

                  <div className="encounter-search-menu-item">
                    <label>EMR Encounter ID</label>
                    <input
                      type="text"
                      className="last-input-item input-text-item"
                      placeholder="Encounter ID"
                      name="encounterId"
                      onChange={inputParamHandler}
                      value={searchParams?.encounterId}
                    />
                  </div>
                </div>
                <div className="encounter-search-menu-block ">
                  <div className="encounter-search-menu-item">
                    <label>Video Time</label>
                    <div className="d-flex flex-column">
                      <span>
                        <Select
                          name="providerVideoChatTimeOperator"
                          isSearchable={true}
                          onChange={selectParamHandler as any}
                          value={setSelectInputValue(
                            searchParams.providerVideoChatTimeOperator
                              ? searchParams.providerVideoChatTimeOperator
                              : "",
                            timeOperator,
                          )}
                          className="last-input-item"
                          menuPortalTarget={bodyRef.current}
                          placeholder="Select"
                          options={timeOperator}
                          isMulti={false}
                          styles={customStyles}
                        />
                      </span>
                      <span className="d-flex">
                        <input
                          type="number"
                          className="time-input-item input-text-item mt-1"
                          placeholder="Minutes"
                          name="providerVideoChatTime"
                          onChange={(e) => handleTimeInput("providerVideoChatTime", "min", e)}
                          value={videoTime?.min}
                        />
                        <span className=" pl-1 ">
                          <input
                            type="number"
                            className="time-input-item input-text-item mt-1"
                            placeholder="Seconds"
                            name="providerVideoChatTime"
                            onChange={(e) => handleTimeInput("providerVideoChatTime", "sec", e)}
                            value={videoTime?.sec}
                          />
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="encounter-search-menu-item">
                    <label>Organization Type</label>
                    <Select
                      name="orgType"
                      onChange={selectParamHandler as any}
                      value={setSelectInputValue(
                        searchParams.orgType
                          ? searchParams.orgType
                          : "",
                        orgTypes,
                      )}
                      isSearchable={true}
                      className="last-input-item"
                      menuPortalTarget={bodyRef.current}
                      placeholder="Select"
                      options={orgTypes}
                      styles={customStyles}
                      isMulti={false}
                    />
                  </div>
                  <div className="encounter-search-menu-item" style={{ width: "200px" }}>
                    <label>Search In All Fields</label>
                    <input
                      type="text"
                      className="last-input-item input-text-item"
                      placeholder="Name, Location, Practice, etc."
                      name="universal"
                      onChange={inputParamHandler}
                      value={searchParams?.universal}
                    />
                  </div>
                  <div className="encounter-search-menu-item" style={{ width: "200px" }}>{ }</div>
                  <div className="encounter-search-menu-item" style={{ width: "200px" }}>{ }</div>
                </div>
              </>
            )}
          </form>
        </div>
      </div>

      <div className="btn-group">
        <div className="pagination-allEnounters">
          <PaginationAE
            totalCount={totalCount}
            pageSize={pageSize}
            pageStart={pageStart}
            onChange={onChange}
            page={page}
            setPage={setPage}
          />
        </div>
        <div className="encounter-search-menu-buttons">
          <button
            color="link"
            className="customize-table-btn"
            type="button"
            onClick={() => setShowModal(!showModal)}
            disabled={!!showModal}
          >
            Customize Table Columns
          </button>
          {showCancel && (
            <button className="encounter-cancel-button" type="button" onClick={cancelHandler}>
              Cancel
            </button>
          )}
          <button
            className={`encounter-search-button ${disableSearch ? "disabled" : ""} `}
            type="button"
            disabled={disableSearch}
            onClick={searchHandler}
          >
            Search
          </button>
        </div>
      </div>
      {showFilterStats && (
        <MonitorStatsTable
          setMultiSelectInputValue={setMultiSelectInputValue}
          handleSearch={visitStatsClickHandler}
          data={chartStats}
          searchParams={searchParams?.latestVisitStatus}
          visitCount={visitCount}
          vCountProvider={vCountProvider}
          providerPieTotal={providerPieTotal}
          vCountPractice={vCountPractice}
          practicePieTotal={practicePieTotal}
          timeFrame={timeFrame}
          setTimeFrame={setTimeFrame}
          filterFromChart={filterFromChart}
          timeFrameType={timeFrameType}
          setTimeFrameType={setTimeFrameType}
          disabled={disableTimeFrame}
          loadDBCompare={loadDBCompare}
          currentUser={currentUser}
        />
      )}
    </>
  );
};

export default MonitorEncounterFilter;