import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./chatHistory.scss";
import moment from "moment";
import Octicon, { Check } from "@githubprimer/octicons-react";
import momentz from "moment-timezone";
import { PROVIDER_TIME_ZONE } from "../../constants/Providers";
import { Badge, Button, Input, Tooltip, UncontrolledTooltip } from "reactstrap";
import api from "../../api";
import {
  IChatHistoryItem,
  IChatTemplatesMessages, ICurrentUser,
  IPrevEncountersChat,
} from "../../constants/Types";
import TemplateEditModal from "./TemplatesEditModal";
import Select from "react-select";
import { connect } from "react-redux";
import { IAppState } from "../../reducer";
import { COMPONENT_LOAD, ENCOUNTER_CHAT_TEMPLATE } from "../../constants/actionTypes";
import { errorLogger } from "../../utils";
import isEqual from "react-fast-compare";

const uploadLinkToChatMessage = "Press Send button to send the upload link";
const mapStateToProps = (state: IAppState) => {
  return {
    chatTemplates: state.componentData[ENCOUNTER_CHAT_TEMPLATE],
  };
};
const mapDispatchToProps = (dispatch: Function) => ({
  onUpdateTemplates: (payload) =>
    dispatch({ type: COMPONENT_LOAD, subtype: ENCOUNTER_CHAT_TEMPLATE, payload }),
});
const chatHeight = {
  height: "100%",
};

type ITemplateExt =
  | ITemplateExt
  | {
      edit?: boolean;
      upload?: boolean;
    };
type GroupedValuesType = {
  label: string;
  options: ITemplateExt[];
};

const defaultOptions: ITemplateExt[] = [
  { label: "Add/Edit Template", value: "Add/Edit Template", edit: true },
];
const defaultGeneralOptions: ITemplateExt[] = [
  { label: "Send request to upload file", value: "Send request to upload file", upload: true },
];
const groupedDefaultOptions = [
  {
    label: "--",
    options: defaultOptions,
  },
  {
    label: "General",
    options: defaultGeneralOptions,
  },
];

type Props = {
  currentUser?:ICurrentUser;
  chatHistoryItems: IChatHistoryItem[];
  prevEncountersMessages?: IPrevEncountersChat[];
  encounterID?: string;
  updateChat?: Function;
  setIdleTime?: Function;
  location: string;
  message?: string;
  isInEncounter?: boolean;
  visitClosed?: boolean;
  onOpenImageModal: (rawpath: string) => void;
  onUpdateTemplates: (templates: any) => void;
  chatTemplates: { data: IChatTemplatesMessages };
};
type IChatItemProps = {
  item: IChatHistoryItem;
  showDate: (item: IChatHistoryItem, index: number) => void;
  index: number;
  timezone: string;
  onOpenImageModal: (path: string) => void;
  providerTimeZone: string;
  id: string;
};

const ChatItem: FC<IChatItemProps> = ({
  item,
  showDate,
  index,
  timezone,
  onOpenImageModal,
  providerTimeZone,
  id,
}) => {
  return (
    <div id={id} className={styles.chatElement}>
      {item.createdAt && showDate(item, index)}
      {item.writtenBy ? (
        <>
          <div
            className={`${styles.chatHeader} ${
              item.patientMessage ? styles.patientHeader : styles.providerHeader
            }`}
          >
            <span className={styles.headerText}>
              {item.writtenBy} {item.patientMessage && "- Patient"}
              {item.autoGenerated && (
                <span className={`${styles.autogeneratedText} ml-2`}>Auto Generated</span>
              )}
            </span>
            <span className={styles.sendingTime}>
              {momentz.tz(item.createdAt, timezone).format("LT").toLowerCase()}{" "}
              {providerTimeZone.toLowerCase()}
            </span>
          </div>
          <div
            className={`${styles.chatContent} ${item.autoGenerated && styles.autogeneratedText}`}
          >
            {item.isImage ? (
              <div
                className={styles.image}
                dangerouslySetInnerHTML={{ __html: item.textContent }}
                onClick={() => onOpenImageModal(item.rawImgPath || "")}
              />
            ) : (
              <div>
                {item.attachedLinkCreatedAt ? (
                  <span dangerouslySetInnerHTML={{ __html: item.textContent }} />
                ) : (
                  <span>{item.textContent}</span>
                )}
                {item.readByPatient && !item.patientMessage && !item.autoGenerated && (
                  <div className="d-flex justify-content-end mt-2">
                    <span className={styles.sendingTime}>
                      Patient Read
                      <Badge id={"TooltipReadAt-" + index} className="ml-1" color="success" pill>
                        <Octicon size={8} icon={Check} />
                      </Badge>
                      <UncontrolledTooltip
                        style={{ fontSize: 12, backgroundColor: "#4a8fe7" }}
                        target={"TooltipReadAt-" + index}
                      >
                        Read at:{" "}
                        {item.readByPatientAt !== null
                          ? moment(item.readByPatientAt).format("MMMM Do YYYY, h:mm:ss a")
                          : "N/A"}
                      </UncontrolledTooltip>
                    </span>
                  </div>
                )}
                {(item.attachedLinkCreatedAt || item.linkOpenDate) && (
                  <div className="d-flex justify-content-between mt-2">
                    <div className="d-flex flex-column">
                      {item.attachedLinkCreatedAt && (
                        <span className={styles.sendingTime}>
                          Created at:{" "}
                          {momentz
                            .tz(item.attachedLinkCreatedAt, timezone)
                            .format("MMMM Do, h:mm a")
                            .toLowerCase()}{" "}
                        </span>
                      )}
                      {item.linkOpenDate && (
                        <span className={styles.sendingTime}>
                          Opened at:{" "}
                          {momentz
                            .tz(item.linkOpenDate, timezone)
                            .format("MMMM Do, h:mm a")
                            .toLowerCase()}{" "}
                        </span>
                      )}
                    </div>

                    {item.isLinkUsed && (
                      <span className={styles.sendingTime}>
                        Link Used{" "}
                        <Badge color="primary" pill>
                          <Octicon size={8} icon={Check} />
                        </Badge>
                      </span>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className={styles.chatHeader}>
            <span />
            <span className={styles.sendingTime}>
              {momentz.tz(item.createdAt, timezone).format("LT").toLowerCase()}{" "}
              {providerTimeZone.toLowerCase()}
            </span>
          </div>
          <div className={`${styles.chatContent} ${styles.autogeneratedText}`}>
            {item.isImage ? <img src={item.textContent} /> : <span>{item.textContent}</span>}
          </div>
          {item.endOfPrevMessages && (
            <div className={styles.endOfPrevMessages}>
              <span className={styles.sendingTime}>
                Previous visit messages are not included in the current visit notes
              </span>
              <div className={styles.blueBox}>
                <div className={styles.blueBorder} />
                <span>Start of current follow-up visit</span>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
let sendMessageBtnTimeout;

const ChatHistory: FC<Props> = ({
  encounterID,
  chatHistoryItems,
  prevEncountersMessages,
  location,
  isInEncounter = false,
  updateChat,
  visitClosed,
  message,
  onOpenImageModal,
  onUpdateTemplates,
  chatTemplates,
  currentUser,
  ...props
}) => {
  const patientTimeZone = momentz.tz(PROVIDER_TIME_ZONE[location]).zoneAbbr();
  const messagesEndRef: any = useRef(null);
  const [chatMessage, setChatMessage] = useState(message || "");
  const [notSentMessage, setNotSentMessage] = useState("");

  const [arrOfMessages, setArrOfMessages] = useState<IChatHistoryItem[]>([]);
  const [groupedOptions, setGroupedOptions] = useState<GroupedValuesType[]>([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showUndoButton, setShowUndoButton] = useState(false);

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const providerTimeZone = momentz.tz(timezone).zoneAbbr();

  const scrollToBottom = () => {
    const scroll = messagesEndRef.current?.scrollHeight - messagesEndRef.current?.clientHeight;
    messagesEndRef.current?.scrollTo(0, scroll);
  };
  const onSendMessageRequest = async () => {
    if (chatMessage) {
      if (chatMessage === uploadLinkToChatMessage) {
        askForImage();
        setChatMessage("");
        setNotSentMessage("");
        return;
      }
      try {
        await api.Encounters.sendMessage({ message: chatMessage }, encounterID!);
        updateChat && updateChat();
        setChatMessage("");
        setNotSentMessage("");
        scrollToBottom();
      } catch (e) {
        setChatMessage(notSentMessage);
        errorLogger(e);
      }
      finally {
        setShowUndoButton(false)
      }
    }
  };

  const onSendMessage = () => {
    if(chatMessage){
      setNotSentMessage(chatMessage)
      setChatMessage("")
      setShowUndoButton(true)
      sendMessageBtnTimeout = setTimeout(onSendMessageRequest,5000);
      setTimeout(scrollToBottom,100);
    }
  }

 const onStopSendingMessage = () => {
   setShowUndoButton(false)
   setChatMessage(notSentMessage);
   clearTimeout(sendMessageBtnTimeout)
 }

  const onMessageChange = (message) => {
    setChatMessage(message);
  };

  const collectAllMessages = () => {
    if (!prevEncountersMessages || prevEncountersMessages.length < 1) {
      setArrOfMessages(chatHistoryItems);
      return;
    }
    let newArr: IChatHistoryItem[] = [];
    for (let i = 0; i < prevEncountersMessages.length; i++) {
      if (prevEncountersMessages[i].chatHistoryItems && prevEncountersMessages[i].chatHistoryItems.length > 0) {
        newArr.push(...prevEncountersMessages[i].chatHistoryItems);
      }
    }
    if (newArr.length > 0) {
      newArr[newArr.length - 1].endOfPrevMessages = true;
    }
    newArr.push(...chatHistoryItems);
    setArrOfMessages(newArr);
  };

  const handleEnterPress = (e) => {
    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();

      setChatMessage(chatMessage + "\n");
      props.setIdleTime && props.setIdleTime();

      return;
    }
    if (e.key === "Enter") {
      e.preventDefault();
      onSendMessage();
    }
  };
  useEffect(() => {
    const getTemplates = async () => {
      try {
        onUpdateTemplates(api.Encounters.getChatTemplates(encounterID!));
        const grouped = [
          {
            label: "General",
            options: [...(chatTemplates?.data?.generalTemplates || []), ...groupedOptions],
          },
          { label: "Custom", options: chatTemplates?.data?.providerTemplates || [] },
          { label: "--", options: defaultOptions },
        ];
        setGroupedOptions(grouped);
      } catch (e) {
        errorLogger(e);
      }
    };
    if (encounterID) {
      getTemplates();
    }
  }, []);

  useEffect(() => {
    const grouped = [
      {
        label: "General",
        options: [...(chatTemplates?.data?.generalTemplates || []), ...defaultGeneralOptions],
      },
      { label: "Custom", options: chatTemplates?.data?.providerTemplates || [] },
      { label: "--", options: defaultOptions },
    ];
    setGroupedOptions(grouped);
  }, [chatTemplates?.data]);

  useEffect(() => {
    scrollToBottom();
  }, [arrOfMessages.length]);

  useEffect(() => {
    collectAllMessages();
  }, [chatHistoryItems]);

  const showDate = (item, index) => {
    if (
      arrOfMessages[index - 1] &&
      moment(arrOfMessages[index].createdAt).format("ll") ===
        moment(arrOfMessages[index - 1].createdAt).format("ll")
    ) {
      return;
    } else {
      return (
        <div className={styles.chatItemDate}>
          <span>- {moment(item.createdAt).format("ll")} -</span>
        </div>
      );
    }
  };
  const pasteText = (value) => {
    setChatMessage(value);
  };
  const askForImage = async () => {
    try {
      await api.Encounters.askForImage(encounterID!);
      updateChat && updateChat();
      scrollToBottom();
    } catch (e) {
      console.log(e);
    }
  };

  const onSelectOption = (option) => {
    if (option.upload) {
      pasteText(uploadLinkToChatMessage);
      return;
    }
    if (option.edit) {
      setShowEditModal(true);
      return;
    }
    if (option.value) {
      pasteText(option.parsedValue);
    }
  };
  return (
    <div className={styles.chatHistoryContainer}>
      <TemplateEditModal
        encounterID={encounterID}
        onUpdateTemplates={onUpdateTemplates}
        onClose={() => setShowEditModal(false)}
        providerTemplates={chatTemplates?.data?.providerTemplates || []}
        isShown={showEditModal}
      />
      <div className={`${styles.headerWrapper} ${isInEncounter && styles.shadows}`}>
        <div className="d-flex justify-content-between mt-2">
          <span className={styles.headerText}>Patient Messaging</span>
          {chatHistoryItems[0] && (
            <span className={`mt-auto ${styles.sendingTime}`}>
              Encounter Started: {moment(chatHistoryItems[0].createdAt).format("llll")}
            </span>
          )}
        </div>
        {!isInEncounter && (
          <div className={styles.chatInfo}>
            <span>
              Patient local time: {momentz.tz(PROVIDER_TIME_ZONE[location]).format("LT")}{" "}
              {patientTimeZone}
            </span>
          </div>
        )}
      </div>
      <div
        ref={messagesEndRef}
        className={styles.chatItemsWrapper}
        style={isInEncounter ? chatHeight : {}}
      >
        {arrOfMessages.map((item, index) => {
          return (
            <ChatItem
              key={`message-item-${index}`}
              id={`qa-chatHistoryItem-${index}`}
              item={item}
              index={index}
              onOpenImageModal={onOpenImageModal}
              providerTimeZone={providerTimeZone}
              timezone={timezone}
              showDate={showDate}
            />
          );
        })}
        {showUndoButton && (
          <div className={`${styles.chatElement} ${styles.undoAvailable}`}>
            <div className={`${styles.chatHeader} ${styles.providerHeader}`}>
            <span className={styles.headerText}>
              {currentUser?.firstName} {currentUser?.lastName}
            </span>
              <span className={styles.sendingTime}>
              {momentz.tz(Date.now(), timezone).format("LT").toLowerCase()}{" "}
                {providerTimeZone.toLowerCase()}
            </span>
            </div>
            <div className={styles.chatContent}>
              <div>
                <span>{notSentMessage}</span>
              </div>
              <div className="d-flex justify-content-end mt-2">
                Sending...
                <Badge role="button" onClick={onStopSendingMessage} className="ml-1" color="warning" pill>
                  Undo
                </Badge>
              </div>
            </div>
          </div>
        )}
      </div>
      {isInEncounter &&
        (visitClosed ? (
          <div className={styles.chatClosed} onKeyPress={handleEnterPress}>
            <div className="d-flex w-100 align-items-center justify-content-between">
              <span className={styles.headerText}>Visit Closed</span>
              <div className={`${styles.chatInfo} mt-0`}>
                <span>
                  Patient local time: {momentz.tz(PROVIDER_TIME_ZONE[location]).format("LT")}{" "}
                  {patientTimeZone}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.chatInputContainer} onKeyPress={handleEnterPress}>
            <Input
              value={chatMessage}
              type="textarea"
              id="consentText"
              className={styles.chatInput}
              placeholder=""
              onChange={(e) => onMessageChange(e.target.value)}
            />
            <div className="d-flex justify-content-between mt-2">
              <Select
                menuPlacement={"top"}
                placeholder="Select Template"
                value={chatMessage === uploadLinkToChatMessage ? defaultOptions[1] : null}
                className="w-100 mr-3"
                options={groupedOptions}
                onChange={onSelectOption}
              />
              <Button type={"submit"} disabled={showUndoButton} onClick={onSendMessage} className={styles.sendButton}>
                Send
              </Button>
            </div>
            <div className="d-flex align-items-end justify-content-between">
              <div className={styles.chatInfo}>
                <span>
                  Patient local time: {momentz.tz(PROVIDER_TIME_ZONE[location]).format("LT")}{" "}
                  {patientTimeZone}
                </span>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatHistory);
