import React, { useEffect, useState, useRef } from "react";
import AsyncSelect from "react-select/lib/Async";
import Select, { AsyncCreatable } from "react-select";
//type Props = {
// items: {value: string, label: string}[]
// listSource: (searchValue: string) => void
// handleChange: (selected: {value: string, label: string}) => void
// field: string
//selectedItem: string
// error: boolean
// }
const customStyles = {
    container: (provided) => ({
        ...provided,
        borderRadius: "6px !important",
        border: "1px solid #94A4B9 !important"
    }),
    valueContainer: (provided) => ({
        ...provided,
        maxHeight: "40px",
        overflow: "hidden",
        backgroundColor: "#F2F5F9",
        borderRadius: "6px",
        // borderColor: "#94A4B9"
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: "#4A8FE7",
        backgroundColor: "#F2F5F9",
    }),
    clearIndicator: (provided) => ({
        ...provided,
        padding: "8px 2px",
        innerHeight: "9px",
        backgroundColor: "#F2F5F9",
        color: "#4A8FE7",
    }),
    indicatorSeparator: (provided) => ({
        color: "#4A8FE7",
        backgroundColor: "#F2F5F9",
    })
};
const errorStyles = {
    container: (provided) => ({
        ...provided,
        borderRadius: "6px !important",
        border: "2px solid #DC4545 !important"
    }),
    valueContainer: (provided) => ({
        ...provided,
        maxHeight: "40px",
        overflow: "hidden",
        backgroundColor: "#F2F5F9",
        borderRadius: "6px",
        // borderColor: "#94A4B9"
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: "#4A8FE7",
        backgroundColor: "#F2F5F9",
    }),
    clearIndicator: (provided) => ({
        ...provided,
        padding: "8px 2px",
        innerHeight: "9px",
        backgroundColor: "#F2F5F9",
        color: "#4A8FE7",
    }),
    indicatorSeparator: (provided) => ({
        color: "#4A8FE7",
        backgroundColor: "#F2F5F9",
    })
};

const formatOption = (item: string) => ({ value: item, label: item })

const MedicationSelect = ({ loadOptions, listSource, handleChange, field, selectedItem, isDisabled, medChanged, editing, copy, error, medOrSupply }) => {
    const [defaultOptions, setDefaultOptions] = useState([]);
    const bodyRef = useRef(document.querySelector("body"));
    useEffect(() => {

        const getOptions = async () => {

            let result: any = null
            try {
                result = await listSource(selectedItem.value)

                if (result?.items && medOrSupply === "Medication") {
                    let options = result?.items?.map((item, idx) => ({ value: `${item.Name} ${idx}`, label: item.NameWithRouteDoseForm }))
                    setDefaultOptions(options)
                } else {
                    let options = result?.items?.map((item) => ({ value: item.Name, label: item.Name }))
                    setDefaultOptions(options)
                }

            } catch (err) { }
        }

        if ((editing || copy) && !defaultOptions.length) {
            getOptions()
        }

    }, [selectedItem])



    const onInputChange = (value, name) => {
        if (defaultOptions.length) {
            setDefaultOptions([])
        }

        handleChange(value)
    }

    // add check for value beacause some medications may have same value (ProductName)
    const selectValue = medChanged?.ProductNameWithRoute && selectedItem?.label && medChanged?.ProductNameWithRoute === selectedItem?.label ? selectedItem : null
    if (medOrSupply === "Supply") {
        return (
            <AsyncCreatable
                loadOptions={loadOptions}
                onChange={(e) => onInputChange(e, field)}
                isMulti={false}
                isClearable
                backspaceRemovesValue={true}
                defaultOptions={false}
                placeholder={"Type to Select"}
                className="form-medication-select"
                styles={error ? errorStyles : customStyles}
                isDisabled={isDisabled}
                name={field}
                value={selectedItem ? selectedItem : null}
                menuPortalTarget={bodyRef.current}
            />
        )
    }

    return (
        <AsyncSelect
            loadOptions={loadOptions}
            onChange={(e) => onInputChange(e, field)}
            isMulti={false}
            backspaceRemovesValue={true}
            placeholder={"Type to Select"}
            className="form-medication-select"
            styles={error ? errorStyles : customStyles}
            isDisabled={isDisabled}
            name={field}
            value={selectValue}
            defaultOptions={(editing || copy) && defaultOptions.length ? defaultOptions : false}
            menuPortalTarget={bodyRef.current}
        />
    )
}

export default MedicationSelect