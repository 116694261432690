import React, { useState, useEffect, FC } from "react";
import { connect } from "react-redux";
import { Button, Input, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import { Link, RouteComponentProps } from "react-router-dom";
import Octicon, { ArrowLeft, Check, X } from "@githubprimer/octicons-react";
import Arrow from "../../images/ArrowNavigator.svg";
import CircularProgressbar from "react-circular-progressbar";
import classNames from "classnames";

import styles from "./index.scss";
import NavBar from "../Provider/NavBar";
import DashboardIcon from "../../images/DashboardIcon.svg";
import Spinner from "../../images/Spinner.svg";
import api from "../../api";
import EncounterList from "./EncounterList";
import { calculatePercentage } from "../ProviderManagement/List";
import PDFView from "../Provider/PDFView";
import AuditButton from "./AuditButton";
import { STATES } from "../../constants/Providers";
import {
  EncounterSummaryListItem,
  IAuditStatistic,
  IProviderParameters,
} from "../../constants/Types";
import { IAppState } from "../../reducer";
import { hasPermission, PERMISSION_MEDICAL_DIRECTOR, PERMISSION_SUPERVISING_PHYSICIAN, PERMISSION_PAGE } from "../../constants/Permissions";
import { COMPONENT_LOAD, NAVIGATE_TO } from "../../constants/actionTypes";
import { store } from "../../store";

function isEmpty(obj: object) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

const AuditInfo = ({
  percentage,
  threshold,
  size = "32px",
  supervisingPhysicianID,
  state = "",
  children,
  className,
  STATES,
  itemCount = 0,
}) => {
  const [supervisingPhysician, setSupervisingPhysician] = useState("");
  const chartPercentage = Math.floor(Math.min(percentage / threshold, 1) * 100);

  useEffect(() => {
    if (supervisingPhysicianID) {
      const getProvider = async () => {
        const supervisingPhysician = await api.Providers.get(supervisingPhysicianID);
        const fname = `${supervisingPhysician.firstName} ${supervisingPhysician.lastName}`;
        setSupervisingPhysician(fname);
      };

      getProvider();
    }
  }, [supervisingPhysicianID]);

  return (
    <div className={classNames(className, "audit-info", "big ")}>
      <div className="audit-details">
        <div className="state">{STATES[state]}</div>
        <div className="supervising">
          Supervising Physician: {supervisingPhysician ? supervisingPhysician : "N/A"}
        </div>
        <div></div>
      </div>
      {!!supervisingPhysician && itemCount > 0 && (
        <div className="d-flex mt-3">
          <div
            className="audit-progress-bar-container"
            style={{
              height: size,
              width: size,
              minWidth: size,
            }}
          >
            <CircularProgressbar
              percentage={chartPercentage}
              styles={{
                path: { stroke: chartPercentage === 100 ? "#ADC85E" : "#3678CC" },
                trail: { stroke: "#c7c7c7" },
              }}
            />
            {chartPercentage === 100 && <Octicon icon={Check} className="audit-checkmark" />}
          </div>
          <div className="audit-progress-text">{children}</div>
        </div>
      )}
    </div>
  );
};

type NavigatorProps = {
  filters: IAuditStatistic[];
  setSelectedMonth: (value: any) => void;
  selectedMonth: Partial<IAuditStatistic>;
};

const Navigator: FC<NavigatorProps> = ({ filters = [], selectedMonth, setSelectedMonth }) => {
  let [idx, setIdx] = useState(0);
  let len = filters.length;
  useEffect(() => {
    if (selectedMonth) {
      const idx = filters.findIndex((i: any) => i.value === selectedMonth.value);
      if (idx > -1) {
        setIdx(idx);
      }
    }
  }, [selectedMonth, filters]);

  const onClick = (val: string) => {
    let i = 0;
    if (val === "sub" && idx < 1) {
      i = 0;
    } else if (val === "add" && idx >= len - 1) {
      i = len - 1;
    } else {
      i = val === "add" ? ++idx : --idx;
    }

    setIdx(i);
    setSelectedMonth(filters[idx]);
  };

  return (
    <div className={styles.monthNavigator}>
      <Arrow
        className={`${styles.arrowLeft} qa-audit-month-leftarrow-btn`}
        onClick={() => onClick("sub")}
      />
      <span className={`${styles.text} qa-audit-month`}>
        {!isEmpty(selectedMonth) && filters[idx].label}
      </span>
      <Arrow
        className={`${styles.arrowRight} qa-audit-month-rightarrow`}
        onClick={() => onClick("add")}
      />
    </div>
  );
};

type AuditTableProps = {
  setStage: React.Dispatch<React.SetStateAction<string>>;
  handlePDFClick: (
    key: string | null,
    note: string | null,
    url?: string | undefined,
    audited?: boolean | undefined,
    state?: string,
  ) => Promise<void>;
  handleAuditClick: (auditorDisagree: boolean) => Promise<void>;
  selectedMonth: Partial<IAuditStatistic>;
  audit: any;
  stage: string;
  items: EncounterSummaryListItem[];
  note: string | null;
  setAudit: React.Dispatch<any>;
  setNote: React.Dispatch<React.SetStateAction<string | null>>;
  state: string;
  match: boolean;
};

const AuditTable: FC<AuditTableProps> = ({
  handlePDFClick,
  handleAuditClick,
  setStage,
  selectedMonth,
  audit,
  stage,
  items,
  note,
  setNote,
  setAudit,
  state,
  match,
}) => {
  const onPDFClick = (key, auditNotes, noteURL, audited) => {
    handlePDFClick(key, auditNotes, noteURL, audited, state);
  };
  return (
    <>
      <EncounterList
        items={items}
        onPDFClick={onPDFClick}
        selectedMonth={selectedMonth}
        match={match}
      />
      <PDFView url={audit.url} onClose={() => setAudit({ url: null, key: null, audited: null })}>
        {!audit.audited &&
          (stage === "edit" ? (
            <div className={styles.auditEdit}>
              <Input
                type="textarea"
                value={note || ""}
                onChange={(e) => setNote(e.target.value)}
                className={styles.auditInput}
              />
              <div className={styles.auditPdfOptions}>
                <Button className={styles.cancel} onClick={() => setStage("confirm")} color="link">
                  Cancel
                </Button>
                <AuditButton
                  type="primary"
                  icon={Check}
                  onClick={() => handleAuditClick(true)}
                  className={styles.wide}
                >
                  I agree with the assessment, diagnosis & treatment plan with the noted changes.
                </AuditButton>
              </div>
            </div>
          ) : (
            <div className={styles.auditPdfOptions}>
              <AuditButton type="primary" icon={Check} onClick={() => handleAuditClick(false)}>
                I agree with the assessment, diagnosis & treatment plan.
              </AuditButton>
              <AuditButton type="error" icon={X} onClick={() => setStage("edit")}>
                The assessment, diagnosis & treatment plan needs edits.
              </AuditButton>
            </div>
          ))}
      </PDFView>
    </>
  );
};

type AuditInfoProps = {
  currentUser: any;
  providerID: string;
  onLoadAuditStatistics: any;
}
const mapStateToProps = (state: any, ownProps: any) => {
  return {
    currentUser: state.common.currentUser,
    providerID: ownProps.match.params.providerID,
  };
}

const mapDispatchToProps = (dispatch: any) => ({
  onLoadAuditStatistics: (payload) => dispatch({type: COMPONENT_LOAD, payload: payload}),
})

const Audit: FC<AuditInfoProps> = ({ providerID, currentUser, onLoadAuditStatistics}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [name, setName] = useState<string>("");
  const [states, setStates] = useState<{ value: string; label: string }[]>([]);
  const [filters, setFilters] = useState<IAuditStatistic[]>([]);
  const [provider, setprovider] = useState<Partial<IProviderParameters>>({});
  const [selectedMonth, setSelectedMonth] = useState<Partial<IAuditStatistic>>({});
  const [selectedState, setSelectedState] = useState<string>("none");
  const [notYetAudited, setNotYetAudited] = useState<boolean>(false);
  const [audit, setAudit] = useState<any>({
    url: null,
    key: null,
    state: "",
  });
  const [stage, setStage] = useState("confirm");
  const [note, setNote] = useState<string | null>("");
  const [auditItems, setAuditItems] = useState<{ [key: string]: EncounterSummaryListItem[] }>({});
  const currentCapabilities = (currentUser || {}).capabilities || {};
  const currentGeographic = currentCapabilities.geographic || [];
  const isMedicalDirector = hasPermission(
    (currentUser || {}).permissions,
    PERMISSION_MEDICAL_DIRECTOR,
  );

  const setLoader = (selectedState: string) => {
    if (selectedMonth) {
      const stateFinished =
        selectedMonth.stateFinished && selectedMonth.stateFinished[selectedState]
          ? selectedMonth.stateFinished[selectedState]
          : 0;
      const stateAudited =
        selectedMonth.stateAudited && selectedMonth.stateAudited[selectedState]
          ? selectedMonth.stateAudited[selectedState]
          : 0;

      const percent = calculatePercentage(stateAudited, stateFinished);

      return percent;
    }
  };

  const isProviderSupervisingPhysician = (supervisingPhysicians, providerID: string) => {
    return Object.values(supervisingPhysicians || {}).includes(providerID);
  };

  function fethchAuditData() {
    if(!currentUser.permissions.includes(PERMISSION_SUPERVISING_PHYSICIAN && PERMISSION_PAGE)) {
     return store.dispatch({type: NAVIGATE_TO, targetPath: "/permissions-issue"})
    }
    const getVisits = api.Auditing.encounters( {
      providerID,
      audited: notYetAudited ? false : "",
      month: selectedMonth ? selectedMonth.value : undefined,
      pageToken: "",
    });

    let items: EncounterSummaryListItem[] = [];

    getVisits.next().then(async (val: any) => {
      let nextPageToken = val.value.nextPageToken;
      items = items.concat(val.value.items);

      while (nextPageToken) {
        await getVisits.next().then((value) => {
          nextPageToken = value.value!.nextPageToken;
          items = items.concat(value.value!.items);
        });
      }

      let sortedItems = items.reduce((sortedItems, value) => {
        if (sortedItems[value.location]) {
          sortedItems[value.location].push(value);
        } else {
          sortedItems[value.location] = [value];
        }

        return sortedItems;
      }, {} as { [key: string]: EncounterSummaryListItem[] });
      let states: string[] = [];

      if (currentUser) {
        const provider = await api.Providers.get(providerID);
        // get the states supervised by the supervisor for this provider
        setprovider(provider);

        // medical directory can audit all states they have in common with the provider being audited,
        // while supervising physicians should only see the states they directly audit for the provider
        states = isMedicalDirector
          ? Object.keys(provider.medicalDirectors || {}).filter((state) =>
              currentGeographic.includes(state),
            )
          : Object.keys(provider.supervisingPhysicians || {}).filter(
              (state) => provider.supervisingPhysicians[state] === currentUser.providerID,
            );
        let validStates = states.sort().map((i) => ({ value: i, label: STATES[i] }));
        validStates.unshift({ value: "allStates", label: "All States" });
        setStates(validStates);
        setSelectedState(validStates[0].value);
      }

      // include empty items for supervised states that don't contain audit items
      for (const state of states) {
        if (!sortedItems[state]) {
          sortedItems[state] = [];
        }
      }

      setAuditItems(sortedItems);
      setIsLoading(false);
    });
  }

  useEffect(() => {
    const stats = async () => {
      try{
        await  onLoadAuditStatistics(api.Auditing.auditStatistics(providerID).then((result: any) => {
          setName(result.fullName);
            setFilters(result.items);
            if (result.currentMonth) {
              setSelectedMonth(result.currentMonth);
            }
         }))
      }catch(e){
        console.log(e);
      }
    }
    stats()
    
  }, []);

  useEffect(() => {
    if (currentUser && selectedMonth) {
      setIsLoading(true);
      fethchAuditData();
    } else {
      setIsLoading(false);
    }
  }, [currentUser, providerID, selectedMonth, notYetAudited]);

  const handlePDFClick = async (
    key: string | null,
    note: string | null,
    url?: string,
    audited?: boolean,
    state?: string,
  ) => {
    setAudit({
      url,
      key,
      audited,
      state,
    });
    setStage("confirm");
    setNote(note);
  };

  const handleAuditClick = async (auditorDisagree: boolean) => {
    try {
      await api.Auditing.audit(audit.key, auditorDisagree, note);
      if (
        isProviderSupervisingPhysician(provider?.supervisingPhysicians, currentUser?.providerID)
      ) {
        if (!selectedMonth.stateAudited) {
          selectedMonth.stateAudited = {};
        }

        if (!selectedMonth.stateAudited[audit.state]) {
          selectedMonth.stateAudited[audit.state] = 1;
        } else {
          selectedMonth.stateAudited[audit.state] += 1;
        }

        setSelectedMonth(selectedMonth);
      }
      fethchAuditData();
    } finally {
      handlePDFClick(null, null);
    }
  };

  const handleStateSelect = (state: string) => {
    setSelectedState(state);
  };

  let auditTableItems =
    selectedState === "allStates" ? auditItems : { [selectedState]: auditItems[selectedState] };

  return (
    <div>
      <NavBar />
      <div className="dashboard-container">
        <h4>
          {" "}
          <div className="header-icon float-left">
            <DashboardIcon />
          </div>
          Providers
        </h4>
        <div className="dashboard-component align-content-stretch audit">
          <div className={styles.auditHeader}>
            <div className="d-flex w-100">
              <Link to="/providers">
                <Octicon
                  icon={ArrowLeft}
                  size="medium"
                  className={`{styles.backButton} qa-audit-back-btn`}
                />
              </Link>

              <div className={styles.providerName}>{name}</div>

              <div className={styles.filters}>
                <Navigator
                  filters={filters}
                  setSelectedMonth={setSelectedMonth}
                  selectedMonth={selectedMonth}
                />

                <Select
                  className={styles.select}
                  options={states}
                  isSearchable={false}
                  value={states.find((item) => item.value === selectedState)}
                  onChange={(item: any) => handleStateSelect(item.value as string)}
                  id="qa-states-drp"
                />
                <FormGroup className={styles.checkboxWrapper} check>
                  <Label check>
                    <Input
                      type="checkbox"
                      className={styles.checkbox}
                      value={notYetAudited as any}
                      onChange={(e) => setNotYetAudited(e.target.checked)}
                      id="qa-audit-filter-chechbox"
                    />{" "}
                    Not yet audited
                  </Label>
                </FormGroup>
              </div>
            </div>
          </div>

          {isLoading ? (
            <div className="component-load-spinner">
              <Spinner />
            </div>
          ) : (
            <>
              {Object.keys(auditTableItems || {}).map((i) => {
                const percent = setLoader(i);
                const supervisingPhysiciansID = provider.supervisingPhysicians
                  ? provider.supervisingPhysicians[i]
                  : "";
                // allow audit flag
                const match = supervisingPhysiciansID === currentUser?.providerID;

                return (
                  <div className={styles.auditBody}>
                    <AuditInfo
                      threshold={20}
                      percentage={percent}
                      size="80px"
                      className={styles.auditGraphic}
                      state={i}
                      supervisingPhysicianID={supervisingPhysiciansID}
                      STATES={STATES}
                      itemCount={(auditTableItems[i] || []).length}
                    >
                      <>
                        <div className={styles.auditPercentage}>{percent}%</div>
                        <div className={styles.auditedThisMonth}>Audited this month</div>
                      </>
                    </AuditInfo>

                    <div className={styles.auditTable}>
                      {!!auditTableItems[i] && auditTableItems[i].length > 0 ? (
                        <AuditTable
                          handlePDFClick={handlePDFClick}
                          handleAuditClick={handleAuditClick}
                          setStage={setStage}
                          selectedMonth={selectedMonth}
                          audit={audit}
                          stage={stage}
                          items={auditTableItems[i]}
                          note={note}
                          setNote={setNote}
                          setAudit={setAudit}
                          state={i}
                          match={match}
                        />
                      ) : (
                        <div>No Visits</div>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps, mapDispatchToProps
)(Audit);
