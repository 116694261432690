import React, { useState, useRef } from "react";
import Select, { Creatable } from "react-select";
import Octicon, { ChevronUp, ChevronDown } from "@githubprimer/octicons-react";
import debounce from "lodash.debounce";
import { IDefaultMedication } from "../../constants/Types";
import { Input } from "reactstrap";

const customStyles = {
  valueContainer: (provided) => ({
    ...provided,
    maxHeight: "40px",
    overflow: "hidden",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "8px 2px",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: "8px 2px",
    innerHeight: "9px",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    padding: "1px 0px",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    padding: "1px 0px",
  }),
};

const setSelectInputValue = (value: string, variants: { label: string; value: string | boolean }[]) => {
  return variants.find((el) => el.value === value) || null;
};

const formatOption = (item: string) => ({ value: item, label: item })

const activeChoices = [
  { value: true, label: "Active" },
  { value: false, label: "Disabled" },
];

const debounceCallback = debounce((func, param) => {
  func(param);
}, 500);

const Search = ({ cancelSearch, search, setLoading, disabled, formItems }) => {
  const [searchValues, setSearchValues] = useState<IDefaultMedication | {}>({});
  const [showClearSearch, setShowClearSearch] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const bodyRef = useRef(document.querySelector("body"));

  const setSearchParam = (value, key) => {
    const updatedParams = { ...searchValues };

    // for when the field is cleared with backspace
    if (value || key === "Active") {
      updatedParams[key] = value;
    } else {
      delete updatedParams[key];
    }
    // handles showing clear all search button
    if ((value === "" && Object.values(updatedParams).every((val) => val === "")) || !Object.keys(updatedParams).length) {
      cancelSearch();
      setShowClearSearch(false);
    } else {
      setLoading(true);
      debounceCallback(search, updatedParams);
      setShowClearSearch(true);
      // handleSearch()
    }
    setSearchValues(updatedParams);
  };

  // handle change for select
  const selectHandler = (item: { value: boolean | string, label: string }, name: string) => {
    let value = item?.value;

    setSearchParam(value, name);
  };

  const textInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchParam(event?.target.value, event?.target.name);
  };

  const numberInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const result = event.target.value.replace(/\D/g, '');
    setSearchParam(result, event?.target.name);
  };

  const handleSearch = () => {
    search(searchValues);
  };

  const cancelHandler = () => {
    setLoading(true);
    setSearchValues({});
    setShowClearSearch(false);
    // some cancel feature to reset formulary
    cancelSearch();
  };



  const productNameOptions = formItems ? [...new Map(formItems.map((m) => [m?.ProductName, { label: m?.ProductName, value: m?.ProductName }])).values()] : [];
  const conditionsOptions = formItems ? [...new Map(formItems.map((m) => [m?.Condition?.toString(), { label: m?.Condition?.toString(), value: m?.Condition?.toString() }])).values()] : [];
  const productNameWithRouteOptions = formItems ? [...new Map(formItems.map((m) => [m?.ProductNameWithRoute, { label: m?.ProductNameWithRoute, value: m?.ProductNameWithRoute }])).values()] : [];
  const strengthOptions = formItems ? [...new Map(formItems.map((m) => [m?.Strength, { label: m?.Strength, value: m?.Strength }])).values()] : [];
  const rxcuiOptions = formItems ? [...new Map(formItems.map((m) => [m?.RXCUI, { label: m?.RXCUI, value: m?.RXCUI }])).values()] : [];
  const ndcOptions = formItems ? [...new Map(formItems.map((m) => [m?.NDC, { label: m?.NDC, value: m?.NDC }])).values()] : [];
  const doseFormOptions = formItems ? [...new Map(formItems.map((m) => [m?.Doseform, { label: m?.Doseform, value: m?.Doseform }])).values()] : [];
  const routeOptions = formItems ? [...new Map(formItems.map((m) => [m?.Route, { label: m?.Route, value: m?.Route }])).values()] : [];
  const unitOptions = formItems ? [...new Map(formItems.map((m) => [m?.Unit, { label: m?.Unit, value: m?.Unit }])).values()] : [];
  const usageInstructionsOptions = formItems ? [...new Map(formItems.map((m) => [m?.UsageInstructions, { label: m?.UsageInstructions, value: m?.UsageInstructions }])).values()] : [];
  const protocolOptions = formItems ? [...new Map(formItems.map((m) => [m?.Protocol, { label: m?.Protocol, value: m?.Protocol }])).values()] : [];
  const referenceNumberOptions = formItems ? [...new Map(formItems.map((m) => [m?.ReferenceNumber, { label: m?.ReferenceNumber, value: m?.ReferenceNumber }])).values()] : [];
  const partnerProductNameOptions = formItems ? [...new Map(formItems.map((m) => [m?.PartnerProductName, { label: m?.PartnerProductName, value: m?.PartnerProductName }])).values()] : [];
  const partnerServiceIDOptions = formItems ? [...new Map(formItems.map((m) => [m?.PartnerServiceID, { label: m?.PartnerServiceID, value: m?.PartnerServiceID }])).values()] : [];
  const partnerDosageOptions = formItems ? [...new Map(formItems.map((m) => [m?.PartnerDosage, { label: m?.PartnerDosage, value: m?.PartnerDosage }])).values()] : [];
  const partnerMedicationRouteOptions = formItems ? [...new Map(formItems.map((m) => [m?.PartnerMedicationRoute, { label: m?.PartnerMedicationRoute, value: m?.PartnerMedicationRoute }])).values()] : [];
  const partnerDefaultStatusOptions = formItems ? [...new Map(formItems.map((m) => [m?.PartnerDefaultStatus, { label: m?.PartnerDefaultStatus, value: m?.PartnerDefaultStatus }])).values()] : [];
  const partnerHiddenStatusOptions = formItems ? [...new Map(formItems.map((m) => [m?.PartnerHiddenStatus, { label: m?.PartnerHiddenStatus, value: m?.PartnerHiddenStatus }])).values()] : [];
  const partnerChangeNotesOptions = formItems ? [...new Map(formItems.map((m) => [m?.PartnerChangeNoteOptions, { label: m?.PartnerChangeNoteOptions, value: m?.PartnerChangeNoteOptions }])).values()] : [];
  const partnerField9Options = formItems ? [...new Map(formItems.map((m) => [m?.PartnerField9, { label: m?.PartnerField9, value: m?.PartnerField9 }])).values()] : [];
  const partnerField10Options = formItems ? [...new Map(formItems.map((m) => [m?.PartnerField10, { label: m?.PartnerField10, value: m?.PartnerField10 }])).values()] : [];


  return (
    <>
      <div className="dashboard-component formulary-search-menu align-content-stretch">
        <form>
          <div className="d-flex">
            <div className="formulary-search-menu-block mt-2 mb-3 w-100">
              <div className="formulary-search-menu-item">
                <div className="formulary-search-header">
                  <h1>Formulary Search</h1>
                  <button
                    color="link"
                    type="button"
                    className="advanced-search pl-0"
                    onClick={() => setShowAdvanced(!showAdvanced)}
                    disabled={disabled}
                  >
                    Advanced Search
                    <span>
                      {showAdvanced ? (
                        <Octicon size="small" icon={ChevronDown} />
                      ) : (
                        <Octicon size="small" icon={ChevronUp} />
                      )}
                    </span>
                  </button>
                </div>
              </div>

              <div className="formulary-search-menu-item">
                <label>Condition</label>
                <Creatable
                  options={conditionsOptions}
                  isSearchable={true}
                  isClearable
                  placeholder="Condition"
                  name="Condition"
                  onChange={(e) => selectHandler(e, "Condition")}
                  isMulti={false}
                  styles={customStyles}
                  value={searchValues?.Condition != undefined ? formatOption(searchValues?.Condition) : null}
                  className="select-input-item"
                />
              </div>

              <div className="formulary-search-menu-item">
                <label>Product Name</label>
                <Creatable
                  options={productNameOptions}
                  isSearchable={true}
                  isClearable
                  placeholder="Product Name"
                  name="ProductName"
                  onChange={(e) => selectHandler(e, "ProductName")}
                  isMulti={false}
                  styles={customStyles}
                  value={searchValues?.ProductName != undefined ? formatOption(searchValues?.ProductName) : null}
                  className="select-input-item"
                />
              </div>
              <div className="formulary-search-menu-item">
                <label>Product Name With Route</label>
                <Creatable
                  options={productNameWithRouteOptions}
                  isSearchable={true}
                  isClearable
                  placeholder="Product Name With Route"
                  name="ProductNameWithRoute"
                  onChange={(e) => selectHandler(e, "ProductNameWithRoute")}
                  isMulti={false}
                  styles={customStyles}
                  value={searchValues?.ProductNameWithRoute != undefined ? formatOption(searchValues?.ProductNameWithRoute) : null}
                  className="select-input-item"
                />
              </div>

              <div className="formulary-search-menu-item">
                <label>Strength</label>
                <Creatable
                  options={strengthOptions}
                  isSearchable={true}
                  isClearable
                  placeholder="Strength"
                  name="Strength"
                  onChange={(e) => selectHandler(e, "Strength")}
                  isMulti={false}
                  styles={customStyles}
                  value={searchValues?.Strength != undefined ? formatOption(searchValues?.Strength) : null}
                  className="select-input-item"
                />
              </div>

            </div>
          </div>
          {!showAdvanced && showClearSearch && (
            <div className="clear-search d-flex justify-content-end ">
              <button
                color="link"
                type="button"
                className="clear-search-btn"
                onClick={() => cancelHandler()}
                disabled={disabled}
              >
                Clear All Search Fields
              </button>
            </div>
          )}
          {showAdvanced && (
            <>
              <div className="formulary-search-menu-block mt-2 mb-3 w-100">
                <div className="formulary-search-menu-item mr-4">
                  <label>RXCUI</label>
                  <Creatable
                    options={rxcuiOptions}
                    isSearchable={true}
                    isClearable
                    placeholder="RXCUI"
                    name="RXCUI"
                    onChange={(e) => selectHandler(e, "RXCUI")}
                    isMulti={false}
                    styles={customStyles}
                    value={searchValues?.RXCUI != undefined ? formatOption(searchValues?.RXCUI) : null}
                    className="select-input-item"
                  />
                </div>
                <div className="formulary-search-menu-item">
                  <label>NDC</label>
                  <Creatable
                    options={ndcOptions}
                    isSearchable={true}
                    isClearable
                    placeholder="NDC"
                    name="NDC"
                    onChange={(e) => selectHandler(e, "NDC")}
                    isMulti={false}
                    styles={customStyles}
                    value={searchValues?.NDC != undefined ? formatOption(searchValues?.NDC) : null}
                    className="select-input-item"
                  />
                </div>
                <div className="formulary-search-menu-item">
                  <label>Route</label>
                  <Creatable
                    options={routeOptions}
                    isSearchable={true}
                    isClearable
                    placeholder="Route"
                    name="Route"
                    onChange={(e) => selectHandler(e, "Route")}
                    isMulti={false}
                    styles={customStyles}
                    value={searchValues?.Route != undefined ? formatOption(searchValues?.Route) : null}
                    className="select-input-item"
                  />
                </div>
                <div className="formulary-search-menu-item">
                  <label>Dose Form</label>
                  <Creatable
                    options={doseFormOptions}
                    isSearchable={true}
                    isClearable
                    placeholder="Dose Form"
                    name="Doseform"
                    onChange={(e) => selectHandler(e, "Doseform")}
                    isMulti={false}
                    styles={customStyles}
                    value={searchValues?.Doseform != undefined ? formatOption(searchValues?.Doseform) : null}
                    className="select-input-item"
                  />
                </div>
                <div className="formulary-search-menu-item">
                  <label>Quantity</label>
                  <input
                    placeholder="Quantity"
                    onChange={numberInputHandler}
                    value={searchValues?.Quantity || ""}
                    className="input-text-item"
                    name="Quantity"
                    type="text"
                    disabled={disabled}
                  />
                </div>

              </div>
              <div className="formulary-search-menu-block mt-2 mb-3 w-100">
                <div className="formulary-search-menu-item mr-4">
                  <label>Unit</label>
                  <Creatable
                    options={unitOptions}
                    isSearchable={true}
                    isClearable
                    placeholder="Unit"
                    name="Unit"
                    onChange={(e) => selectHandler(e, "Unit")}
                    isMulti={false}
                    styles={customStyles}
                    value={searchValues?.Unit != undefined ? formatOption(searchValues?.Unit) : null}
                    className="select-input-item"
                  />
                </div>
                <div className="formulary-search-menu-item">
                  <label>Refills</label>
                  <input
                    placeholder="Refills"
                    onChange={numberInputHandler}
                    value={searchValues?.Refills || ""}
                    className="input-text-item"
                    name="Refills"
                    type="text"
                    disabled={disabled}
                  />
                </div>
                <div className="formulary-search-menu-item">
                  <label>Days Supply</label>
                  <input
                    placeholder="Days Supply"
                    onChange={numberInputHandler}
                    value={searchValues?.DaysSupply || ""}
                    className="input-text-item"
                    name="DaysSupply"
                    type="text"
                    disabled={disabled}
                  />
                </div>
                <div className="formulary-search-menu-item ">
                  <label>Usage Instructions</label>
                  <Creatable
                    options={usageInstructionsOptions}
                    isSearchable={true}
                    isClearable
                    placeholder="Usage Instructions"
                    name="UsageInstructions"
                    onChange={(e) => selectHandler(e, "UsageInstructions")}
                    isMulti={false}
                    styles={customStyles}
                    value={searchValues?.UsageInstructions != undefined ? formatOption(searchValues?.UsageInstructions) : null}
                    className="select-input-item"
                  />
                </div>
                <div className="formulary-search-menu-item">
                  <label>Protocol</label>
                  <Creatable
                    options={protocolOptions}
                    isSearchable={true}
                    isClearable
                    placeholder="Protocol"
                    name="Protocol"
                    onChange={(e) => selectHandler(e, "Protocol")}
                    isMulti={false}
                    styles={customStyles}
                    value={searchValues?.Protocol != undefined ? formatOption(searchValues?.Protocol) : null}
                    className="select-input-item"
                  />
                </div>
              </div>
              <div className="formulary-search-menu-block mt-2 mb-3 w-100">
                <div className="formulary-search-menu-item mr-4">
                  <label>Reference Number</label>
                  <Creatable
                    options={referenceNumberOptions}
                    isSearchable={true}
                    isClearable
                    placeholder="Reference Number"
                    name="ReferenceNumber"
                    onChange={(e) => selectHandler(e, "ReferenceNumber")}
                    isMulti={false}
                    styles={customStyles}
                    value={searchValues?.ReferenceNumber != undefined ? formatOption(searchValues?.ReferenceNumber) : null}
                    className="select-input-item"
                  />
                </div>
                <div className="formulary-search-menu-item">
                  <label>Active</label>
                  <Select
                    name="Active"
                    isSearchable={true}
                    placeholder="Active"
                    menuPortalTarget={bodyRef.current}
                    options={activeChoices}
                    isMulti={false}
                    styles={customStyles}
                    className="select-input-item"
                    onChange={(e) => selectHandler(e, "Active")}
                    value={setSelectInputValue(
                      searchValues?.Active != undefined ? searchValues?.Active : null,
                      activeChoices,
                    )}
                    isDisabled={disabled}
                  />
                </div>
                <div className="formulary-search-menu-item">
                  <label>Partner Price</label>
                  <input
                    placeholder="Partner Price"
                    onChange={textInputHandler}
                    value={searchValues?.PartnerPrice || ""}
                    className="input-text-item"
                    name="PartnerPrice"
                    type="text"
                    disabled={disabled}
                  />
                </div>
                <div className="formulary-search-menu-item">
                  <label>Partner Product Name</label>
                  <Creatable
                    options={partnerProductNameOptions}
                    isSearchable={true}
                    isClearable
                    placeholder="Partner Product Name"
                    name="PartnerProductName"
                    onChange={(e) => selectHandler(e, "PartnerProductName")}
                    isMulti={false}
                    styles={customStyles}
                    value={searchValues?.PartnerProductName != undefined ? formatOption(searchValues?.PartnerProductName) : null}
                    className="select-input-item"
                  />
                </div>
                <div className="formulary-search-menu-item">
                  <label>Partner Service ID</label>
                  <Creatable
                    options={partnerServiceIDOptions}
                    isSearchable={true}
                    isClearable
                    placeholder="Partner Service ID"
                    name="PartnerServiceID"
                    onChange={(e) => selectHandler(e, "PartnerServiceID")}
                    isMulti={false}
                    styles={customStyles}
                    value={searchValues?.PartnerServiceID != undefined ? formatOption(searchValues?.PartnerServiceID) : null}
                    className="select-input-item"
                  />
                </div>
              </div>
              <div>
                <div className="formulary-search-menu-block mt-2 mb-3 w-100">
                  <div className="formulary-search-menu-item mr-4">
                    <label>Partner Dosage</label>
                    <Creatable
                      options={partnerDosageOptions}
                      isSearchable={true}
                      isClearable
                      placeholder="Partner Dosage"
                      name="PartnerDosage"
                      onChange={(e) => selectHandler(e, "PartnerDosage")}
                      isMulti={false}
                      styles={customStyles}
                      value={searchValues?.PartnerDosage != undefined ? formatOption(searchValues?.PartnerDosage) : null}
                      className="select-input-item"
                    />
                  </div>
                  <div className="formulary-search-menu-item">
                    <label>Partner Medication Route + Type</label>
                    <Creatable
                      options={partnerMedicationRouteOptions}
                      isSearchable={true}
                      isClearable
                      placeholder="Partner Medication Route+Type"
                      name="PartnerMedicationRouteType"
                      onChange={(e) => selectHandler(e, "PartnerMedicationRouteType")}
                      isMulti={false}
                      styles={customStyles}
                      value={searchValues?.PartnerMedicationRouteType != undefined ? formatOption(searchValues?.PartnerMedicationRouteType) : null}
                      className="select-input-item"
                    />
                  </div>
                  <div className="formulary-search-menu-item">
                    <label>Partner Default Status</label>
                    <Creatable
                      options={partnerDefaultStatusOptions}
                      isSearchable={true}
                      isClearable
                      placeholder="Partner Default Status"
                      name="PartnerDefaultStatus"
                      onChange={(e) => selectHandler(e, "PartnerDefaultStatus")}
                      isMulti={false}
                      styles={customStyles}
                      value={searchValues?.PartnerDefaultStatus != undefined ? formatOption(searchValues?.PartnerDefaultStatus) : null}
                      className="select-input-item"
                    />
                  </div>
                  <div className="formulary-search-menu-item">
                    <label>Partner Hidden Status</label>
                    <Creatable
                      options={partnerHiddenStatusOptions}
                      isSearchable={true}
                      isClearable
                      placeholder="Partner Hidden Status"
                      name="PartnerHiddenStatus"
                      onChange={(e) => selectHandler(e, "PartnerHiddenStatus")}
                      isMulti={false}
                      styles={customStyles}
                      value={searchValues?.PartnerHiddenStatus != undefined ? formatOption(searchValues?.PartnerHiddenStatus) : null}
                      className="select-input-item"
                    />
                  </div>
                  <div className="formulary-search-menu-item">
                    <label>Partner Change Notes</label>
                    <Creatable
                      options={partnerChangeNotesOptions}
                      isSearchable={true}
                      isClearable
                      placeholder="Partner Change Notes"
                      name="PartnerChangeNotes"
                      onChange={(e) => selectHandler(e, "PartnerChangeNotes")}
                      isMulti={false}
                      styles={customStyles}
                      value={searchValues?.PartnerChangeNotes != undefined ? formatOption(searchValues?.PartnerChangeNotes) : null}
                      className="select-input-item"
                    />
                  </div>
                </div>
                <div className="formulary-search-menu-block mt-2 mb-3 w-100">
                  <div className="formulary-search-menu-item mr-4">
                    <label>Partner Field 9</label>
                    <Creatable
                      options={partnerField9Options}
                      isSearchable={true}
                      isClearable
                      placeholder="Partner Field 9"
                      name="PartnerField9"
                      onChange={(e) => selectHandler(e, "PartnerField9")}
                      isMulti={false}
                      styles={customStyles}
                      value={searchValues?.PartnerField9 != undefined ? formatOption(searchValues?.PartnerField9) : null}
                      className="select-input-item"
                    />
                  </div>
                  <div className="formulary-search-menu-item">
                    <label>Partner Field 10</label>
                    <Creatable
                      options={partnerField10Options}
                      isSearchable={true}
                      isClearable
                      placeholder="Partner Field 10"
                      name="PartnerField10"
                      onChange={(e) => selectHandler(e, "PartnerField10")}
                      isMulti={false}
                      styles={customStyles}
                      value={searchValues?.PartnerField10 != undefined ? formatOption(searchValues?.PartnerField10) : null}
                      className="select-input-item"
                    />
                  </div>
                  <div className="formulary-search-menu-item" style={{ width: "200px" }}>{ }</div>
                  <div className="formulary-search-menu-item" style={{ width: "200px" }}>{ }</div>
                  <div
                    className={
                      showClearSearch
                        ? "formulary-search-menu-item clear-search-advanced"
                        : "formulary-search-menu-item"
                    }
                    style={{ minWidth: "197px" }}
                  >
                    {showClearSearch ? (
                      <button
                        color="link"
                        type="button"
                        className="clear-search-btn"
                        onClick={() => cancelHandler()}
                        disabled={disabled}
                      >
                        Clear All Search Fields
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

              </div>
            </>
          )}
        </form>
      </div>
    </>
  );
};
export default Search;
