import React, { Component } from "react";
import moment from "moment";

import { Button, Form, FormGroup, Input, Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import styles from "./PatientSideBar.scss";
import { formatPhoneNumber } from "../../utils";
import api from "../../api";
import { IMedicalProfile, IPatient, IPatientProfile } from "../../constants/Types";

interface Props {
  profile: IPatientProfile;
  userID: string;
  onSave: Function;
  updateProfile: Function;
  onCancel: Function;
}
interface State {
  patient: IPatient;
  emergencyContact: { name: string; contactNumber: string };
  primaryCareProvider: { name: string; fax: string };
  medicalProfile: IMedicalProfile;
  language: string;
  inProgress: boolean;
  updateError: boolean;
  errorMessage: string[] | null;
}
class PatientSideBarProfileEdit extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      patient: props.profile.patient,
      emergencyContact: props.profile.medicalProfile.emergencyContact,
      primaryCareProvider: props.profile.medicalProfile.primaryCareProvider,
      medicalProfile: props.profile.medicalProfile,
      language: props.profile?.medicalProfile?.languages && props.profile?.medicalProfile?.languages?.length > 0 ? props.profile?.medicalProfile?.languages[0] : "",
      inProgress: false,
      errorMessage: null,
      updateError: false,
    };
  }

  handleChange = (where, event) => {
    this.setState({
      [where]: {
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'where' implicitly has an 'any' type.
        ...this.state[where],
        [event.target.name]: event.target.value,
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      },
    });
  };

  formatDate(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  handleSave = async () => {
    this.setState({ updateError: false });
    const formatLanguage = (lan) => {
      if (lan.toLowerCase() === "english") {
        return "en";
      } else if (lan.toLowerCase() === "spanish") {
        return "sp";
      }
      return lan;
    };

    const formatDOB = (date) => {
      date = moment(date).format("YYYY-MM-DD");
      return date;
    };

    const data = {
      dob: formatDOB(this.state.patient.dob),
      sex: this.state.patient.sex,
      firstName: this.state.patient.firstName,
      lastName: this.state.patient.lastName,
      languages: [formatLanguage(this.state.language)],
      phone: this.state.patient.phone,
      email: this.state.patient.email,
      userAddress: {
        street: this.state.patient.street,
        street2: this.state.patient.street2,
        city: this.state.patient.city,
        state: this.state.patient.state,
        postalCode: this.state.patient.postalCode,
      },
      emergencyContact: {
        name: this.state.emergencyContact.name ? this.state.emergencyContact.name : "",
        contactNumber: this.state.emergencyContact.contactNumber
          ? this.state.emergencyContact.contactNumber
          : "",
      },
      PCPhysician: {
        fax: this.state.primaryCareProvider.fax ? this.state.primaryCareProvider.fax : "",
        name: this.state.primaryCareProvider.name ? this.state.primaryCareProvider.name : "",
      },
    };

    try {
      const res = await api.UserRecords.update(this.props.userID, data);
      if (!res.errors) {
        await this.props.updateProfile();
        this.props.onSave();
      } else {
        this.setState({ updateError: true });
      }
    } catch (e) {
      this.setState({
        updateError: true,
        errorMessage: e.response.body.errors,
      });
    }
  };

  render() {
    const { patient, emergencyContact, primaryCareProvider, medicalProfile, language, inProgress } =
      this.state;
    const isMale = patient.sex === "M";
    const isFemale = patient.sex === "F";
    const isEnglish = language === "en";
    const isSpanish = language === "sp";
    
    return (
      <>
        <div className="d-flex justify-content-start mt-5 mb-4">
          <div onClick={() => this.props.onCancel()}>Cancel</div>
          <Button className={styles.editButtonSave} onClick={this.handleSave}>
            Save
          </Button>
        </div>

        {this.state.updateError &&
          this.state.errorMessage &&
          this.state.errorMessage.map((item, index) => (
            <span key={`errorMessage-${index}`} className={`${styles.errorMessage}`}>
              <br />*{item}
            </span>
          ))}

        <div className={styles.patientInfo}>
          <Form>
            <div className={styles.profileBlock}>
              <h2>Name</h2>
              <FormGroup>
                <Row form>
                  <Col>
                    <Input
                      className={styles.input + " " + styles.inputBig}
                      type="text"
                      name="firstName"
                      value={patient.firstName}
                      placeholder="First Name"
                      disabled={inProgress}
                      onChange={this.handleChange.bind(this, "patient")}
                      id="firstName"
                    />
                  </Col>
                  <Col>
                    <Input
                      className={styles.input + " " + styles.inputBig}
                      type="text"
                      name="lastName"
                      value={patient.lastName}
                      placeholder="Last Name"
                      disabled={inProgress}
                      onChange={this.handleChange.bind(this, "patient")}
                      id="lastName"
                    />
                  </Col>
                </Row>
              </FormGroup>
            </div>
            <div className={styles.profileBlock}>
              <h2>Date of Birth</h2>
              <Input
                className={styles.input}
                type="date"
                name="dob"
                value={this.formatDate(patient.dob)}
                placeholder="Last Name"
                disabled={inProgress}
                onChange={this.handleChange.bind(this, "patient")}
                id="dob"
              />
            </div>
            <div className={styles.profileBlock}>
              <h2>Sex</h2>
              <Button
                className={`${styles.toggleButton} ${isMale ? styles.active : ""}`}
                onClick={() =>
                  this.handleChange("patient", { target: { name: "sex", value: "M" } })
                }
              >
                Male
              </Button>
              <Button
                className={`${styles.toggleButton} ${isFemale ? styles.active : ""}`}
                onClick={() =>
                  this.handleChange("patient", { target: { name: "sex", value: "F" } })
                }
              >
                Female
              </Button>
            </div>
            <div className={styles.profileBlock}>
              <h2>Preferred Language</h2>
              <Button
                className={`${styles.toggleButton} ${isEnglish ? styles.active : ""}`}
                onClick={() => this.setState({ language: "en" })}
              >
                English
              </Button>
              <Button
                className={`${styles.toggleButton} ${isSpanish ? styles.active : ""}`}
                onClick={() => this.setState({ language: "sp" })}
              >
                Spanish
              </Button>
            </div>
            <div className={styles.profileBlock}>
              <h2>Contact Information</h2>
              <FormGroup>
                <Row form>
                  <Col>
                    <Input
                      className={styles.input}
                      type="phone"
                      name="phone"
                      value={patient.phone}
                      placeholder="Phone"
                      disabled={inProgress}
                      onChange={this.handleChange.bind(this, "patient")}
                      id="phone"
                    />
                  </Col>
                  <Col>
                    <Input
                      className={styles.input}
                      type="email"
                      name="email"
                      value={patient.email}
                      placeholder="Email"
                      disabled={inProgress}
                      onChange={this.handleChange.bind(this, "patient")}
                      id="email"
                    />
                  </Col>
                </Row>
              </FormGroup>
            </div>
            <div className={styles.profileBlock}>
              <h2>Home Address</h2>
              <FormGroup>
                <Row form>
                  <Col>
                    <Input
                      className={styles.input}
                      type="text"
                      name="street"
                      value={patient.street}
                      placeholder="Street"
                      disabled={inProgress}
                      onChange={this.handleChange.bind(this, "patient")}
                      id="street"
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col>
                    <Input
                      className={styles.input}
                      type="text"
                      name="street2"
                      value={patient.street2}
                      placeholder="Street2"
                      disabled={inProgress}
                      onChange={this.handleChange.bind(this, "patient")}
                      id="street2"
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col>
                    <Input
                      className={styles.input}
                      type="text"
                      name="city"
                      value={patient.city}
                      placeholder="City"
                      disabled={inProgress}
                      onChange={this.handleChange.bind(this, "patient")}
                      id="city"
                    />
                  </Col>
                </Row>

                <Row form>
                  <Col>
                    <Input
                      className={styles.input}
                      type="text"
                      name="state"
                      value={patient.state}
                      placeholder="State"
                      disabled={inProgress}
                      onChange={this.handleChange.bind(this, "patient")}
                      id="state"
                    />
                  </Col>
                  <Col>
                    <Input
                      className={styles.input}
                      type="text"
                      name="postalCode"
                      value={patient.postalCode}
                      placeholder="Postal Code"
                      disabled={inProgress}
                      onChange={this.handleChange.bind(this, "patient")}
                      id="postalCode"
                    />
                  </Col>
                </Row>
              </FormGroup>
            </div>
            <div className={styles.profileBlock}>
              <h2>Emergency Contact</h2>
              <FormGroup>
                <Row form>
                  <Col>
                    <Input
                      className={styles.input}
                      type="text"
                      name="name"
                      value={emergencyContact.name}
                      placeholder="Full Name"
                      disabled={inProgress}
                      onChange={this.handleChange.bind(this, "emergencyContact")}
                      id="name"
                    />
                  </Col>
                  <Col>
                    <Input
                      className={styles.input}
                      type="phone"
                      name="contactNumber"
                      value={emergencyContact.contactNumber}
                      placeholder="Phone Number"
                      disabled={inProgress}
                      onChange={this.handleChange.bind(this, "emergencyContact")}
                      id="contactNumber"
                    />
                  </Col>
                </Row>
              </FormGroup>
            </div>
            <div className={styles.profileBlock}>
              <h2>Primary Physican</h2>
              <FormGroup>
                <Row form>
                  <Col>
                    <Input
                      className={styles.input}
                      type="text"
                      name="name"
                      value={primaryCareProvider.name}
                      placeholder="Full Name"
                      disabled={inProgress}
                      onChange={this.handleChange.bind(this, "primaryCareProvider")}
                      id="physicianName"
                    />
                  </Col>
                  <Col>
                    <Input
                      className={styles.input}
                      type="phone"
                      name="fax"
                      value={primaryCareProvider.fax}
                      placeholder="Fax"
                      disabled={inProgress}
                      onChange={this.handleChange.bind(this, "primaryCareProvider")}
                      id="fax"
                    />
                  </Col>
                </Row>
              </FormGroup>
            </div>
            <div className={styles.profileBlock}>
              <h2>Preferred Pharmacy</h2>
              {!medicalProfile.preferredPharmacy.storeName ? (
                <p>Not Provided</p>
              ) : (
                <p style={{ fontSize: "12px" }}>
                  {medicalProfile.preferredPharmacy.storeName}
                  <br />
                  {formatPhoneNumber(medicalProfile.preferredPharmacy.primaryPhone)}
                </p>
              )}
            </div>
          </Form>
        </div>
      </>
    );
  }
}

PatientSideBarProfileEdit.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  profile: PropTypes.object,
  updateProfile: PropTypes.func,
  userID: PropTypes.string,
};

export default PatientSideBarProfileEdit;
