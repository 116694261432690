import React from "react"
import Octicon, { X } from '@githubprimer/octicons-react'
import Warning from "../../images/Warning.svg"
const ConfirmExitModal = ({ visible, setVisible, onConfirm }) => {

    if (!visible) {
        return null
    }

    return (
        <div className="modal-overlay">
            <div className="confirmExit-containter mr-auto ml-auto">
                <div className='d-flex justify-content-end mr-2'><button className='delete-exit-btn' type="button" onClick={() => setVisible()}><Octicon icon={X} size={18} /></button></div>
                <div className='exit-body d-flex justify-content-center flex-column pb-2'>
                    <div className='ml-auto mr-auto mt-4'> <Warning /> </div>
                    <div className='ml-auto mr-auto exit-text mt-2'>Exit Without Saving?</div>
                    <div className='ml-auto mr-auto exit-subtext mt-3'>Changes will be lost.</div>
                </div>
                <div className="del-modal-btns d-flex flex-row mt-4 justify-content-end mr-3">
                    <button className="exit-cancel-btn" type="button" onClick={() => setVisible(!visible)}>Cancel</button>
                    <button className="exit-confirm-btn" type="button" onClick={onConfirm}>Confirm Exit</button>
                </div>
            </div>
        </div>
    )
}

export default ConfirmExitModal;