import React, { useState, useEffect } from "react";
import Pencil from "../../images/PencilWhite.svg";
import WhiteX from "../../images/WhiteX.svg";
import Check from "../../images/Check.svg";
import Octicon, { ChevronDown, ChevronUp } from "@githubprimer/octicons-react";



const EncounterStickyNote = ({ refID, handleSave, getSticky, editStickyText, setEditing, message, editing }) => {
    const [open, setOpen] = useState(false)
    const [error, setError] = useState(false)


    const handleSaveSticky = () => {
        handleSave()
        setEditing()

    }

    const handleEditSticky = () => {
        setError(false)
        setEditing()
    }
    const cancelEdit = () => {
        setEditing()
        getSticky()
    }


    const handleTextInput = (e) => {
        editStickyText(e.target.value)
    }

    if (!open) {
        return (
            <div className="sticky-text ml-5 mb-3" onClick={() => setOpen(!open)}>Internal Sticky Note - (not shown to patient or final note)<span className="ml-2"><Octicon icon={ChevronDown} /></span> </div>
        )
    }

    return (
        <>
            <div className="sticky-text ml-5 mb-2" onClick={() => setOpen(!open)}>Internal Sticky Note - (not shown to patient or final note) {error && <span className="ml-2" style={{ color: "#DC4545", fontSize: "12px" }}>Error Saving Message</span>}<span className="ml-2"> <Octicon icon={ChevronUp} /></span> </div>
            <div className={!error ? "stickyNote-containerEncounter" : "stickyNote-containerEncounter error"}>
                <textarea
                    className="stickyNote-inputEncounter p-2"
                    placeholder="Internal Sticky Note (Not visible to Patient nor part of Provider note)"
                    disabled={!editing}
                    value={message}
                    onChange={handleTextInput}
                />
                {editing ?
                    <div className="d-flex flex-column editingBtn-groupEn">
                        <button type="button" className="sticky-btnEn stickyNote-ExitEditEn mb-1" onClick={() => cancelEdit()}><WhiteX className="xSVGEn" /></button>
                        <button type="button" className="sticky-btnEn stickyNote-SaveEditEn" onClick={handleSaveSticky}><Check className="checkSVGEn" /></button>
                    </div>
                    :
                    <button className="sticky-btnEn stickyNote-editBtnEn" onClick={() => handleEditSticky()} ><Pencil /></button>}
            </div>
        </>

    )
}

export default EncounterStickyNote;