import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Loadable from "react-loadable";

import api from "../../api";
import NavBar from "./NavBar";
import PatientList from "../WaitingRoom/PatientList";
import ModalMessage from "../ModalMessage";
import ClockIcon from "../../images/ClockIcon.svg";
import {
  ENCOUNTER_ENGAGE,
  WAITING_ROOM_MESSAGE_CLEAR,
  WAITING_ROOM_LOAD_PENDING,
  WAITING_ROOM_LOAD_AVAILABLE, WAITING_ROOM_LOAD_POSTPONED,
} from "../../constants/actionTypes";

import Loading from "../ComponentLoading";
import ClockIn from "../ClockIn";
import { hasPermission, PERMISSION_SCRIBE } from "../../constants/Permissions";
import { checkIsJSON, errorLogger } from "../../utils";
import moment from "moment";
import { Button } from "reactstrap";
import Octicon, { ChevronUp, ChevronDown } from "@githubprimer/octicons-react";


const VideoSetup = Loadable({
  loader: () => import("../VideoSetup"),
  loading: Loading,
  delay: 300,
});

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.common.currentUser,
    availablePatients: state.waitingRoom.available,
    pendingPatients: state.waitingRoom.pending,
    postponedPatients: state.waitingRoom.postponed,
    presence: state.presence,
    message: state.waitingRoom.message,
  };
};

const mapDispatchToProps = (dispatch) => ({
  engageEncounter: (referenceID, payload) =>
    dispatch({
      type: ENCOUNTER_ENGAGE,
      payload: payload,
      referenceID: referenceID,
    }),
  loadPending: (payload) => dispatch({ type: WAITING_ROOM_LOAD_PENDING, payload }),
  loadAvailable: (payload) => dispatch({ type: WAITING_ROOM_LOAD_AVAILABLE, payload }),
  loadPostponed: (payload) => dispatch({ type: WAITING_ROOM_LOAD_POSTPONED, payload }),

  clearMessage: () => dispatch({ type: WAITING_ROOM_MESSAGE_CLEAR }),
});

const PresenceVeil = ({ presence }) => {
  if (presence && presence.clockedIn) {
    return null;
  }
  return (
    <div className="presence-veil">
      <div className="clock-in-message d-flex flex-column align-items-center">
        <div>You must clock in to begin accepting patients.</div>
        <div className="mt-5">
          <ClockIn />
        </div>
      </div>
    </div>
  );
};

function AvailablePatients({
  engageEncounter,
  currentUser,
  presence,
  pendingPatients,
  availablePatients,
  postponedPatients,
  message,
  clearMessage,
  loadPending,
  loadAvailable,
  loadPostponed,
}) {
  const [practiceID, setPracticeID] = useState(undefined);
  const [prevPendingPatients, setPrevPendingPatients] = useState([]);
  const [onlinePatients, setOnlinePatients] = useState([]);
  const [offlinePatients, setOfflinePatients] = useState([]);
  const [patientsWithChat, setPatientsWithChat] = useState([]);
  const [postponedP, setPostponedPatients] = useState([]);
  const [pageStart, setPageStart] = useState(1);
  const [postponedOpen, setPostponedOpen] = useState(false)
  let pendingFetchInterval, availableFetchInterval;

  useEffect(() => {
    fetchPostponedPatients()
    fetchPendingPatients();
    fetchAvailablePatients();
    return () => {
      if (pendingFetchInterval) {
        clearInterval(pendingFetchInterval);
      }
      if (availableFetchInterval) {
        clearInterval(availableFetchInterval);
      }
    };
  }, []);

  useEffect(() => {
    if (currentUser && currentUser.currentPracticeID !== practiceID) {
      setPracticeID(currentUser.currentPracticeID);
    }
  }, [currentUser]);

  useEffect(() => {
    setPrevPendingPatients(pendingPatients);
  }, [pendingPatients]);

  useEffect(() => {
    setPostponedPatients(prev => [...prev, ...postponedPatients]);
  }, [postponedPatients]);

  const splitAvailableBySection = () => {
    const filterIsOnline = availablePatients.filter((item) =>
      moment.duration(moment().diff(moment(item.patientLastSeen))).asSeconds() < 30 &&
      item.communicationWithChat !== true
    );
    const filterIsOffline = availablePatients.filter((item) =>
      moment.duration(moment().diff(moment(item.patientLastSeen))).asSeconds() > 30 &&
      item.communicationWithChat !== true
    );
    const filterIsChat = availablePatients.filter((item) =>
      item.communicationWithChat === true
    );
    setOnlinePatients(filterIsOnline);
    setOfflinePatients(filterIsOffline)
    setPatientsWithChat(filterIsChat)
  }

  useEffect(() => {
    splitAvailableBySection()
  }, [availablePatients]);

  const onAcceptPatient = async (patient) => {
    try {
      await engageEncounter(patient.referenceID, api.Encounters.engage(patient.referenceID));
    }
    catch (e) {
      errorLogger(e)
    }
  };

  const fetchPendingPatients = () => {
    if (pendingFetchInterval) {
      clearInterval(pendingFetchInterval);
    }
    pendingFetchInterval = setInterval(() => {
      loadPending(api.Encounters.pending());
    }, 3000);
  };
  const loadMorePostponed = () => {
    loadPostponed(api.Encounters.postponed({ pageStart: pageStart + 1 }));
    setPageStart((prevState => prevState + 1))
  }

  const fetchPostponedPatients = () => {
    loadPostponed(api.Encounters.postponed({}));
  };

  const fetchAvailablePatients = () => {
    if (availableFetchInterval) {
      clearInterval(availableFetchInterval);
    }
    availableFetchInterval = setInterval(() => {
      try {
        loadAvailable(api.Encounters.available());
      } catch (e: any) {
        errorLogger(e)
      }
    }, 3000);
  };
  const isScribe = hasPermission(currentUser?.permissions, PERMISSION_SCRIBE);
  const setupVideo = currentUser && !isScribe && !currentUser.videoSetup;

  return (
    <div>
      <NavBar />
      {setupVideo && <VideoSetup />}
      {!setupVideo && <PresenceVeil presence={presence} />}
      <div className="dashboard-container">
        <h4>
          {" "}
          <div className="header-icon float-left">
            <ClockIcon />
          </div>
          Patients in waiting room...{" "}
        </h4>
        <ModalMessage message={message} onClear={clearMessage} />
        <div className={`dashboard-component align-content-stretch ${onlinePatients.length <= 0 && "disabled"}`}>
          {onlinePatients.length > 0 ? <h2>Online video visits</h2> : <h2 className="text-center">No available Online visits</h2>}
          <PatientList onAcceptPatient={onAcceptPatient} items={onlinePatients} />
          <PatientList items={pendingPatients} />
        </div>
        <div className={`dashboard-component align-content-stretch ${patientsWithChat.length <= 0 && "disabled"}`}>
          {patientsWithChat.length > 0 ? <h2>Chat visits</h2> : <h2 className="text-center">No available Chat visits</h2>}
          <PatientList onAcceptPatient={onAcceptPatient} items={patientsWithChat} />
        </div>
        <div className={`dashboard-component align-content-stretch ${offlinePatients.length <= 0 && "disabled"}`}>
          {offlinePatients.length > 0 ? <h2>Offline video visits</h2> : <h2 className="text-center">No available Offline visits</h2>}
          <PatientList onAcceptPatient={onAcceptPatient} items={offlinePatients} />
        </div>
        {postponedPatients.length > 0 && (
          <div className="dashboard-component align-content-stretch d-flex flex-column">
            <div className="d-flex"><h2>Postponed </h2><button onClick={() => setPostponedOpen(!postponedOpen)} className="postponedCtrl d-flex justify-content-start pt-1">{<Octicon size={18} icon={postponedOpen ? ChevronUp : ChevronDown} />}</button></div>
            {postponedOpen &&
              <>
                <PatientList items={postponedP} />
                <Button className="ml-auto mr-auto" color="primary" onClick={loadMorePostponed}>
                  Load More...
                </Button>
              </>
            }
          </div>
        )}
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AvailablePatients);
