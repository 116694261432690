import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import moment from "moment";
import set from "lodash.set";
import PropTypes from "prop-types";

import {
  COMPONENT_LOAD,
  COMPONENT_UNLOAD,
  PROVIDER_DETAILS_LOAD,
  PROVIDER_EDIT_SAVE,
  PROVIDER_RESEND_INVITE,
  NAVIGATE_TO,
  COMPONENT_CHANGED,
  REFRESH_USER,
} from "../../constants/actionTypes";
import NavBar from "../Provider/NavBar";
import api from "../../api";
import ErrorList from "../ErrorList";
import ProviderForm from "./ProviderForm";
import PageLoadSpinner from "../PageLoadSpinner";
import ProviderUpdateLog from "./ProviderUpdateLog";
import { toast } from "react-toastify";
import { checkIsJSON, errorLogger } from "../../utils";
import ProviderPresenceTimeline from "./ProviderPresenceTimeline";
import {
  hasAnyPermission,
  hasPermission,
  PERMISSION_MANAGE,
  PERMISSION_PAGE,
  PERMISSION_SEE_PATIENTS, PERMISSION_THERAPIST,
} from "../../constants/Permissions";
import { store } from "../../store";

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.common.currentUser,
    loadErrors: (state.componentData[PROVIDER_DETAILS_LOAD] || {}).errors,
    saveErrors: state.errors[PROVIDER_EDIT_SAVE],
    resendErrors: state.errors[PROVIDER_RESEND_INVITE],
    providerID: ownProps.match.params.providerID,
    loading: state.progress[PROVIDER_DETAILS_LOAD],
    ...state.componentData[PROVIDER_DETAILS_LOAD],
  };
};

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload) => dispatch({ type: COMPONENT_LOAD, subtype: PROVIDER_DETAILS_LOAD, payload }),
  onUnload: () => {
    dispatch({ type: COMPONENT_UNLOAD, subtype: PROVIDER_DETAILS_LOAD });
    dispatch({ type: COMPONENT_UNLOAD, subtype: PROVIDER_EDIT_SAVE });
    dispatch({ type: COMPONENT_UNLOAD, subtype: PROVIDER_RESEND_INVITE });
  },
  onChange: (payload) =>
    dispatch({
      type: COMPONENT_CHANGED,
      subtype: PROVIDER_DETAILS_LOAD,
      payload,
    }),
  onSubmit: (payload) =>
    dispatch({
      type: COMPONENT_LOAD,
      subtype: PROVIDER_EDIT_SAVE,
      payload,
    }),
  refreshUser: () =>
    dispatch({
      type: REFRESH_USER,
      payload: api.Authorization.current(),
      targetPath: "/providers",
    }),
  onCancel: () => dispatch({ type: NAVIGATE_TO, targetPath: "/providers" }),
  onResend: (payload) =>
    dispatch({ type: COMPONENT_LOAD, subtype: PROVIDER_RESEND_INVITE, payload }),
});

class ProviderEdit extends Component<ProviderEditProps> {
  constructor(props) {
    super(props);
    this.state = {
      seePatientsErr: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onResend = this.onResend.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {

    const fetchData = async () => {
      try {
        let data = await api.Providers.get(this.props.providerID);
        return data;
      } catch (error) {
        this.props.history.push("/providers");
      }
    };

    this.props.onLoad(fetchData());

  }
  componentDidUpdate(prevProps: any,) {
    if (prevProps?.currentUser?.permissions && this.props?.currentUser?.permissions && !hasPermission(this.props?.currentUser?.permissions, PERMISSION_PAGE) || !hasPermission(this.props?.currentUser?.permissions, PERMISSION_MANAGE)) {
      store.dispatch({ type: NAVIGATE_TO, targetPath: "/permissions-issue" });
    }
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  async onSubmit(event) {
    let data = this.props.data;
    if (hasAnyPermission(data.permissions, PERMISSION_SEE_PATIENTS, PERMISSION_THERAPIST) &&
      data.suffix.length === 0) {
      this.setState({ seePatientsErr: true });
    } else {
      this.setState({ seePatientsErr: false });
      data.dateOfBirth = moment(this.props.data.dateOfBirth).format("YYYY-MM-DD");

      //This prevent a 422 error in the backend. We should clean up later
      data.supervisingPhysicians = null;

      try {
        const payload = await api.Providers.update({
          providerID: this.props.providerID,
          ...data,
          doseSpotID: parseInt(data.doseSpotID) || 0,
        });
        this.props.onSubmit(payload);
        this.props.refreshUser();
      } catch (error: any) {
        const status = error.response.status;
        if (status === 422) {
          errorLogger(error)
        }
      }
    }
  }

  onResend(event) {
    this.props.onResend(api.Providers.resendInvite({ email: this.props.data.email }));
  }

  onChange(field, newValue) {
    const o = set({}, field, { $set: newValue });
    this.props.onChange(o);
  }

  render() {
    const formErrors = (this.props.saveErrors || []).concat(this.props.resendErrors || []);
    const providerData = { ...this.props.data, seePatientsErr: this.state.seePatientsErr };
    return (
      <div>
        <NavBar />
        <div className="dashboard-container">
          {this.props.loadErrors && <ErrorList errors={this.props.loadErrors} />}
          <h4>Edit Provider</h4>
          <PageLoadSpinner inProgress={this.props.loading} />
          {!this.props.loading && this.props.data && this.props.data && (
            <div className="dashboard-component align-content-stretch">
              <ProviderForm
                currentUser={this.props.currentUser}
                provider={providerData}
                isEditing={true}
                onResend={this.onResend}
                onChange={this.onChange}
                errors={formErrors}
                providerID={this.props.providerID}
              />

              <div className="d-flex">
                <Button color="primary" onClick={this.onSubmit}>
                  Update Provider
                </Button>
                <Button className="ml-2" color="link" onClick={this.props.onCancel}>
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </div>
        {!this.props.loading && (
          <div className="dashboard-container">
            <ProviderUpdateLog providerID={this.props.providerID} />
          </div>
        )}
        {!this.props.loading && (
          <div className="dashboard-container">
            <ProviderPresenceTimeline providerID={this.props.providerID} />
          </div>
        )}
      </div>
    );
  }
}

export interface ProviderEditProps {
  currentUser: object;
  history: object;
  loading: Boolean;
  onCancel: () => void;
  onChange: ({ field: string, newValue: any }) => void;
  onLoad: (promise) => void;
  onResend: (promise: Promise<any>) => void;
  onSubmit: (promise: Promise<any>) => void;
  onUnload: (promise: Promise<any>) => void;
  providerID: string;
  data: object;
  saveErrors: Array<string>;
  resendErrors: Array<string>;
  loadErrors: Array<string>;
}
export default connect(mapStateToProps, mapDispatchToProps)(ProviderEdit);
