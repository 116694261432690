import React, { Component } from "react";
import { Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import PatientIcon from "../../images/ProfileIconWithSquare.svg";
import { ResultLabel } from "../../constants/TestConstants";
import { IPreferredPharmacy, ITestDetails, IUserDetails, IPatient, IPatientDuplicateItem, ICurrentUser } from "../../constants/Types";
import { gender } from "../../constants/MedicalProfile";
import PatientServices from "../PatientServices";
import { RequisitionVisitTypes } from "../../constants/RequisitionTypes";
import api from "../../api";
import { checkIsJSON, openInNewTab } from "../../utils";
import { providerResources } from "../../constants/Encounter"
import { toast } from "react-toastify";
import { PERMISSION_RECENT_ENCOUNTER, PERMISSION_RECENT_ENCOUNTER_LIMITED } from "../../constants/Permissions";


export const PATIENT_INFO_TAB = "patientInfo";
export const DIAGNOSIS_TAB = "diagnosis";
export const TREATMENT_PLAN = "treatmentPlan";
export const PHYSICAL_EXAM_TAB = "physicalExam";
export const SKIN_ALLERGY_TEST_TAB = "skinAllergy";
export const REQ_PDF = "reqPDF";

function labelForResult(testType, providerResult) {
  if (providerResult.invalid) {
    return "";
  }
  return <span>&bull; {ResultLabel(testType, providerResult.results)} </span>;
}

export interface PatientHeaderProps {
  isAsyncVisit?: boolean;
  isPCCPatient?: boolean;
  isSinglePage?: boolean;
  videoFinished?: boolean;
  emergencyContact?: {
    name?: string;
    contactNumber?: string;
  };
  onPageNavigation: (name: string) => void;
  patient: IUserDetails;
  preferredPharmacy?: IPreferredPharmacy;
  selected?: string;
  showErrorMsg?: boolean;
  showttTab?: boolean;
  showttTabtwo?: boolean;
  telemedExtender?: {
    name: string;
    phone: string;
  };
  facilityName?: string;
  testResult?: ITestDetails;
  visitType: string;
  patientData: Partial<IPatient>;
  userID?: string;
  healthGorillaIsEnable: boolean | undefined;
  currentUser?: ICurrentUser | null
}

interface PrintHeaderState {
  selected: string;
  infoVisible: boolean;
  dropdownOpen: boolean;
  activeDropdown: string;
  pMatchOpen: boolean;
  patientMatches: IPatientDuplicateItem[];
}


const SubMenu = ({ category, items, handleClick, activeDropdown }) => {

  return (
    <Dropdown direction="right" isOpen={activeDropdown === category} toggle={() => handleClick(category)} className="sub-dropdown">
      <DropdownToggle onClick={handleClick} className="sub-dropdownToggle" caret>
        {category}
      </DropdownToggle>
      <DropdownMenu>
        {items.map((resource, idx) => {
          return (
            <DropdownItem className="pResDropdownItem" key={idx} onClick={() => window.open(resource.value, "_blank")}>{resource.label}</DropdownItem>
          )
        })}
      </DropdownMenu>
    </Dropdown>
  )
}

export default class PatientHeader extends Component<PatientHeaderProps, PrintHeaderState> {
  constructor(props) {
    super(props);
    this.state = { selected: DIAGNOSIS_TAB, infoVisible: false, dropdownOpen: false, activeDropdown: "", pMatchOpen: false, patientMatches: [] };
  }

  componentDidMount() {
    this.getPatientMatches()
  }

  getPatientMatches = async () => {
    let result = await api.UserRecords.duplicatePatients(this.props.userID!);
    this.setState({ patientMatches: result?.items || [] })

  }

  onPatientInfo = () => {
    this.setState({ selected: PATIENT_INFO_TAB });
    this.props.onPageNavigation(PATIENT_INFO_TAB);
  };
  viewHealthGorillaChart = async () => {
    try {
      const healthGorillaURL = await api.HealthGorilla.viewHealthGorillaChart(this.props.userID!);

      if (this.props.patientData.healthGorillaUserID) {
        openInNewTab(healthGorillaURL.url);
      } else {
        setTimeout(() => {
          // with the initial HG chart call, we need more time to find a patient that matches
          openInNewTab(healthGorillaURL.url);
        }, 3000);
      }
    } catch (e: any) {
      const isJSON = checkIsJSON(e.response.body?.message);
      let errorMessage = isJSON
        ? JSON.parse(e.response.body?.message).responseBody
        : e.response.body?.message || e.response.text;
      if (typeof errorMessage === "object") {
        errorMessage = errorMessage?.error_description || errorMessage?.issue[0]?.diagnostics;
      }
      toast.error(`Error: ${errorMessage}`);
    }
  };
  onTreatmentPlan = () => {
    this.setState({ selected: TREATMENT_PLAN });
    this.props.onPageNavigation(TREATMENT_PLAN);
  };

  onSkinAllergyTest = () => {
    this.setState({ selected: SKIN_ALLERGY_TEST_TAB });
    this.props.onPageNavigation(SKIN_ALLERGY_TEST_TAB);
  };

  onPhysicalExam = () => {
    this.setState({ selected: PHYSICAL_EXAM_TAB });
    this.props.onPageNavigation(PHYSICAL_EXAM_TAB);
  };

  onDiagnosis = () => {
    this.setState({ selected: DIAGNOSIS_TAB });
    this.props.onPageNavigation(DIAGNOSIS_TAB);
  };

  onReqPDFTab = () => {
    this.setState({ selected: REQ_PDF });
    this.props.onPageNavigation(REQ_PDF);
  };

  toggle = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }

  togglePMatch = () => {
    this.setState({ pMatchOpen: !this.state.pMatchOpen })
  }

  handleClick = (clicked: string) => {
    if (this.state.activeDropdown !== clicked) {
      this.setState({ activeDropdown: clicked })
    } else {
      this.setState({ activeDropdown: "" })
    }
  }

  openEncounterMonitor = (id: string) => {
    window.open(`/encounter-monitor/?encounterId=${id}`)
  }

  render() {
    const isSinglePage = !!this.props.isSinglePage;
    const visitType = this.props.visitType;
    const telemedExtender = this.props.telemedExtender;
    const patient = this.props.patient || {};
    const selected = this.props.selected || this.state.selected;
    const consentDetails = this.props.patientData.consentDetails;
    const infoClass = selected === PATIENT_INFO_TAB ? "selected" : "unselected";
    const skinAllergyTestClass = selected === SKIN_ALLERGY_TEST_TAB ? "selected" : "unselected";
    const dxClass = selected === DIAGNOSIS_TAB ? "selected" : "unselected";
    const ttPlan = selected === TREATMENT_PLAN ? "selected" : "unselected";
    const examClass = selected === PHYSICAL_EXAM_TAB ? "selected" : "unselected";
    const requisitionVisit = RequisitionVisitTypes.includes(visitType);
    const currentUser = this?.props?.currentUser
    const encounterMonitorPerm = currentUser?.permissions?.includes(PERMISSION_RECENT_ENCOUNTER) || currentUser?.permissions?.includes(PERMISSION_RECENT_ENCOUNTER_LIMITED);
    const preferredPharmacy = this?.props?.preferredPharmacy
    if (!patient.name) {
      return (
        <div className="patient-header d-flex justify-content-between">
          <PatientIcon />
        </div>
      );
    }
    const sex = gender[patient.sex] || "Unknown";
    let testResult = this.props.testResult;
    let resultLabel: JSX.Element | string = "";
    if (testResult && testResult.providerResult && testResult.providerResult.recorded) {
      resultLabel = labelForResult(testResult.testType, testResult.providerResult);
    }

    return (
      <div className="patient-header-container">
        <div className="patient-header d-flex align-items-center">
          <div className="patient-icon">
            <PatientIcon />
          </div>
          <div className="patient-name-box flex-grow-1">
            <div className="d-flex flex-column">
              <div className="d-flex flex-row align-items-center" style={{ marginBottom: 4 }}>
                <div>
                  <a
                    className="patient-name mr-2"
                    style={{ color: "#000" }}
                    href={`/active-patient-chart/${patient.userID}`}
                    target="_blank"
                  >
                    {patient.name}
                  </a>
                </div>
                {consentDetails && (
                  <PatientServices
                    covidZone={this.props.patientData.covidZone}
                    rpmConsent={consentDetails.rpm}
                    ccmConsent={consentDetails.ccm}
                  />
                )}
                <div className="d-flex align-items-center">
                  <span className="view-chart-text">View Patient Chart in:</span>
                  <a
                    className="patient-chart-button-text ml-2"
                    href={`/active-patient-chart/${patient.userID}`}
                    target="_blank"
                  >
                    Reliant EMR
                  </a>
                  {this.props.healthGorillaIsEnable && (
                    <a
                      className="patient-chart-button-text ml-2"
                      onClick={this.viewHealthGorillaChart}
                    >
                      Health Gorilla
                    </a>
                  )}
                </div>
                {this.state.patientMatches.length > 0 && <div className="patientMatches">
                  <Dropdown isOpen={this.state.pMatchOpen} toggle={this.togglePMatch} className="pMatch-dropdown">
                    <DropdownToggle className="pMatchDropdown-btn" caret>
                      Patient Matches
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem className="pMatchDropdownHeader" header>Patient Name | Patient Practice | Encounter Count</DropdownItem>
                      {this.state.patientMatches.map((pItem, idx) => {
                        return (
                          <DropdownItem className="pMatchDropdownItem">
                            <a
                              href={`/active-patient-chart/${pItem.userID}`}
                              target="_blank"
                            >
                              {`${pItem.firstName} ${pItem.lastName} `}
                            </a> |
                            <span>{` ${pItem.practiceName}  `}</span> |
                            {encounterMonitorPerm ? <a
                              href={`/active-encounter-monitor/?encounterId=${pItem.encounterLink}`}
                              target="_blank"
                            >
                              {` ${pItem.encounterCount}`}
                            </a> :
                              <span>{` ${pItem.encounterCount}`}</span>
                            }
                          </DropdownItem>
                        )
                      })}
                    </DropdownMenu>
                  </Dropdown>
                </div>}
                <div className="providerResources">
                  <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} className="pRes-dropdown" >
                    <DropdownToggle className="pResDropDown-btn" caret>
                      Provider Resources
                    </DropdownToggle>
                    <DropdownMenu>
                      {providerResources.map((resource, index) => {
                        const groupedResources = ["Awesome Woman", "Reliant Resources", "Screening Questions"]
                        return (
                          <>
                            {resource.label === "Awesome Woman" &&
                              <>
                                <DropdownItem className="prResDropdownHeader" header>Awesome Woman</DropdownItem>
                                {resource?.value.map((item) => (
                                  <DropdownItem className="pResDropdownItemGrouped pl-5" key={`${index}-${item.label}`} onClick={() => window.open(item.value, "_blank")}>{item.label}</DropdownItem>
                                ))}
                              </>
                            }
                            {resource.label === "Reliant Resources" &&
                              <>
                                <DropdownItem className="prResDropdownHeader" header>Reliant Resources</DropdownItem>
                                {resource?.value?.map((item) => (
                                  <DropdownItem className="pResDropdownItemGrouped pl-5" key={`${index}-${item.label}`} onClick={() => window.open(item.value, "_blank")}>{item.label}</DropdownItem>
                                ))}
                              </>
                            }
                            {resource.label === "Screening Questions" &&
                              <>
                                <DropdownItem className="prResDropdownHeader" header>Screening Questions</DropdownItem>
                                {resource?.value?.map((item) => (
                                  <DropdownItem className="pResDropdownItemGrouped pl-5" key={`${index}-${item.label}`} onClick={() => window.open(item.value, "_blank")}>{item.label}</DropdownItem>
                                ))}
                              </>
                            }
                            {!groupedResources.includes(resource.label) && <DropdownItem className="pResDropdownItem" key={`${index}-${resource.label}`} onClick={() => window.open(resource.value, "_blank")}>{resource.label}</DropdownItem>}
                          </>

                        )
                      })}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
              <div className="moreInfoText">
                {sex} &bull; {patient.dob} ({patient.age} years old) &bull; {patient.state} &bull; {patient?.phone} &bull; { }
                {this.props.facilityName} &bull; {preferredPharmacy?.storeName}
              </div>
              <div className="patient-details"> {resultLabel} </div>

              {!this.props.isAsyncVisit &&
                !isSinglePage &&
                !this.props.isPCCPatient &&
                !!this.props.videoFinished && (
                  <div style={{ marginTop: 10 }}>
                    <div className="video-call-complete">Video Call Complete</div>
                  </div>
                )}
            </div>
          </div>
          <div>
            {isSinglePage ? (
              <></>
            ) : (
              <div className="switch-button">
                <Button id="btn-patient-info" onClick={this.onPatientInfo} className={infoClass}>
                  Patient Info
                </Button>
                <span>&gt;</span>
                {visitType === "rash_and_allergy" && telemedExtender && (
                  <>
                    <Button
                      id="btn-patient-info"
                      onClick={this.onSkinAllergyTest}
                      className={skinAllergyTestClass}
                    >
                      Skin Allergy Test
                    </Button>
                    <span>&gt;</span>
                  </>
                )}
                <Button id="btn-physical-exam" onClick={this.onPhysicalExam} className={examClass}>
                  Physical Exam
                </Button>
                <span>&gt;</span>
                {visitType !== "rxrenewal" &&
                  visitType !== "nursing_home" &&
                  visitType !== "in_home_health" &&
                  visitType !== "rx_request" &&
                  !requisitionVisit && (
                    <Button id="btn-diagnosis" onClick={this.onDiagnosis} className={dxClass}>
                      Diagnosis
                    </Button>
                  )}
                {(visitType === "nursing_home" ||
                  visitType === "rxrenewal" ||
                  visitType === "in_home_health" ||
                  visitType === "rx_request" ||
                  requisitionVisit) && (
                    <Button id="btn-diagnosis" onClick={this.onDiagnosis} className={dxClass}>
                      Dx+Treatment
                    </Button>
                  )}
                {this.props.showttTab &&
                  this.props.showttTabtwo &&
                  !requisitionVisit &&
                  visitType !== "rx_request" && (
                    <>
                      <span>&gt;</span>
                      <Button
                        id="btn-treatmentplan"
                        onClick={this.onTreatmentPlan}
                        className={ttPlan}
                      >
                        Treatment Plan
                      </Button>
                    </>
                  )}
              </div>
            )}
            {this.props.showErrorMsg && (
              <span className="save-error-msg">
                You must save any edits to patient information before continuing
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
}
