import React, { FC, useState } from "react";

import Select from "react-select";
import { Row, Col } from "reactstrap";
import { Textfit } from "react-textfit";
import { Styles } from "react-select/lib/styles";
import { ICPTCode, ICPTCodeInNote, IOptionType } from "../../constants/Types";
import CaretUp from "../../images/CaretUpBlack.svg";
import CaretDown from "../../images/CaretDownBlack.svg";


const selectStyle: Partial<Styles> = {
  option: (styles, { isDisabled }) => {
    return {
      ...styles,
      textAlign: isDisabled ? "center" : "start",
    };
  },
};

export const EmptyComponent = () => {
  return null;
};

type PillListProps = {
  item: IOptionType;
  onRemove: (opt: IOptionType) => void;
  idx: number;
  onChangeQty: (item: any) => void;
};
export const PillListItem: FC<PillListProps> = ({ idx, onRemove, item, onChangeQty }) => {
  const [qtyPills, setQtyPills] = useState<number>(item.quantityUnits || 1);

  const increment = () => {
    if (qtyPills === 10) {
      return;
    }
    try {
      onChangeQty({ ...item, quantityUnits: qtyPills + 1 });
      setQtyPills((qtyPills) => qtyPills + 1);
    } catch (e) {
      console.log(e.message);
    }
  };
  const decrement = () => {
    if (qtyPills === 1) {
      return;
    }
    try {
      onChangeQty({ ...item, quantityUnits: qtyPills - 1 });
      setQtyPills((qtyPills) => qtyPills - 1);
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div className="pill blue d-flex">
      <Textfit mode="multi" className="align-self-center" max={13} min={8}>
        <div className="pill-content">{item.label}</div>
      </Textfit>
      <span className="ml-auto mr-1">Qty.</span>
      <div className="pillQuantity mr-1 text-center">{qtyPills}</div>
      <div className="d-flex flex-column justify-content-center align-items-center mt-2 mr-2">
        <CaretUp style={{ cursor: "pointer" }} onClick={increment} />
        <CaretDown style={{ cursor: "pointer", marginTop: "5px" }} onClick={decrement} />
      </div>
      <div className="close-button" onClick={() => onRemove(item)}>
        {" "}
        &#x2715;{" "}
      </div>
    </div>
  );
};

const selectedCodesToItems = (
  allEmcodes: ICPTCode[],
  selectedEmcodes: ICPTCodeInNote[],
): IOptionType[] => {
  if (!!allEmcodes && allEmcodes.length > 0 && selectedEmcodes.length > 0) {
    return selectedEmcodes.map((el) => {
      for (let codeObj of allEmcodes) {
        if (codeObj.code == el.code) {
          return {
            label: `${codeObj.code} ${codeObj.description}`,
            value: el.code,
            quantityUnits: el.quantityUnits,
          };
        }
      }
      return { label: el.code, value: el.code, quantityUnits: el.quantityUnits };
    });
  }
  return [];
};
const filter = (
  item: IOptionType & { data: { isDisable: boolean; additional: boolean } },
  input: string,
) => {
  input = input.toLowerCase().trim();
  if ((input !== "" && item.data.isDisable) || (input === "" && item.data.additional)) {
    return false;
  }

  return item?.label?.toLowerCase().includes(input);
};

type props = {
  placeholder: string;
  onChangeCode: (val: ICPTCodeInNote[]) => void;
  emCodes: ICPTCode[];
  selectedEmcodes: ICPTCodeInNote[];
  cptCodeError: string[];
};

export const DiagnosisCPTCode: FC<props> = ({
  placeholder,
  onChangeCode,
  emCodes,
  selectedEmcodes,
  cptCodeError,
}) => {
  const emCodeSelected = selectedCodesToItems(emCodes, selectedEmcodes);

  const onRemove = (item: IOptionType) => {
    const selected = emCodeSelected.filter((elem) => elem.value !== item.value);

    const formatSelected = selected.map((val): ICPTCodeInNote => {
      return { code: val.value, quantityUnits: val.quantityUnits || 1 };
    });

    onChangeCode(formatSelected);
  };
  const onChangeQty = (selectedItem: IOptionType) => {
    const newSelected = emCodeSelected.map((el) => {
      if (selectedItem.value === el.value) {
        return {
          code: el.value,
          quantityUnits: selectedItem.quantityUnits!,
        };
      }
      return {
        code: el.value,
        quantityUnits: el.quantityUnits!,
      };
    });
    onChangeCode(newSelected);
  };

  const onChange = (selected: any) => {
    if (selected?.length > 0) {
      onChangeCode(
        selected.map((el) => {
          return {
            code: el.value,
            quantityUnits: el.quantityUnits,
          };
        }),
      );
    }
  };

  const defaultOptions = [
    {
      label: placeholder,
      value: placeholder,
      isDisabled: true,
    },
    ...emCodes.map((el) => {
      return {
        label: `${el.code} ${el.description}`,
        value: el.code,
        additional: el.additional,
        quantityUnits: 1,
      };
    }),
  ];

  return (
    <Row>
      <Col md="6">
        <Select
          backspaceRemovesValue={false}
          placeholder={placeholder}
          isMulti
          menuPlacement="top"
          value={emCodeSelected}
          isClearable={false}
          onChange={onChange}
          options={defaultOptions}
          classNamePrefix="info-select"
          filterOption={filter}
          components={{
            IndicatorSeparator: EmptyComponent,
            MultiValueContainer: EmptyComponent,
            DropdownIndicator: EmptyComponent,
          }}
          styles={selectStyle}
        />
        {cptCodeError.length > 0 &&
          cptCodeError.map((el) => (
            <p key={el} className="limitErrorMsg">
              {el}
            </p>
          ))}
      </Col>
      <Col md="6">
        <div className="pill-list">
          {emCodeSelected.length > 0 &&
            emCodeSelected.map((item, idx) => (
              <PillListItem idx={idx}  key={`pillList-${idx}`} item={item} onRemove={onRemove} onChangeQty={onChangeQty} />
            ))}
        </div>
      </Col>
    </Row>
  );
};
