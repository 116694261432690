import React, { useState } from "react"
import { useParams } from 'react-router-dom'
import { connect } from "react-redux";
import api from "../../api"
import { IFormularyItem } from "../../constants/Types";
import PageLoadSpinner from "../PageLoadSpinner";
import AddOrEdit from "./AddOrEdit";
import ConfirmExitModal from "./ConfirmExitModal";
import "./Formulary.scss";
import NavBar from "../Provider/NavBar";
import { NAVIGATE_TO } from "../../constants/actionTypes";
import { toast } from "react-toastify";

const mapStateToProps = (state, ownProps) => {
    return {
        permissions: state.common.permissions,
        currentUser: state.common.currentUser,
        ...ownProps,
    };
};

const mapDispatchToProps = (dispatch) => ({
    loadFormularies: () => dispatch({ type: NAVIGATE_TO, targetPath: `/formularies` })
});

const defaultMedication: IFormularyItem = {
    Active: true,
    Condition: null,
    DaysSupply: 0,
    Doseform: null,
    ProductName: null,
    Protocol: null,
    Quantity: null,
    RXCUI: null,
    ReferenceNumber: null,
    Refills: null,
    Route: null,
    Unit: null,
    UsageInstructions: null,
    NDC: null,
    Strength: null,
    ProductNameWithRoute: null,
    Kind: null,
    ID: null,
    PartnerPrice: null,
    PartnerProductName: null,
    PartnerServiceID: null,
    PartnerDosage: null,
    PartnerMedicationRouteType: null,
    PartnerDefaultStatus: null,
    PartnerHiddenStatus: null,
    PartnerChangeNotes: null,
    PartnerField9: null,
    PartnerField10: null,
}

const AddingMed = ({ loadFormularies }) => {
    const [medication, setMedication] = useState<IFormularyItem>(defaultMedication)
    const [loading, setLoading] = useState(false)
    const [showConfirmExit, setShowConfirmExit] = useState(false)

    const { ID } = useParams()

    const handleUpdate = async (med, kind?: string) => {

        const medicationWithId = { ...med, ID: ID }
        try {
            api.Formularies.edit(ID, medicationWithId).then((res) => {
                toast.success("Medication successfuly saved to formulary.")
                loadFormularies()
            })
        } catch (err) {
            console.log(err)
        }
    }

    const handleCancel = () => {
        setShowConfirmExit(false)
        loadFormularies()
    }

    return (
        <>
            <NavBar />
            <div className="manageFormulary-containter">
                <div className="dashboard-component">
                    {loading && <div className="m-auto">
                        <PageLoadSpinner inProgress={loading} />
                    </div>}
                    {showConfirmExit && <ConfirmExitModal visible={showConfirmExit} setVisible={setShowConfirmExit} onConfirm={handleCancel} />}
                    <div>
                        <AddOrEdit
                            medication={medication}
                            setMedication={setMedication}
                            handleUpdate={handleUpdate}
                            editing={false}
                            adding={true}
                            copy={false}
                            setShowConfirmExit={setShowConfirmExit}
                        />
                    </div>
                </div>
            </ div>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(AddingMed);
