import React from "react";
import { Chart } from "react-google-charts";



const optionsPie = {
    title: '',
    height: 160,
    width: 255,
    legend: { position: "right" },
    pieSliceText: "visits",
    pieStartAngle: 100,
}


const PieChart = ({ data }) => {


    return (
        <>
            <div className="pie-chart-wrapper w-25">
                <span className=" pie-chart-header">{`Provider Visit Count`}</span>
                <Chart
                    chartType="PieChart"
                    data={data}
                    options={optionsPie}
                    loader={<div className="d-flex justify-content-center mt-5" style={{ height: 140, width: 900 }}>Loading chart...</div>}
                    width="100%"
                />
            </div>
        </>
    )
}

export default PieChart