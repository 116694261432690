import React, { useState, useEffect } from "react";
import { Input, Button, Table } from "reactstrap";
import moment from "moment";
import PropTypes from "prop-types";

import api from "../../api";
import PatientNoteIcon from "../../images/NoteIcon2.svg";
import BillingReceiptIcon from "../../images/ReceiptIcon2.svg";
import Plus from "../../images/Plus2.svg";
import PDFButton from "../PDFButton";
import Spinner from "../../images/Spinner.svg";
import styles from "./chart.scss";
import MdboxIcon from "../../images/mdboxIcon.svg";
import PccIcon from "../../images/pccIcon.svg";
import StrikeOutIcon from "../../images/StrikeOutIcon.svg";
import ViewEntry from "./ViewEntry";
import SmallCaretBlue from "../../images/SmallCaretBlue.svg";
import EditWrapper from "./EditWrapper";
import UploadDetails from "./UploadDetails";
import StrikeOutNote from "../StrikeOutNote";
import { hasPermission, PERMISSION_ADMIN, PERMISSION_SCRIBE } from "../../constants/Permissions";
import PhotoIcon from "../../images/PhotoIcon.svg";

//for testing items
const uploadNames = [
  "SARS-CoV2 Nasal Swab Test Result",
  "SARS-CoV2 Saliva Test Result",
  "SARS-CoV-2 Antibody Assessment",
];

const DocumentTableRow = ({
  entry,
  uploadNames,
  canEditNote,
  dateFormat,
  yearFormat,
  onViewEntry,
  onShowCovidUploadForEntry,
  onShowStrikeOutModal,
  onSaveChartItem,
  isAdmin,
  isScribe,
  handleImages,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const [data, setData] = useState(entry);
  const [oldData, setOldData] = useState(entry);

  useEffect(() => {
    // on changes to the entry, update the internal data
    setData(entry);
    setOldData(entry);
  }, [entry]);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const onDetailsChange = (fieldName) => (value) => {
    setData({ ...data, [fieldName]: value });
  };

  const onEditCancel = () => {
    setData(oldData);
  };

  const onEditSave = () => {
    !!onSaveChartItem && onSaveChartItem(data);
    setOldData(data);
  };

  const onShowVisitPhotos = (entry) => {
    const refKey = `${entry.referenceKey.ID}|${entry.referenceKey.Kind}` || null;
    const date = !!entry.createdAt && moment(entry.createdAt).format("L");
    const title = `${date} - ${entry.description}`;
    !!handleImages && handleImages(refKey, title);
  };

  return (
    <tr>
      <td
        className={`${styles.tableDate} ${!showDetails || (entry.strikeOut && styles.alignMiddle)}`}
      >
        <div className={styles.yearFormat}> {moment(entry.createdAt).format("lll")}</div>
        {/*{dateFormat(moment(entry.createdAt).format("ll"))}*/}
        {/*<div className={styles.yearFormat}>{yearFormat(moment(entry.createdAt).format("ll"))}</div>*/}
      </td>
      <td className={styles.icons}>{entry.isPCCNote ? <PccIcon /> : <MdboxIcon />}</td>
      <td
        className={`${styles.description} ${
          !showDetails || (entry.strikeOut && styles.alignMiddle)
        }`}
      >
        <div className="d-flex">
          <p className={`${styles.descriptionText} ${entry.strikeOut && styles.strikeOutedText}`}>
            {entry.description}
          </p>
          {uploadNames.includes(entry.description) && (
            <span onClick={toggleDetails} className={styles.showButton}>
              {showDetails ? "Hide Details" : "View Details"}
              <span className={showDetails ? styles.rotatedCaret : ""}>
                <SmallCaretBlue />
              </span>
            </span>
          )}
        </div>
        {!uploadNames.includes(entry.description) ? (
          <div className={`${styles.providerName} ${entry.strikeOut && styles.strikeOutedText}`}>
            {entry.providerName ? `Provider: ${entry.providerName}` : ""}{" "}
            {entry.scribeName && `, Scribe: ${entry.scribeName}`}
          </div>
        ) : (
          ""
        )}
        {entry.strikeOut && (
          <div className={`${styles.reasonBlock} mt-3 mb-2`}>
            <p className={styles.reasonHeader}>Reason for Strike Out:</p>
            <p className="mt-0">{entry.strikeOutReason}</p>
          </div>
        )}
        {showDetails && uploadNames.includes(entry.description) && (
          <div className={styles.fileEdit}>
            <EditWrapper data={data} onCancel={onEditCancel} onSave={onEditSave}>
              <UploadDetails details={data} onChange={onDetailsChange} />
            </EditWrapper>
          </div>
        )}
      </td>

      <td>
        {!entry.strikeOut && (
          <div className="d-flex justify-content-end">
            {entry.receiptURL && (
              <PDFButton
                pdfRequisitionURL={entry.pdfRequisitionURL || null}
                url={entry.receiptURL}
                style={styles.chartBillingButton}
                encounterKey={
                  entry.referenceKey ? `${entry.referenceKey.ID}|${entry.referenceKey.Kind}` : null
                }
                isScribe={isScribe}
                id="qa-documents-billingreciept-btn"
              >
                <BillingReceiptIcon />
              </PDFButton>
            )}
          </div>
        )}
      </td>
      <td></td>
      <td className={styles.pdfButtonContainer}>
        {!entry.strikeOut && (
          <div className="d-flex justify-content-end">
            {!!entry.hasImage && (
              <Button
                className={`${styles.photoBtn} mr-3`}
                id="qa-viewPhoto-btn"
                onClick={() => onShowVisitPhotos(entry)}
              >
                <PhotoIcon />
              </Button>
            )}
            {entry.pdfURL ? (
              <PDFButton
                pdfRequisitionURL={entry.pdfRequisitionURL || null}
                isScribe={isScribe}
                url={entry.pdfURL}
                style={styles.chartNoteButton}
                showEditButton={!!canEditNote}
                encounterKey={
                  entry.referenceKey ? `${entry.referenceKey.ID}|${entry.referenceKey.Kind}` : null
                }
                id="qa-documents-pdfnote-btn"
                visitType={entry.type}
              >
                <PatientNoteIcon />
              </PDFButton>
            ) : !!entry.labStatus && entry.labStatus === "pending-results" ? (
              <Button
                className={styles.headerButton}
                onClick={() => !!onShowCovidUploadForEntry && onShowCovidUploadForEntry(entry)}
              >
                <Plus />
              </Button>
            ) : (
              <Button
                className={styles.chartNoteButton}
                id="qa-chartnote-btn"
                onClick={() => {
                  !!onViewEntry && onViewEntry(entry);
                }}
              >
                <PatientNoteIcon />
              </Button>
            )}
            {isAdmin && !entry.isPCCNote && (
              <Button
                className={`${styles.strikeOutBtn} ml-3`}
                id="qa-strikenote-btn"
                onClick={() => {
                  !!onShowStrikeOutModal && onShowStrikeOutModal(entry.key);
                }}
              >
                <StrikeOutIcon />
              </Button>
            )}
          </div>
        )}
      </td>
    </tr>
  );
};

const Documents = ({
  userID,
  profile,
  isPccIntegrated,
  canEditNote,
  onShowCovidUploadForEntry,
  entriesLoaded,
  entries,
  setEntries,
  loadEntries,
  permissions,
  handleImages,
  ...props
}) => {
  const [loaded, setLoaded] = useState(false);
  const [viewEntry, setViewEntry] = useState(null);
  const [strikeOutModal, setStrikeOutModal] = useState(false);
  const [key, setKey] = useState<{ ID: string; Kind: string } | null>(null);
  const [reason, setReason] = useState("");
  const isAdmin: boolean = hasPermission(permissions, PERMISSION_ADMIN);
  const isScribe: boolean = hasPermission(permissions, PERMISSION_SCRIBE);

  useEffect(() => {
    setLoaded(entriesLoaded);

    if (!!props.mockEntries && Array.isArray(props.mockEntries)) {
      // for "storybook" modeling
      setEntries(props.mockEntries);
      setLoaded(true);
    }
  }, [entriesLoaded]);

  const onSaveChartItem = (data) => {
    api.UserRecords.editChartItem(userID, data);
  };

  const dateFormat = (date) => {
    let index = date.indexOf(",");
    let day = date.substr(0, index);
    return day;
  };

  const onViewEntry = (entry) => {
    setViewEntry(entry);
  };

  const onShowStrikeOutModal = (KeyObj: { ID: string; Kind: string }) => {
    setKey(KeyObj);
    setStrikeOutModal(!strikeOutModal);
  };

  const onCancelModal = () => {
    setKey(null);
    setStrikeOutModal(false);
    setReason("");
  };

  const onStrikeOutNote = async () => {
    if (reason) {
      try {
        await api.UserRecords.strikeOutNote(userID, { reason: reason, key: key! });
        setStrikeOutModal(!strikeOutModal);
        setReason("");
        loadEntries([]);
      } catch (e) {
        console.log(e);
      }
    } else {
      return;
    }
  };
  const yearFormat = (date) => {
    let index = date.indexOf(",");
    let year = date.substr(index + 1);
    return year;
  };
  const patient = (profile || {}).patient;
  const userName = patient.name;
  return (
    <div className={styles.documentsComponent}>
      <StrikeOutNote visible={strikeOutModal} onCancel={onCancelModal} onDelete={onStrikeOutNote}>
        <div className="mb-4 w-100">
          <Input
            onChange={(e) => setReason(e.target.value)}
            className={styles.reasonTextArea}
            placeholder="Type any additional info…"
            type="textarea"
          />
        </div>
      </StrikeOutNote>
      <div>
        <h2 className="flex-fill" style={{ fontSize: "20px" }}>
          {" "}
          Documents{" "}
        </h2>
        {viewEntry && (
          <ViewEntry
            userName={userName}
            entry={viewEntry}
            userID={userID}
            onClose={() => setViewEntry(null)}
          />
        )}
        {!loaded ? (
          <div className="d-flex justify-content-center">
            <Spinner />
          </div>
        ) : entries.length < 1 ? (
          <p>There are no chart items for this patient.</p>
        ) : (
          <div className={styles.chartTable}>
            <Table>
              <thead>
                <tr>
                  <th>{""}</th>
                  <th>{""}</th>
                  <th>{""}</th>
                  <th>{""}</th>
                  <th>{""}</th>
                </tr>
              </thead>
              <tbody>
                {entries.map((entry, idx) => (
                  <DocumentTableRow
                    isAdmin={isAdmin}
                    isScribe={isScribe}
                    key={`chartentry-${idx}`}
                    uploadNames={uploadNames}
                    entry={entry}
                    onShowStrikeOutModal={onShowStrikeOutModal}
                    onViewEntry={onViewEntry}
                    onShowCovidUploadForEntry={onShowCovidUploadForEntry}
                    onSaveChartItem={onSaveChartItem}
                    canEditNote={canEditNote}
                    dateFormat={dateFormat}
                    yearFormat={yearFormat}
                    handleImages={handleImages}
                  />
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Documents;
