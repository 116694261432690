import React, { FC } from "react";
import AsyncCreatableSelect from "react-select/lib/AsyncCreatable";
import api from "../../api";

type Props = {
    handleChange: (selected: { value: string, label: string }, field: { name: string }) => void
    field: string
    selectedItem: { label: string, value: string } | null
}
const customStyles = {
    valueContainer: (provided) => ({
        ...provided,
        maxHeight: "40px",
        overflow: "scroll",
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: "8px 2px",
    }),
    clearIndicator: (provided) => ({
        ...provided,
        padding: "8px 2px",
        innerHeight: "9px",
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        padding: "1px 0px",
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        padding: "1px 0px",
    }),
};

const providerOptions = [
    { label: "Unassigned", value: "unassigned" }
]

const ApiSelect: FC<Props> = ({ handleChange, field, selectedItem }) => {

    const loadOptions = async (text) => {

        let result: any = null
        if (text?.length > 1) {
            try {
                result = await api.Monitoring.searchFields(field, text)
            } catch (err) { }
        }

        const options = result?.data || []
        handleChange({ value: text, label: text }, { name: field })
        if (field === "providerName") {
            let apiOptions = options.map((item) => ({ value: item, label: item })) || []

            return apiOptions.length ? [providerOptions[0], ...apiOptions] : providerOptions
        } else {
            return options.map((item) => ({ value: item, label: item }))

        }

    }



    const onInputChange = (value) => {
        handleChange(value, { name: field })
    }

    return (
        <AsyncCreatableSelect
            styles={customStyles}
            isMulti={false}
            loadOptions={loadOptions}
            onChange={onInputChange}
            value={selectedItem}
            isClearable
        />
    )
}

export default ApiSelect