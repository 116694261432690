import React from 'react'
import Warning from "../../images/Warning.svg"
import Octicon, { X } from '@githubprimer/octicons-react'

const DeleteModal = ({ visible, setVisible, deleteMed }) => {

    if (!visible) {
        return null
    }



    return (
        <div className='modal-overlay'>
            <div className='deleteModal-container mr-auto ml-auto'>
                <div className='d-flex justify-content-end mr-2'><button className='delete-exit-btn' type="button" onClick={() => setVisible()}><Octicon icon={X} size={18} /></button></div>
                <div className='delete-body d-flex justify-content-center flex-column pb-2'>
                    <div className='ml-auto mr-auto mt-4'> <Warning /> </div>
                    <div className='ml-auto mr-auto delete-text mt-2'>Delete Entry?</div>
                    <div className='ml-auto mr-auto delete-subtext mt-3'>This can not be undone.</div>
                </div>
                <div className="del-modal-btns d-flex flex-row mt-4 justify-content-end mr-3">
                    <button className="del-cancel-btn" type="button" onClick={() => setVisible()}>Cancel</button>
                    <button className="delete-btn-form" type="button" onClick={() => deleteMed()}>Delete</button>
                </div>
            </div>
        </div>
    )

}

export default DeleteModal