import React, { FC, useState } from "react";
import { Button } from "reactstrap";
import moment from "moment";
import PropTypes from "prop-types";

import styles from "./PatientSideBar.scss";
import PillIcon from "../../images/DoseSpotIcon.svg";
import UploadIconWhite from "../../images/UploadIconWhite.svg";
import { formatPhoneNumber, openInNewTab } from "../../utils";
import NoteIcon from "../../images/PatientNoteIcon.svg";
import ArrowIcon from "../../images/whiteDownArrow.svg";
import PrescriptionButton from "../Provider/PrescriptionButton";
import NewNoteSelect from "../NewNoteSelect";
import ConsentToggle from "../ConsentToggleButton";

import OnHoverTitle from "./OnHoverTitle";
import ChartMenu from "./ChartMenu";
import PatientServicesTags from "./PatientServicesTags";
import { ICurrentUser, IPatientProfile } from "../../constants/Types";
import { MouseEventHandler } from "react-select/lib/types";
import {
  hasAnyPermission,
  hasPermission,
  PERMISSION_SCRIBE,
  PERMISSION_SEE_PATIENTS, PERMISSION_THERAPIST,
} from "../../constants/Permissions";
import classNames from "classnames";
import ButtonWithLoader from "./ButtonWithLoader";
import api from "../../api";

const offsetOfTitle = 10;

type Props = {
  consent: boolean | null;
  setConsent: Function;
  userID: string;
  currentUser: ICurrentUser;
  profile: IPatientProfile;
  image: string;
  defaultImage: string;
  initiateEncounter: (visitType: string) => void;
  isNursingHomeSite: boolean;
  consentDate: string | null;
  onUpload: MouseEventHandler;
  setEditConsentModal: (consentModal: boolean) => void;
  isPCCPatient: boolean;
  onAddNote: MouseEventHandler;
  onEdit: () => void;
  setPrescriptionError: Function;
  permissions: string[];
  viewHealthGorillaChart: () => void;
  isBtnPending: boolean;
};

const PatientSideBarProfileInfo: FC<Props> = ({
  consent,
  setConsent,
  profile,
  image,
  defaultImage,
  userID,
  currentUser,
  onUpload,
  onAddNote,
  onEdit,
  setPrescriptionError,
  initiateEncounter,
  isNursingHomeSite,
  consentDate,
  isPCCPatient,
  setEditConsentModal,
  permissions,
  viewHealthGorillaChart,
  isBtnPending
}) => {

  const [mouseCoordinate, setMouseCoordinate] = useState<{ x: number; y: number } | null>(null);

  const patientSex = profile.patient.sex === "F" ? "Female" : "Male";
  const patientLanguage = profile.medicalProfile.languages ? profile.medicalProfile.languages[0] : [];
  const statusDate = consentDate ? moment(consentDate).format("l") : "";
  const rpmFlag =
    !!profile.patient && !!profile.patient.consentDetails.rpm
      ? profile.patient.consentDetails.rpm
      : false;
  const ccmFlag =
    !!profile.patient && !!profile.patient.consentDetails.ccm
      ? profile.patient.consentDetails.ccm
      : false;
  const covidZone = profile.patient.covidZone;
  const grayed = isNursingHomeSite ? isNursingHomeSite && consent : true;
  const disabledCheck =
    grayed &&
    !!currentUser &&
    !!currentUser?.capabilities &&
    currentUser.capabilities?.geographic?.includes(profile.patient.state);

  const iconColor = !disabledCheck ? "#7F91A8" : "white";
  const isScribe = hasPermission(permissions, PERMISSION_SCRIBE);
  const isHealthGorillaUser = profile.patient.healthGorillaUserID;
  const nursingHomeNewNote = currentUser && hasAnyPermission(currentUser.permissions, PERMISSION_SEE_PATIENTS, PERMISSION_THERAPIST);

  const startEncounter = (visitType) => {
    initiateEncounter(visitType);
  };
  const onButtonsHover = (nativeEvent) => {
    if (!consent) {
      setMouseCoordinate({
        x: nativeEvent.offsetX + offsetOfTitle,
        y: nativeEvent.offsetY + offsetOfTitle,
      });
    }
  };
  const isProviderScribe = currentUser?.providerScribingStates && currentUser?.providerScribingStates?.includes(profile?.patient?.state) || false
  const formatLanguage = (lan) => {
    if (typeof lan === "string" && lan.toLowerCase() === "en") {
      return "English";
    } else if (typeof lan === "string" && lan.toLowerCase() === "sp") {
      return "Spanish";
    }
    return lan;
  };

  return (
    <>
      <div className="d-flex justify-content-start mt-5 mb-4">
        <div className={styles.patientPhoto}>
          <img src={image || defaultImage} alt="Photo" />
        </div>
        <div className={styles.infoList}>
          <h2 className={styles.infoName}>
            {profile.patient.firstName} {profile.patient.lastName}
          </h2>
          <p>
            DOB: {profile.patient.dob} ({moment().diff(profile.patient.dob, "years", false)} years
            old)
          </p>
          <p>Sex: {patientSex}</p>
        </div>
        <ChartMenu
          onEdit={onEdit}
          isNursingHomeSite={isNursingHomeSite}
          isPCCPatient={isPCCPatient}
          setConsentModal={setEditConsentModal}
        />
      </div>
      <div
        onMouseMove={(e) => onButtonsHover(e.nativeEvent)}
        className={`mb-3 pr-2 ${styles.buttonsContainer}`}
      >
        <div
          className={`d-flex justify-content-center flex-column mb-3 pr-2 ${styles.consentPopUp}`}
        >
          {!!isNursingHomeSite && mouseCoordinate && (
            <OnHoverTitle
              coordinate={mouseCoordinate}
              styles={styles.showTitleBlock}
              visible={!consent}
            >
              Need Patient Consent
            </OnHoverTitle>
          )}
          <div className={!!isNursingHomeSite ? `${!consent && "noEvent"}` : ""}>
            <NewNoteSelect
              disabled={!disabledCheck}
              startEncounter={startEncounter}
              setAdding={onAddNote}
              isScribe={isScribe}
              nursingHomeNewNote={nursingHomeNewNote}
              providerScribe={isProviderScribe}
            />
            <div className={styles.buttonWrapper}>
              <PrescriptionButton
                disabled={!disabledCheck || isScribe}
                userID={userID}
                style={styles.greyButton}
                setPrescriptionError={setPrescriptionError}
              >
                <PillIcon stroke={iconColor} />
                Write Rx
              </PrescriptionButton>
              <Button
                color="none"
                className={styles.blueButton}
                onClick={onUpload}
                id="qa-uploadfile-sidebarprofileinfo-btn"
              >
                <UploadIconWhite stroke={iconColor} className="mr-2" />
                Upload File
              </Button>
              {currentUser?.healthGorillaIsEnable && (
                <>
                  <ButtonWithLoader
                    isAlreadyExist={!!isHealthGorillaUser}
                    inProgress={isBtnPending}
                    classes={classNames(styles.newNoteBtn, "mt-3 mb-0", "w-100")}
                    onBtnClick={viewHealthGorillaChart}
                  >
                    <span>View in Health Gorilla</span>
                  </ButtonWithLoader>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.patientInfo}>
        {!!isNursingHomeSite && (
          <div className={styles.profileBlock}>
            {/* add patient tags for nh practices */}
            <PatientServicesTags
              consent={consent}
              covidZone={covidZone}
              RPM={rpmFlag}
              CCM={ccmFlag}
            />
          </div>
        )}
        <div className={styles.profileBlock}>
          <h2>Preferred Language</h2>
          <p>{formatLanguage(patientLanguage)}</p>
        </div>
        <div className={styles.profileBlock}>
          <h2>Contact Information</h2>
          <p>
            {profile.patient.phone}
            <br />
            {profile.patient.email}{" "}
          </p>
        </div>
        <div className={styles.profileBlock}>
          <h2>Home Address</h2>
          {!profile.patient.street ||
            !profile.patient.city ||
            !profile.patient.state ||
            !profile.patient.postalCode ? (
            <p>Not Provided</p>
          ) : (
            <p>
              {profile.patient.street}
              <br />
              {profile.patient.street2 && <>{profile.patient.street2} <br /></>}
              {profile.patient.city}, {profile.patient.state} {profile.patient.postalCode}
            </p>
          )}
        </div>
        <div className={styles.profileBlock}>
          <h2>Emergency Contact</h2>
          {!profile.medicalProfile.emergencyContact.name ||
            !profile.medicalProfile.emergencyContact.contactNumber ? (
            <p>Not Provided</p>
          ) : (
            <p>
              {profile.medicalProfile.emergencyContact.name}
              <br />
              {profile.medicalProfile.emergencyContact.contactNumber}
            </p>
          )}
        </div>
        <div className={styles.profileBlock}>
          <h2>Primary Physican</h2>
          <p>
            {profile.medicalProfile.primaryCareProvider.name || "Not provided"}, Fax:{" "}
            {!!profile.medicalProfile.primaryCareProvider.fax
              ? profile.medicalProfile.primaryCareProvider.fax
              : "Not provided"}
          </p>
        </div>
        <div className={styles.profileBlock}>
          <h2>Preferred Pharmacy</h2>
          {!profile.medicalProfile.preferredPharmacy.storeName ? (
            <p>Not Provided</p>
          ) : (
            <p>
              {profile.medicalProfile.preferredPharmacy.storeName}
              <br />
              {formatPhoneNumber(profile.medicalProfile.preferredPharmacy.primaryPhone)}
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default PatientSideBarProfileInfo;
