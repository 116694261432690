import React, { useEffect, useState } from "react";
import CheckToggle from "../CheckToggle";
import Octicon, { X } from "@githubprimer/octicons-react";
import { Row, Col } from "reactstrap";


const CustomizeTableModal = ({ visible, setVisible, selectedColumns, setColumns, allColumns }) => {

    const [selected, setSelected] = useState<[] | string[]>([])
    const [allSelected, setAllSelected] = useState<boolean>(false);

    useEffect(() => {
        setSelected(selectedColumns);
        if (selectedColumns.length === allColumns.length) {
            setAllSelected(true)
        }
    }, [selectedColumns]);

    const handleCheck = (value: string) => {
        const list: string[] = [...selected];

        if (list.includes(value)) {
            let index = list.indexOf(value);
            list.splice(index, 1);
        } else {
            list.push(value);
        }
        setSelected(list);
    }

    const handleSave = () => {
        setColumns(selected)
        setVisible()
    }

    const handleAllCheck = () => {
        if (allSelected) {
            setSelected([]);
        } else {
            setSelected(allColumns);
        }
        setAllSelected(!allSelected);
    }

    if (!visible) {
        return null
    }

    return (
        <div className="modal-overlay">
            <div className="m-auto formulary-table-modal">
                <div className="d-flex flex-row-reverse">
                    <button type="button" className="exit-modal-btn mr-3 mt-3" onClick={() => setVisible()}>
                        <Octicon icon={X} size={18} />
                    </button>
                </div>
                <Row>
                    <Col className="title-container d-flex" md="12">
                        <h1 className="title">
                            Visible Columns
                        </h1>
                        <div className="d-flex pl-5 pr-5">
                            <CheckToggle
                                size={"small"}
                                onChange={handleAllCheck}
                                checked={allSelected}
                                className="encounter-modal-toggle"
                                disabled={false}
                            />
                            <span className="check-label">Check All/Uncheck All</span>
                        </div>
                    </Col>
                </Row>
                <div className="inputs-container d-flex w-100">
                    <Row className="ml-5 mr-5">
                        <Col md="6">
                            <div className="d-flex mb-3" >
                                <CheckToggle
                                    size={"small"}
                                    onChange={() => handleCheck("Condition")}
                                    checked={selected?.includes("Condition")}
                                    className="formulary-modal-toggle"
                                    disabled={false}
                                />
                                <span className="check-label">Condition</span>
                            </div>
                            <div className="d-flex mb-3" >
                                <CheckToggle
                                    size={"small"}
                                    onChange={() => handleCheck("ProductName")}
                                    checked={selected?.includes("ProductName")}
                                    className="formulary-modal-toggle"
                                    disabled={false}
                                />
                                <span className="check-label">Product Name</span>
                            </div>
                            <div className="d-flex mb-3" >
                                <CheckToggle
                                    size={"small"}
                                    onChange={() => handleCheck("ProductNameWithRoute")}
                                    checked={selected?.includes("ProductNameWithRoute")}
                                    className="formulary-modal-toggle"
                                    disabled={false}
                                />
                                <span className="check-label">Product Name With Route</span>
                            </div>
                            <div className="d-flex mb-3" >
                                <CheckToggle
                                    size={"small"}
                                    onChange={() => handleCheck("Strength")}
                                    checked={selected?.includes("Strength")}
                                    className="formulary-modal-toggle"
                                    disabled={false}
                                />
                                <span className="check-label">Strength</span>
                            </div>
                            <div className="d-flex mb-3" >
                                <CheckToggle
                                    size={"small"}
                                    onChange={() => handleCheck("RXCUI")}
                                    checked={selected?.includes("RXCUI")}
                                    className="formulary-modal-toggle"
                                    disabled={false}
                                />
                                <span className="check-label">RXCUI</span>
                            </div>
                            <div className="d-flex mb-3" >
                                <CheckToggle
                                    size={"small"}
                                    onChange={() => handleCheck("NDC")}
                                    checked={selected?.includes("NDC")}
                                    className="formulary-modal-toggle"
                                    disabled={false}
                                />
                                <span className="check-label">NDC</span>
                            </div>
                            <div className="d-flex mb-3" >
                                <CheckToggle
                                    size={"small"}
                                    onChange={() => handleCheck("Route")}
                                    checked={selected?.includes("Route")}
                                    className="formulary-modal-toggle"
                                    disabled={false}
                                />
                                <span className="check-label">Route</span>
                            </div>
                            <div className="d-flex mb-3" >
                                <CheckToggle
                                    size={"small"}
                                    onChange={() => handleCheck("Doseform")}
                                    checked={selected?.includes("Doseform")}
                                    className="formulary-modal-toggle"
                                    disabled={false}
                                />
                                <span className="check-label">Dose Form</span>
                            </div>
                            <div className="d-flex mb-3" >
                                <CheckToggle
                                    size={"small"}
                                    onChange={() => handleCheck("Quantity")}
                                    checked={selected?.includes("Quantity")}
                                    className="formulary-modal-toggle"
                                    disabled={false}
                                />
                                <span className="check-label">Quantity</span>
                            </div>
                            <div className="d-flex mb-3" >
                                <CheckToggle
                                    size={"small"}
                                    onChange={() => handleCheck("Unit")}
                                    checked={selected?.includes("Unit")}
                                    className="formulary-modal-toggle"
                                    disabled={false}
                                />
                                <span className="check-label">Unit</span>
                            </div>
                            <div className="d-flex mb-3" >
                                <CheckToggle
                                    size={"small"}
                                    onChange={() => handleCheck("Refills")}
                                    checked={selected?.includes("Refills")}
                                    className="formulary-modal-toggle"
                                    disabled={false}
                                />
                                <span className="check-label">Refills</span>
                            </div>
                            <div className="d-flex mb-3" >
                                <CheckToggle
                                    size={"small"}
                                    onChange={() => handleCheck("DaysSupply")}
                                    checked={selected?.includes("DaysSupply")}
                                    className="formulary-modal-toggle"
                                    disabled={false}
                                />
                                <span className="check-label">Days Supply</span>
                            </div>
                            <div className="d-flex mb-3" >
                                <CheckToggle
                                    size={"small"}
                                    onChange={() => handleCheck("UsageInstructions")}
                                    checked={selected?.includes("UsageInstructions")}
                                    className="formulary-modal-toggle"
                                    disabled={false}
                                />
                                <span className="check-label">Usage Instructions</span>
                            </div>
                            <div className="d-flex mb-3" >
                                <CheckToggle
                                    size={"small"}
                                    onChange={() => handleCheck("Protocol")}
                                    checked={selected?.includes("Protocol")}
                                    className="formulary-modal-toggle"
                                    disabled={false}
                                />
                                <span className="check-label">Protocol</span>
                            </div>



                        </Col>
                        <Col md="6">
                            <div className="d-flex mb-3" >
                                <CheckToggle
                                    size={"small"}
                                    onChange={() => handleCheck("ReferenceNumber")}
                                    checked={selected?.includes("ReferenceNumber")}
                                    className="formulary-modal-toggle"
                                    disabled={false}
                                />
                                <span className="check-label">Reference Number</span>
                            </div>
                            <div className="d-flex mb-3" >
                                <CheckToggle
                                    size={"small"}
                                    onChange={() => handleCheck("Active")}
                                    checked={selected?.includes("Active")}
                                    className="formulary-modal-toggle"
                                    disabled={false}
                                />
                                <span className="check-label">Active</span>
                            </div>
                            <div className="d-flex mb-3" >
                                <CheckToggle
                                    size={"small"}
                                    onChange={() => handleCheck("Updated")}
                                    checked={selected?.includes("Updated")}
                                    className="formulary-modal-toggle"
                                    disabled={false}
                                />
                                <span className="check-label">Last Updated</span>
                            </div>
                            <div className="d-flex mb-3" >
                                <CheckToggle
                                    size={"small"}
                                    onChange={() => handleCheck("UpdatedBy")}
                                    checked={selected?.includes("UpdatedBy")}
                                    className="formulary-modal-toggle"
                                    disabled={false}
                                />
                                <span className="check-label">Updated By</span>
                            </div>
                            <div className="d-flex mb-3" >
                                <CheckToggle
                                    size={"small"}
                                    onChange={() => handleCheck("PartnerPrice")}
                                    checked={selected?.includes("PartnerPrice")}
                                    className="formulary-modal-toggle"
                                    disabled={false}
                                />
                                <span className="check-label">Partner Price</span>
                            </div>
                            <div className="d-flex mb-3" >
                                <CheckToggle
                                    size={"small"}
                                    onChange={() => handleCheck("PartnerProductName")}
                                    checked={selected?.includes("PartnerProductName")}
                                    className="formulary-modal-toggle"
                                    disabled={false}
                                />
                                <span className="check-label">Partner Product Name</span>
                            </div>
                            <div className="d-flex mb-3" >
                                <CheckToggle
                                    size={"small"}
                                    onChange={() => handleCheck("PartnerServiceID")}
                                    checked={selected?.includes("PartnerServiceID")}
                                    className="formulary-modal-toggle"
                                    disabled={false}
                                />
                                <span className="check-label">Partner Service ID</span>
                            </div>
                            <div className="d-flex mb-3" >
                                <CheckToggle
                                    size={"small"}
                                    onChange={() => handleCheck("PartnerDosage")}
                                    checked={selected?.includes("PartnerDosage")}
                                    className="formulary-modal-toggle"
                                    disabled={false}
                                />
                                <span className="check-label">Partner Dosage</span>
                            </div>
                            <div className="d-flex mb-3" >
                                <CheckToggle
                                    size={"small"}
                                    onChange={() => handleCheck("PartnerMedicationRouteType")}
                                    checked={selected?.includes("PartnerMedicationRouteType")}
                                    className="formulary-modal-toggle"
                                    disabled={false}
                                />
                                <span className="check-label">Partner Medication Route + Type</span>
                            </div>
                            <div className="d-flex mb-3" >
                                <CheckToggle
                                    size={"small"}
                                    onChange={() => handleCheck("PartnerDefaultStatus")}
                                    checked={selected?.includes("PartnerDefaultStatus")}
                                    className="formulary-modal-toggle"
                                    disabled={false}
                                />
                                <span className="check-label">Partner Default Status</span>
                            </div>
                            <div className="d-flex mb-3" >
                                <CheckToggle
                                    size={"small"}
                                    onChange={() => handleCheck("PartnerHiddenStatus")}
                                    checked={selected?.includes("PartnerHiddenStatus")}
                                    className="formulary-modal-toggle"
                                    disabled={false}
                                />
                                <span className="check-label">Partner Hidden Status</span>
                            </div>
                            <div className="d-flex mb-3" >
                                <CheckToggle
                                    size={"small"}
                                    onChange={() => handleCheck("PartnerChangeNotes")}
                                    checked={selected?.includes("PartnerChangeNotes")}
                                    className="formulary-modal-toggle"
                                    disabled={false}
                                />
                                <span className="check-label">Partner Change Notes</span>
                            </div>
                            <div className="d-flex mb-3" >
                                <CheckToggle
                                    size={"small"}
                                    onChange={() => handleCheck("PartnerField9")}
                                    checked={selected?.includes("PartnerField9")}
                                    className="formulary-modal-toggle"
                                    disabled={false}
                                />
                                <span className="check-label">Partner Field 9</span>
                            </div>
                            <div className="d-flex mb-3" >
                                <CheckToggle
                                    size={"small"}
                                    onChange={() => handleCheck("PartnerField10")}
                                    checked={selected?.includes("PartnerField10")}
                                    className="formulary-modal-toggle"
                                    disabled={false}
                                />
                                <span className="check-label">Partner Field 10</span>
                            </div>

                        </Col>
                    </Row>
                </div>
                <div className="d-flex justify-content-end mr-3 pb-3 mt-3">
                    <button className="modal-cancel" type="button" onClick={() => setVisible()}>Cancel</button>
                    <button className="modal-save" type="button" onClick={() => handleSave()}>Save</button>
                </div>
            </div>
        </div>
    )

}

export default CustomizeTableModal