import React, { FC, useEffect, useState } from "react";
import { IAssignableProviderItem } from "../../constants/Types";
import api from "../../api";
import Select from "react-select";
import { errorLogger } from "../../utils";

type ScribeSectionProps = {
    referenceID: string;
    selectedProvider?: Partial<IAssignableProviderItem>;
    onSelectProvider?: (provider: IAssignableProviderItem) => void;
};
type ProvidersOption = {
    label: string;
    value: string;
    fullData: IAssignableProviderItem | null;
};

const ScribeSection: FC<ScribeSectionProps> = ({
                                                   referenceID = "",
                                                   selectedProvider = {} as Partial<IAssignableProviderItem>,
                                                   onSelectProvider,
                                               }) => {
    const [providers, setProviders] = useState<ProvidersOption[]>([]);

    useEffect(() => {
        async function getList() {
            try {
                const res = await api.Providers.getAssignable(referenceID);
                const formattedOptions = res.items
                  .map((item) => ({ label: item.fullName, value: item.id, fullData: item }))
                  .sort((a, b) => a.label.localeCompare(b.label));

                setProviders(
                  formattedOptions || [{ label: "No providers", value: "No providers", fullData: null }],
                );
            } catch (e) {
                errorLogger(e);
            }
        }
        getList();
    }, []);

    const onSelect = (provider: IAssignableProviderItem | null) => {
        if (provider) {
            onSelectProvider && onSelectProvider(provider);
        }
    };
    return (
      <div className="scribe-section">
          {" "}
          <Select
            components={{
                IndicatorSeparator: () => null,
                MultiValueContainer: () => null,
            }}
            className="w-25"
            menuPlacement="top"
            options={providers}
            onChange={(option: ProvidersOption) => onSelect(option.fullData)}
            hideSelectedOptions={true}
            isSearchable={true}
            placeholder="Select Provider"
          />
      </div>
    );
};

export default ScribeSection;
