import React from "react"
import Octicon, { X } from "@githubprimer/octicons-react";
import { Table } from "reactstrap";




const FilteredDBCompareModal = ({ visible, setVisible, data }) => {


    if (!visible) {
        return null
    }
    return (
        <div className="modal-overlay">
            <div className="m-auto db-modal">
                <div className="d-flex flex-row-reverse">
                    <button type="button" className="dbModal-exit-btn" onClick={() => setVisible(!visible)}>
                        <Octicon icon={X} />
                    </button>
                </div>
                <div>
                    <h1 className="db-comp-title mt-2">Database Comparison Search Filters Applied</h1>
                </div>
                <div>
                    {data.length < 1 ?

                        <div className="ml-auto mr-auto d-flex justify-content-center mt-5 mb-5">Databases are in Sync. </div>

                        :
                        <Table className="dbMatch-table">
                            <thead>
                                <tr>
                                    <th>Encounter ID</th>
                                    <th>Values</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.length > 0 && data?.map((item) => {

                                    return (
                                        <tr >
                                            <td className={!item?.isMatching ? "non-match" : ""}>{item.referenceID}</td>
                                            <td>{!item?.isMatching && item?.notMatched ?
                                                <>
                                                    {item?.notMatched.map((item) => (
                                                        <>
                                                            <div className="non-match">{`${item.field} Dynamo: ${item.dynamodb}`}</div>
                                                            <div className="non-match">{`${item.field} Elastic: ${item.elastic}`}</div>
                                                        </>
                                                    ))}
                                                </>

                                                : "--"}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>}
                </div>
            </div>
        </div>
    )
}

export default FilteredDBCompareModal;