import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import NavBar from "../Provider/NavBar";
import "./Formulary.scss";
import {
  COMPONENT_LOAD,
  COMPONENT_UNLOAD,
  FORMULARY_DATA,
  NAVIGATE_TO,
} from "../../constants/actionTypes";
import api from "../../api";
import PageLoadSpinner from "../PageLoadSpinner";
import FormularySearch from "./FormularySearch";
import Select from "react-select";
import MedicationsTable from "./Table";
import debounce from "lodash.debounce";
import CustomizeModal from "./CustomizeTableModal"
import { IFormulary, IFormularyItem } from "../../constants/Types"
import { toast } from "react-toastify";
import DeleteModal from "./DeleteModal";

const mapStateToProps = (state, ownProps) => {
  return {
    permissions: state.common.permissions,
    currentUser: state.common.currentUser,
    ...state.componentData[FORMULARY_DATA],
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload) =>
    dispatch({
      type: COMPONENT_LOAD,
      subtype: FORMULARY_DATA,
      payload,
    }),
  onUnload: () => dispatch({ type: COMPONENT_UNLOAD, subtype: FORMULARY_DATA }),
  editFormulary: (id, kind) => dispatch({ type: NAVIGATE_TO, targetPath: `/formularies/${id}/${kind}` }),
  addMedication: (id) => dispatch({ type: NAVIGATE_TO, targetPath: `/formularies/new/${id}` })
});

const defaultColumns = ["Condition", "ProductName", "ProductNameWithRoute", "Strength", "RXCUI", "NDC", "Route", "Doseform", "Quantity", "Unit", "Refills", "DaysSupply", "UsageInstructions", "Protocol", "ReferenceNumber", "Active", "Updated", "UpdatedBy", "Duplicate", "Delete", "PartnerPrice", "PartnerProductName", "PartnerServiceID", "PartnerDosage", "PartnerMedicationRouteType", "PartnerDefaultStatus", "PartnerHiddenStatus", "PartnerChangeNotes", "PartnerField9", "PartnerField10"]
//RefillsDays, Supply, UsageSupply, UsageInstructions, Protocol, ReferenceNumber
const FormularyView = ({ onUnload, editFormulary, addMedication }) => {

  const [loading, setLoading] = useState(true);
  // need for new version of endpoints 
  const [selectedFormulary, setSelectedFormulary] = useState<{ label: string; value: any } | null>(null)
  const [formulary, setFormulary] = useState<IFormulary>();
  const [formItems, setFormItems] = useState<IFormularyItem[] | []>();
  const [formularyOptions, setFormularyOptions] = useState([]);
  const [sortType, setSortType] = useState({ field: "", order: "" });

  const [adding, setAdding] = useState(false);
  const [editing, setEditing] = useState(false);
  const [copy, setCopying] = useState(false)
  const [medication, setMedication] = useState(null);

  const [columns, setColumns] = useState(defaultColumns);

  const [showModal, setShowModal] = useState(false);
  const [showConfirmExit, setShowConfirmExit] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const [csvLink, setCSVLink] = useState()
  const actionsDebounceCallback = debounce((func, param) => {
    func(param);
  }, 500);

  useEffect(() => {
    fetchFormularies();
  }, []);

  useEffect(() => {
    if (formulary?.ID) {
      getCSV()
    }

  }, [formulary])

  const getCSV = async () => {
    try {
      let result = await api.Formularies.getCSV(formulary.ID)
      let csv = result?.url || ""
      setCSVLink(csv)
    } catch (err) {
      console.log(err)
    }
  }
  const fetchFormularies = async () => {
    setLoading(true)
    try {
      // fetch practices&names to poulate list 
      const data = await api.Formularies.getPractices()
      let formOptions = data?.items ? data?.items.map((form) => ({ label: form.MedicalPracticeName, value: form })) : []
      setFormularyOptions(formOptions)
      setSelectedFormulary(formOptions[0])

      let medsList = await api.Encounters.getVisitFormularies(formOptions[0]?.value?.MedicalPracticeID)
      let medications = medsList?.Items || []
      setFormItems(medications)

      let formularyObject = { Formularies: [...medications], ID: formOptions[0].value?.FormularyID, MedicalPractice: formOptions[0].value?.MedicalPracticeID, Name: formOptions[0].value?.FormularyName }
      setFormulary(formularyObject)
      if (medications) {
        setLoading(false)
      }
    } catch (err) {
      console.log(err)
    }
  }


  const handleSort = (field, order) => {
    if (sortType.field === field && sortType.order === order) {
      return;
    } else {
      setSortType({ field: field, order: order });
      sortTable(field, order);
    }
  };

  const sortTable = (field, order) => {
    // run just once
    setLoading(true);
    const intFields = ["RXCUI", "Quantity"];
    setTimeout(() => {
      // change value based on previous state
      setFormItems((prev) => {
        // if (!prev) return prev; // avoid optional chaining (subjective)
        const formItemsCopy = [...prev]; // stay immutable

        // simplify
        const x = order === "asc" ? 1 : -1;
        formItemsCopy.sort((a, b) => {
          //   Array value
          if (field === "Condition") {
            let aCased = a[field]?.map((el) => el?.toLowerCase());
            let bCased = b[field]?.map((el) => el?.toLowerCase());

            if (order === "asc") return aCased > bCased ? 1 : -1;
            else return aCased < bCased ? 1 : -1;
          } else {
            if (order === "asc") {
              return a[field] > b[field] ? 1 : -1;
            } else return a[field] < b[field] ? 1 : -1;
          }
        });

        return formItemsCopy;
      });

      setLoading(false);
    }, 0);
  };

  // need to check that every item in the new filtered array contains a matching value
  // based on the entered search params.

  const comparedtosearch = (medication, searchParams) => {
    const intFields = ["DaysSupply", "Quantity", "Refills"];
    for (let [key, value] of Object.entries(searchParams)) {
      // do(es) the object(s) contain the search value
      if (key === "Condition") {
        const includesSearch = (item) => item.toLowerCase().includes(value.toLowerCase());
        // conditions are arrays
        if (!medication[key].some(includesSearch)) {
          return false;
        }
      } else if (intFields.includes(key)) {

        if (medication[key] !== Number(value)) {
          return false;
        }
      } else if (key === "Active") {
        if (medication[key] !== value) {
          return false
        }
      } else {
        if (!medication[key]?.toLowerCase().includes(value.toLowerCase())) {
          return false;
        }
      }
    }
    return true;
  };

  const handleSearch = (searchParams) => {
    // clean copy of the medications (formularies)
    const medications = [...formulary?.Formularies];
    const formFiltered = medications.filter((med) => comparedtosearch(med, searchParams));

    setFormItems(formFiltered);
    setLoading(false);
  };

  const handleCancel = () => {
    setFormItems(formulary?.Formularies);
    actionsDebounceCallback(setLoading, false);
  };

  const handleFormularySwitch = async (item: { label: string; value: any }) => {
    setLoading(true);

    setSelectedFormulary(item)
    try {
      let medsList = await api.Encounters.getVisitFormularies(item?.value?.MedicalPracticeID)
      let medications = medsList?.Items || []
      setFormItems(medications)

      let formularyObject = { Formularies: [...medications], ID: item.value?.FormularyID, MedicalPractice: item.value?.MedicalPracticeID, Name: item.value?.FormularyName }
      setFormulary(formularyObject)
      if (medications) {
        setLoading(false)
      }
    } catch (err) {

    }
  };

  const handleUpdate = async (med, kind?: string) => {
    // setLoading(true);
    setShowModal(false)
    const medicationChanged = adding ? { ...med, ID: formulary?.ID } : med
    //copy of medication list
    const medsCopy = [...formItems]
    // index of the selected med
    let medIndex = medsCopy.findIndex((m) => m.Kind === medicationChanged?.Kind)
    if (medIndex !== -1) {
      //editing
      medsCopy[medIndex] = medicationChanged
    } else {
      toast.error("ERROR: There was an issue saving your changes.")
      return
    }
    setFormItems(medsCopy)
    try {
      api.Formularies.edit(formulary?.ID, medicationChanged).then((res) => {
        toast.success("Formulary saved successfuly")
      })
    } catch (err) {
      console.log(err)
      toast.error("ERROR: There was an issue saving your changes.")
      fetchFormularies()
    }
  }

  const handleDelete = async () => {
    //copy of medications list
    const medsCopy = [...formItems]
    // index of the selected med
    let medIndex = medsCopy.findIndex((m) => m.Kind === medication?.Kind)
    medsCopy.splice(medIndex, 1)
    let formularyObject = { ...formulary, Formulary: [...medsCopy] }
    setFormItems(medsCopy)
    setFormulary(formularyObject)
    setShowDeleteModal(false)
    // setLoading(true)
    try {
      api.Formularies.delete(formulary?.ID, medication).then((res) => {
        toast.success("Medication deleted.")
        setMedication(null)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      toast.error("ERROR: There was an issue deleting the medication.")
    }
  }

  const cancelDelete = () => {
    setMedication(null)
    setShowDeleteModal(false)
  }

  const handleColumnsChange = (columns) => {
    setColumns(columns)
  }


  return (
    <>
      <NavBar />
      {showModal && <CustomizeModal visible={showModal} setVisible={() => setShowModal(!showModal)} selectedColumns={columns} setColumns={handleColumnsChange} allColumns={defaultColumns} />}
      {showDeleteModal && <DeleteModal visible={showDeleteModal} setVisible={cancelDelete} deleteMed={handleDelete} />}
      <div className="manageFormulary-containter">
        <div className="dashboard-component">
          <div className="foumlary-page-header">
            <div className="formulary-page-description">
              <Select
                className="select-input-item"
                options={formularyOptions}
                onChange={handleFormularySwitch}
                value={selectedFormulary}
                isDisabled={loading}
              />
            </div>
            <div className="formulary-header-secondary-items">
              <button
                className="exportCSV-btn"
                type="button"
                disabled={!csvLink || loading}
              >
                {csvLink ? (
                  <a href={csvLink} download>
                    Export to CSV
                  </a>
                ) : (
                  "Export to CSV"
                )}
              </button>
              <button
                className="add-item-btn"
                type="button"
                onClick={() => addMedication(formulary.ID)}
                disabled={loading}
              >
                Add Item
              </button>
            </div>
          </div>
          <>
            <FormularySearch
              cancelSearch={handleCancel}
              search={handleSearch}
              setLoading={setLoading}
              disabled={loading}
              formItems={formItems}
            />
            <div className="display-count">
              <div className="item-count">
                {` Displaying ${formItems?.length || 0
                  } of ${formulary?.Formularies?.length || 0} items`}
              </div>

              <div className="form-customize-te">
                <button disabled={loading} type="button" className="customize-tbl-btn" onClick={() => setShowModal(!showModal)}>Customize Table Columns</button>
              </div>
            </div>
          </>

          <div >
            <div className="table-wrapper">
              <MedicationsTable
                loading={loading}
                formItems={formItems}
                handleSort={handleSort}
                sortType={sortType}
                setLoading={setLoading}
                setEditing={editFormulary}
                setCopying={addMedication}
                setMedication={setMedication}
                handleUpdate={handleUpdate}
                columns={columns}
                formulary={formulary}
                setShowDeleteModal={setShowDeleteModal}
              />
            </div>
            {loading && (
              <div className="m-auto">
                <PageLoadSpinner inProgress={loading} />
              </div>
            )}
            {!loading && (!formItems || !formItems?.length) &&
              <div className="m-auto d-flex">
                <div className="m-auto" style={{ lineHeight: "10" }}>There are currently no medications saved under this formulary.</div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FormularyView);
