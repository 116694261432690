import React from "react";
import { connect } from "react-redux";

import api from "../../api";

import {
  WAITING_ROOM_LOAD_PENDING,
  WAITING_ROOM_LOAD_AVAILABLE,
} from "../../constants/actionTypes";
import {
  hasAnyPermission,
  hasPermission,
  PERMISSION_SEE_PATIENTS,
  PERMISSION_THERAPIST,
} from "../../constants/Permissions";
import { toast } from "react-toastify";
import { checkIsJSON, errorLogger } from "../../utils";

const mapStateToProps = (state, ownProps) => ({
  currentTime: state.time.currentTime,
  permissions: state.common.permissions,
  currentUser: state.common.currentUser,
  ...ownProps,
});

const mapDispatchToProps = (dispatch) => ({
  loadPending: (payload) => dispatch({ type: WAITING_ROOM_LOAD_PENDING, payload }),
  loadAvailable: (payload) => dispatch({ type: WAITING_ROOM_LOAD_AVAILABLE, payload }),
});

class PatientPoller extends React.Component {
  constructor(props) {
    super(props);
    this.state = { nextPollTime: null };
  }

  poll() {
    const nextTime = new Date().getTime() + this.props.pollInterval;
    this.setState({ nextTime: nextTime });
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'loadPending' does not exist on type 'Rea... Remove this comment to see the full error message
    try{
      this.props.loadPending(api.Encounters.pending());
    }
    catch(e: any){
     errorLogger(e)
    }
    
    try{
      this.props.loadAvailable(api.Encounters.available());
    }
    catch(e: any){
      errorLogger(e)
    }
    
  }

  componentDidUpdate(prevProps) {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'prevProps' implicitly has an 'any' type... Remove this comment to see the full error message
    const canSeePatients = hasAnyPermission(this.props.permissions, PERMISSION_SEE_PATIENTS, PERMISSION_THERAPIST);
    if (
      canSeePatients &&
      this.props.pollInterval &&
      !this.state.nextTime &&
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'pollInterval' does not exist on type 'Re... Remove this comment to see the full error message
      this.props.currentUser
    ) {
      const nextTime = new Date().getTime() + this.props.pollInterval;
      this.setState({ nextTime: nextTime });
    }

    if (
      canSeePatients &&
      this.props.currentUser &&
      this.state.nextTime &&
      this.props.currentTime > this.state.nextTime
    ) {
      this.poll();
    }
  }

  render() {
    return null;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PatientPoller);
