import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Table, Button, Input } from "reactstrap";
import moment from "moment";

import "./UnsignedNotes.scss";
import EditIcon from "../../images/SignNote.svg";
import api from "../../api";
import {
  NAVIGATE_TO,
  ENCOUNTER_SAVED,
  ENCOUNTER_EXPLICIT_RESUME,
  ENCOUNTER_LOAD_UNSIGNED,
} from "../../constants/actionTypes";
import StrikeOutNote from "../StrikeOutNote";
import DeleteBin from "../../images/DeleteIcon.svg";
import {
  IAllProviderSavedEncounters,
  IProviderSavedEncounter,
  ISerializedItemKey,
  IScribeCompleteEncounter,
} from "../../constants/Types";
import ShareIcon from "../../images/ShareButton.svg";
import ForwardVisitModal from "./ForwardModal";
import bugsnagClient from "../../services/bugsnag";
import { toast } from "react-toastify";
import { errorLogger } from "../../utils";
import Chat from "../../images/ChatDots.svg"
import Video from "../../images/VideoCamera.svg"
import Pencil from "../../images/PencilWhite.svg"
import WhiteX from "../../images/WhiteX.svg"
import Check from "../../images/Check.svg"
import { Prompt } from "react-router"

const mapStateToProps = (state, ownProps) => ({
  unsignedEncountersList: state.unsignedEncounters.unsignedEncountersList,
});

const mapDispatchToProps = (dispatch) => ({
  enterWaitingRoom: () => dispatch({ type: NAVIGATE_TO, targetPath: "/waiting-room" }),
  encounterResume: () => dispatch({ type: ENCOUNTER_EXPLICIT_RESUME }),
  loadUnsignedEncounters: () =>
    dispatch({ type: ENCOUNTER_LOAD_UNSIGNED, payload: api.Encounters.unsignedEncounters() }),
});

type UnsignedNotesProps = {
  enterWaitingRoom: Function;
  savedEncounter: Function;
  setExplicitResume: Function;
  loadUnsignedEncounters: Function;
  isScribe: boolean;
  unsignedEncountersList: any;
  encounterResume: Function;
};
const getDate = (dt) => moment(dt).format("MM/DD/YYYY");
const getTime = (dt) => moment(dt).format("hh:mm A");

type Props = {
  item: IProviderSavedEncounter;
  onDeleteEnc: () => void;
  signNote: (id: ISerializedItemKey) => void;
  isScribe: boolean;
  forwardVisit: () => void;
  editingMessage: boolean;
  setEditingMessage: (value: boolean) => void;
  editedMessage: string
  setEditedMessage: (value: string) => void
  handleSaveSticky: () => void
  setRefID: (value: string | null) => void
  refID: string | null
  error: boolean;
};

const TableRow: FC<Props> = ({ item, onDeleteEnc, isScribe, forwardVisit, editingMessage, setEditingMessage, editedMessage, setEditedMessage, handleSaveSticky, setRefID, refID, error, ...props }) => {

  const dateTime = item.EncounterKey.Kind;
  const asyncVisit = item.asyncChat;
  const visitType = item.visitType;
  const showMessageStatus = item.asyncChat;
  const isReassigned = item.reassigned;
  const state = item.state
  const rowRefID = `${item.EncounterKey.ID}|${item.EncounterKey.Kind}`
  const originalMessage = item.text ? item.text : "";
  const sex = item?.sex === "F" ? "Female" : item?.sex === "M" ? "Male" : ""
  const subText = visitType && sex && state ? `${visitType} - ${sex} - ${state}` : !visitType && sex && state ? `${sex} - ${state}` : visitType && !sex && state ? `${visitType} - ${state}` : visitType && sex && !state ? `${visitType} - ${sex}` : ""
  const phoneNumber = item?.phone ? item.phone : ""

  const handleEditSticky = () => {
    setEditingMessage(true)
    setRefID(rowRefID)
    setEditedMessage(originalMessage)
  }
  const cancelEdit = () => {
    setEditingMessage(false)
    setEditedMessage("")
    setRefID(null)
    setEditedMessage(originalMessage)
  }
  const handleTextInput = (e) => {

    setEditedMessage(e.target.value)
  }

  const StatusMessage = () => {
    if (item.scribeComplete) {
      return (
        <div className="d-flex flex-column align-items-center">
          <span className="new-messages">Scribe Complete</span>
          <span className="message-time">
            {"New Scribe Draft Received: "}
            {moment(item.scribeCompleteAt!).isValid()
              ? moment(item.scribeCompleteAt!).fromNow()
              : "None"}
          </span>
        </div>
      );
    }

    if (isReassigned) {
      return (
        <div className="d-flex flex-column align-items-center">
          <span className="new-messages">Assigned to you</span>
          <span className="message-time">
            {"Draft Note Received: "}
            {moment(item.reassignedAt!).isValid() ? moment(item.reassignedAt!).fromNow() : " None"}
          </span>
        </div>
      );
    }

    if (showMessageStatus) {
      return (
        <div className="d-flex flex-column align-items-center">
          <span className={item.newMessages ? "new-messages" : "no-new-messages"}>
            {item.newMessages ? "New Message" : "No New Messages"}
          </span>
          <span className="message-time">
            {"Last provider message sent: "}
            {moment(item.lastProviderMessage!).isValid() ? moment(item.lastProviderMessage!).fromNow() : "None"}
          </span>
          <span className="message-time">
            {"Last patient message sent: "}
            {moment(item.lastPatientMessage!).isValid() ? moment(item.lastPatientMessage!).fromNow() : "None"}
          </span>
        </div>
      );
    }
  };

  return (
    <tr>
      <td width="10%">
        <div className="d-flex flex-column">
          <span className="date">{getDate(dateTime)}</span>
          <span className="time">{getTime(dateTime)}</span>
        </div>
      </td>
      <td width="10%">
        <div className="d-flex flex-column ">
          <span className="name">{item.userName}</span>
          <span className="visit-type">{item.chiefComplaint}</span>
          <span className="visit-type">{subText}</span>
          <span className="visit-type">{phoneNumber}</span>
        </div>
      </td>
      <td width="5%">
        {item.asyncChat ? <Chat /> : <Video />}
      </td>
      <td width="10%">{StatusMessage()}</td>
      <td width="40%">
        <div className={error && refID === rowRefID ? "stickyNote container-error" : "stickyNote sticky-container"}>
          <textarea
            className="stickyNote-input p-2"
            placeholder="Internal Sticky Note (Not visible to Patient nor part of Provider note)"
            disabled={refID !== rowRefID}
            onChange={handleTextInput}
            value={refID === rowRefID ? editedMessage : originalMessage}
          />
          {refID === rowRefID ?
            <div className="d-flex flex-column editingBtn-group">
              <button type="button" className="sticky-btn stickyNote-ExitEdit mb-1" onClick={cancelEdit}><WhiteX className="xSVG" /></button>
              <button type="button" className="sticky-btn stickyNote-SaveEdit" onClick={handleSaveSticky}><Check className="checkSVG" /></button>
            </div>
            :
            <button className="sticky-btn stickyNote-editBtn" onClick={() => handleEditSticky()} ><Pencil /></button>}
        </div>
      </td>
      <td width="15%">
        <div className="d-flex justify-content-around">
          <Button
            className="sign-btn d-flex align-items-center mr-1"
            onClick={() => props.signNote(item.EncounterKey)}
          >
            <EditIcon />
            <span className="title">{isScribe ? "Finish Note" : "Sign Note"}</span>
          </Button>
          {!asyncVisit ? (
            <button onClick={onDeleteEnc} className="delete-btn">
              <DeleteBin />
            </button>
          ) : (
            <span className="mr-3" />
          )}
        </div>
      </td>
      <td width="10%">
        <button onClick={forwardVisit} className="share-btn">
          <ShareIcon />
        </button>
      </td>
    </tr>
  );
};

type ScribeTableProps = {
  scribeCompleteItem: IScribeCompleteEncounter;
  key: string;
};

const ScribeTableRow: FC<ScribeTableProps> = ({ key, scribeCompleteItem }) => {
  const dateTime = scribeCompleteItem?.scribeCompleteAt;
  const chiefComplaint = scribeCompleteItem?.chiefComplaint;
  const assignedTo = scribeCompleteItem?.scribeAssignedToName;
  const patientName = scribeCompleteItem?.userName;

  return (
    <tr key={key}>
      <td>
        <div className="d-flex flex-column">
          <span className="date">{getDate(dateTime)}</span>
          <span className="time">{getTime(dateTime)}</span>
        </div>
      </td>
      <td>
        <div className="d-flex flex-column ">
          <span className="name">{patientName}</span>
          <span className="visit-type">{chiefComplaint}</span>
        </div>
      </td>
      <td>
        <div className="d-flex flex-column ">
          <span className="name">{assignedTo}</span>
          <span className="subText">Assigned Provider</span>
        </div>
      </td>
    </tr>
  );
};

const UnsignedNotes = (props: UnsignedNotesProps) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [encounterKey, setEncounterKey] = useState({});
  const [forwardModal, setForwardModal] = useState(false);
  const [provider, setProvider] = useState("");
  const [user, setSelectedUser] = useState<IProviderSavedEncounter>();
  const [editingMessage, setEditingMessage] = useState(false)
  const [editedMessage, setEditedMessage] = useState<string>("")
  const [refID, setRefID] = useState<string | null>(null)
  const [error, setError] = useState(false)

  const extractUnsignedNotes = ({
    oneDay = [],
    twoDays = [],
    overdue = [],
  }: IAllProviderSavedEncounters) => {
    const items: IProviderSavedEncounter[] = [];
    if (oneDay) {
      items.push(...oneDay);
    }
    if (twoDays) {
      items.push(...twoDays);
    }
    if (overdue) {
      items.push(...overdue);
    }

    return items;
  };

  const fetchUsignedNotes = () => {
    // const response = await api.Encounters.unsignedEncounters();
    props.loadUnsignedEncounters();
    setError(false)
  };

  const onDeleteEnc = async () => {
    await api.Encounters.deleteEncounter(encounterKey);
    setDeleteModal(false);
    fetchUsignedNotes();
  };
  const showModal = (encounterKey) => {
    setEncounterKey(encounterKey);
    setDeleteModal(true);
  };

  const signNote = async (EncounterKey) => {
    try {
      await api.Encounters.resumeSavedEncounter(EncounterKey).then(() => {
        props.encounterResume();
        props.enterWaitingRoom();
      });
    } catch (e: any) {
      const status = e.response?.status;
      if (status === 422) {
        errorLogger(e)
      }
    }
  };

  const showForwardModal = (item) => {
    setEncounterKey(item.EncounterKey);
    setForwardModal(true);
    setSelectedUser(item);
  };

  const assignEncounter = async (referenceID) => {
    try {
      await api.Encounters.assign(referenceID, provider!);
      fetchUsignedNotes();
      setForwardModal(false);
    } catch (error: any) {
      const status = error.response?.status;
      if (status === 422) {
        errorLogger(error)
      }
    }
  };

  const handleSelect = (provider: { target: { value: string } }) => {
    let providerID = provider?.target?.value;
    setProvider(providerID);
  };

  const handleSaveSticky = async () => {
    try {
      if (refID) {
        await api.Encounters.saveProviderStickyNote(refID, editedMessage)
        setEditingMessage(false)
        setEditedMessage("")
        setRefID(null)
        fetchUsignedNotes()
      }
    } catch (err) {
      setError(true)
    }
  }

  useEffect(() => {
    fetchUsignedNotes();
  }, []);

  const unsignedNotesList: IProviderSavedEncounter[] = extractUnsignedNotes(
    props.unsignedEncountersList,
  );
  const scribeCompleteList: IScribeCompleteEncounter[] =
    props?.unsignedEncountersList?.scribeComplete;
  if (unsignedNotesList.length > 0) {
    return (
      <>
        <Prompt
          when={editingMessage}
          message="Are you sure you want to leave this page? You are in the middle of editing."
        />
        <div className=" dashboard-component unsigned-notes-container ">
          <StrikeOutNote
            visible={deleteModal}
            onCancel={() => setDeleteModal(false)}
            onDelete={onDeleteEnc}
          />
          <ForwardVisitModal
            visible={forwardModal}
            onCancel={() => setForwardModal(false)}
            onAssign={assignEncounter}
            encounterID={encounterKey}
            handleSelect={handleSelect}
            selected={provider}
            state={user?.state || ""}
          />
          <h5>Incomplete Patient Notes </h5>
          <Table className="notes-table">
            <tbody>
              {unsignedNotesList.map((i, idx) => (
                <TableRow
                  key={`row-${idx}`}
                  item={i}
                  signNote={signNote}
                  onDeleteEnc={() => showModal(i.EncounterKey)}
                  isScribe={props.isScribe}
                  forwardVisit={() => showForwardModal(i)}
                  editedMessage={editedMessage}
                  setEditedMessage={setEditedMessage}
                  refID={refID}
                  setRefID={setRefID}
                  editingMessage={editingMessage}
                  setEditingMessage={setEditingMessage}
                  handleSaveSticky={handleSaveSticky}
                  error={error}
                />
              ))}
            </tbody>
          </Table>
        </div>
        {!!props.isScribe && !!scribeCompleteList && (
          <div className=" dashboard-component unsigned-notes-container">
            <h5>Scribe Complete</h5>
            <Table className="scribe-notes-table">
              {scribeCompleteList?.map((item, idx) => (
                <ScribeTableRow key={idx} scribeCompleteItem={item} />
              ))}
            </Table>
          </div>
        )}
      </>
    );
  }

  return null;
};

export default connect(mapStateToProps, mapDispatchToProps)(UnsignedNotes);
