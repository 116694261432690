import React, { useEffect, useState } from "react";
import { Table, Button } from "reactstrap";
import Octicons, { Book } from "@githubprimer/octicons-react";
import "../../stylesheets/emrNotifications.scss"
import api from "../../api";
import moment from "moment-timezone";
import { IEMRNotificationsItem } from "../../constants/Types";


const Bubble = ({ count }) => {
    if (!count) {
        return null;
    }
    return (
        <div className="exclamation-bubble" style={{ backgroundColor: "#23c552c7", right: 10, color: "#00000" }}>
            {count}
        </div>
    )
}

const Notification = ({ item }) => {
    const { text, UpdatedAt, link } = item;
    const handleClick = () => {
        window.open(link, "_blank");
    }

    const readClass = moment(UpdatedAt).format("MM/DD/YY") === moment().format("MM/DD/YY") ? "unread" : "";

    if (!text && !link.length) {
        return null;
    }
    const validLink = link.length > 0 && (link.includes("http") || link.includes("https"))

    return (
        <td className={readClass}>
            <div className="notification-div d-flex justify-content-between emrNotificationsDiv">
                <div className="d-flex flex-column">
                    <h6 className="notification-title">{text}</h6>
                    <span className="notification-date">{moment(UpdatedAt).format("MM/DD/YY")}</span>
                </div>

                <div className="ml-4">
                    {validLink && <Button className="notification-button" onClick={handleClick}>
                        <Octicons icon={Book} size={24} />
                    </Button>}
                </div>

            </div>
        </td>
    )
}

const EMRNotifications = ({ }) => {

    const [messages, setMessages] = useState<IEMRNotificationsItem[]>([]);
    const [count, setCount] = useState(0);

    useEffect(() => {
        loadMessages();
    }, [])


    const loadMessages = async () => {
        try {
            let response = await api.EMRNotifications.get();
            let items = response.items || [];
            setMessages(items);
            let co = items.length > 0 ? items.filter(item => moment(item.UpdatedAt).format("MM/DD/YY") === moment().format("MM/DD/YY") && item.text).length : 0;
            setCount(co);
        } catch (error) {
            console.log(error)
        }

    }

    // are there messages to display?
    const newMessages = messages.some(item => item.text);

    if (!messages.length) {
        return null
    }
    return (
        <div>
            <div className="dashboard-component updates-component">
                <h5> Software Updates <Bubble count={count} />  </h5>
                {messages.length === 0 && !newMessages ?
                    <div className="no-messages">No New Updates!</div>
                    :
                    <div className="emr-updates-container">
                        <Table>
                            <tbody>
                                {messages.map((item, index) => (
                                    <tr key={index} className="emrNotifications-tr">
                                        <Notification item={item} />
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                }

            </div>
        </div>
    )
}

export default EMRNotifications;