import React, { FC, useEffect, useState } from "react";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import Select from "react-select";
import { Input, Button } from "reactstrap";
import RichTextEditor from "../RichTextEditor";
import ContentBlock from "./ContentBlock";
import api from "../../api";
import { isPCCUser } from "../../constants/Providers";
import {
  generateSickNote,
  DiagnosisCode,
  PrescriptionDetails,
  InputError,
  ContentBlockBilling,
  ContentBlockTreatmentPlans,
  selectItemToString,
} from "./Diagnosis";
import {
  IAssignableProviderItem,
  ICurrentUser,
  IltcLinkMappings,
  IMedicalProfile,
  IProviderNote,
  ITestDetails,
  IUserDetails,
  IUserMedicalIntake,
  ITherapyMappings,
} from "../../constants/Types";

import { formatPhoneNumber } from "../../utils";
import { hasPermission, PERMISSION_SEE_PATIENTS } from "../../constants/Permissions";

type Props = {
  currentUser?: ICurrentUser | null;
  prescriptionError: string;
  onDateRangePick: (startDate: string, endDate: string) => void;
  chiefComplaint: string;
  note: Partial<IProviderNote>;
  profile: Partial<IMedicalProfile>;
  testResult?: ITestDetails;
  visitType: string;
  patient: IUserDetails;
  encounterID: string;
  intake: IUserMedicalIntake;
  noteLoaded?: boolean;
  onUpdate: (field: string, value: any) => void;
  onIntakeSave: (parts?: string[] | undefined) => void;
  onPrescriptionLink: () => void;
  isExistingUser?: boolean;
  isNursingHomeSite: boolean;
  missingDiagnosisFields: object;
  effectiveDate: string;
  onEffectiveDateChange: (date: any) => void;
  ltcLinkMappings: IltcLinkMappings;
  therapyCounselingMapping: ITherapyMappings;
  myref: React.RefObject<HTMLDivElement>;
  onSelectProvider: (provider: IAssignableProviderItem) => void;
  referenceID?: string | null;
  selectedProvider?: Partial<IAssignableProviderItem>;
  isScribe: boolean;
};

type Condition = {
  value: string;
  label: string;
};

const BlankVisit: FC<Props> = (props) => {
  const {
    encounterID,
    chiefComplaint = "",
    note = {},
    missingDiagnosisFields,
    prescriptionError,
    ltcLinkMappings,
    therapyCounselingMapping,
    visitType,
  } = props;

  const { diagnosis } = note;

  const [selectedVisitType, setSelectedVisitType] = useState<string>("");
  const [guidelineUrl, setGuidelineUrl] = useState<string>("");
  const [activeConditions, setActiveConditions] = useState<Condition[]>([]);
  const [templateForNotes, setTemplateForNotes] = useState<string | null>(null);

  const providerName = props.currentUser
    ? `${props.currentUser.firstName} ${props.currentUser.lastName}`
    : "";
  const patientName = props.patient.name;
  const assessment = note.assessment || "";

  const pharmacyID = props?.profile?.preferredPharmacy?.pharmacyID;
  const pharmacyName = props?.profile?.preferredPharmacy?.storeName;
  const pharmacyAddress = props?.profile?.preferredPharmacy?.address1;
  const pharmacyIndex = props?.profile?.preferredPharmacy?.zip;
  const pharmacyState = props?.profile?.preferredPharmacy?.state;
  const pharmacyCity = props?.profile?.preferredPharmacy?.city;
  const pharmacyPhone = formatPhoneNumber(props?.profile?.preferredPharmacy?.primaryPhone);

  useEffect(() => {
    const sickNote = generateSickNote(patientName, providerName);
    props.onUpdate("sickNote", sickNote);

    //get patientConditions
    async function fetchConditions() {
      const patientID = props.patient.userID;
      const results = await api.PCC.conditions(patientID);
      const conditions = (results.conditions || []).reduce(
        (arr: Condition[], { icd10, icd10Description }) => {
          return [
            ...arr,
            { value: `${icd10} ${icd10Description}`, label: `${icd10} ${icd10Description}` },
          ];
        },
        [],
      );

      setActiveConditions(conditions);
    }

    if (isPCCUser(props.currentUser)) {
      // only fetch conditions if a PCC is active
      fetchConditions();
    }
  }, []);

  const getTemplate = async (type) => {
    // need endpoint to get counseling Templates
    const templateSource = visitType === "blank_ltc" ? api.Encounters.getBlankLTCTemplates : api.Encounters.getTherapyCounselingTemplate
    try {
      const visitTemplate = await templateSource(encodeURIComponent(type));
      setTemplateForNotes(visitTemplate.template);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    setSelectedVisitType(chiefComplaint);
    if (chiefComplaint) {
      getTemplate(chiefComplaint);
    }

    // find the matching entry, if any
    const option = linkOptions.find((option) => option.label === chiefComplaint);
    !!option && setGuidelineUrl(option.value);
  }, [chiefComplaint]);

  const handleDiagnosisChange = (selectedOptions) => {
    if (selectedOptions.length < 1) {
      props.onUpdate("diagnosis", null);
    } else {
      props.onUpdate("diagnosis", selectedOptions.map(selectItemToString));
    }
  };

  const handleChange = (event) => {
    props.onUpdate(event.target.name, event.target.value);
  };
  const handleNoteChange = (value) => {
    props.onUpdate("assessment", value);
  };

  const handleSaveIntake =
    (...fields) =>
      () => {
        !!props.onIntakeSave && props.onIntakeSave(fields);
      };

  const handleVisitTypeChange = (selected) => {
    setSelectedVisitType(selected.label);
    setGuidelineUrl(selected.value);

    // immediately update the chief complaint in the medical intake
    props.intake.chiefComplaint = {
      complaint: selected.label,
    };
    handleSaveIntake("chiefComplaint")();
    handleChange({ target: { name: "chiefComplaint", value: selected.label } });

    // update the encounter with the chief complaint too
    api.Encounters.updateChiefComplaint({ chiefComplaint: selected.label }, encounterID);
  };

  const onInsertTemplate = () => {
    props.onUpdate("assessment", templateForNotes);
  };

  const links = visitType === "blank_ltc" ? ltcLinkMappings.templates || [] : therapyCounselingMapping.templates || [];
  const linkOptions = links
    .sort((a, b) => a.visitName.localeCompare(b.visitName))
    .map((key) => ({ label: key.visitName, value: key.visitName }));
  const selectedLinkOption = linkOptions.find((option) => option.label === selectedVisitType);
  return (
    <div className="patient-diagnosis">
      <ContentBlock title="Visit Type">
        <Select
          styles={{
            // Fixes the overlapping problem of the component
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
          inputId={"em-select"}
          isSearchable={false}
          className="info-select w-50"
          placeholder="Select Visit Type"
          options={linkOptions}
          value={selectedLinkOption}
          onChange={handleVisitTypeChange}
          id="qa-blankvisit-type-drp"
        />
      </ContentBlock>
      <ContentBlock
        title="Visit Notes"
        render={() => (
          <Button
            color="primary"
            outline
            className="template-button"
            disabled={!templateForNotes}
            onClick={onInsertTemplate}
          >
            Insert Template Text
          </Button>
        )}
      >
        <RichTextEditor content={assessment} onUpdate={handleNoteChange} />
      </ContentBlock>
      {missingDiagnosisFields["diagnosis"] && <div ref={props.myref} />}
      <ContentBlock
        title="Diagnosis"
        render={() => (
          <InputError
            message="*Please select at least one diagnosis code for this encounter before signing"
            flag={missingDiagnosisFields["diagnosis"] || diagnosis === null}
          />
        )}
      >
        <DiagnosisCode
          diagnosis={diagnosis}
          activeConditions={activeConditions}
          handleSelectChange={handleDiagnosisChange}
          visitType={props.visitType}
          allergySystems={props.intake.allergySystems}
          placeholder="Start typing diagnosis or ICD 10 code..."
          diagnosisLimit={5}
        />
      </ContentBlock>

      {hasPermission(props.currentUser?.permissions, PERMISSION_SEE_PATIENTS) && <ContentBlock title="Prescription">
        <p style={{ fontSize: 12 }}>
          <span>Patient-Selected Pharmacy - </span>
          {!!pharmacyID
            ? `${pharmacyName} - ${pharmacyID} ${pharmacyAddress}, ${pharmacyCity}, ${pharmacyState}, ${pharmacyIndex} - ${pharmacyPhone}`
            : "None"}
        </p>
        <Button
          id="qa-encounter-writerx-btn"
          className="prescribe-button"
          onClick={props.onPrescriptionLink}
          color="primary"
          outline
        >
          Write Prescription
        </Button>
        {prescriptionError && (
          <p className="prescriptionError">DoseSpot Error: {prescriptionError}</p>
        )}
        <PrescriptionDetails details={note.prescriptionDetails} pharmacies={note.pharmacyDetails} />
      </ContentBlock>}

      <ContentBlockBilling
        isScribe={props.isScribe}
        referenceID={props.referenceID}
        onSelectProvider={props.onSelectProvider}
        selectedProvider={props.selectedProvider}
        visitType={props.visitType}
        missingDiagnosisFields={missingDiagnosisFields}
        note={note}
        effectiveDate={props.effectiveDate}
        onUpdate={props.onUpdate}
        onEffectiveDateChange={props.onEffectiveDateChange}
      />
      {props.visitType !== "therapy_counseling" && missingDiagnosisFields["emCode"] && !missingDiagnosisFields["diagnosis"] && (
        <div ref={props.myref} />
      )}
    </div>
  );
};

export type TemplateOption = {
  label: string;
  url: string;
};

export default BlankVisit;
