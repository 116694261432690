import React, { useEffect, useState, FC } from "react";
import { Input, Row, Col } from "reactstrap";

import ContentBlock from "./ContentBlock";
import RevertIcon from "../../images/revert-icon.svg";
import CloseIcon from "../../images/close-icon.svg";
import api from "../../api";
import ScribeSection from "./ScribeSection";
import { IAssignableProviderItem, IProviderNote, IUserDetails, IUserMedicalIntake, UserSymptomDetailsKeys } from "../../constants/Types";

function isEmpty(obj) {
  for (let key in obj) {
    return false;
  }
  return true;
}

function getDosageRange(dsRange) {
  if (dsRange.indexOf("-") > -1) {
    return dsRange.split("-");
  }
  return dsRange.split("+");
}

const getMedications = (patientAge, medications) => {
  const patientMedications = (medications || []).filter((medication) => {
    const dosageRange = getDosageRange(medication.dosageRange).filter(Boolean);

    if (dosageRange.length > 1) {
      //must be of length 2 !!!
      return (
        patientAge >= parseInt(dosageRange[0], 10) && patientAge <= parseInt(dosageRange[1], 10)
      );
    }
    return patientAge >= parseInt(dosageRange[0], 10);
  });

  return patientMedications;
};

const IconButton = ({ Icon, children, onClick }) => {
  return (
    <button className="note-input-btn" onClick={onClick}>
      <Icon className="icon" />
      {children}
    </button>
  );
};

const NoteInput = ({
  name,
  note,
  includeNote,
  initialNote,
  placeholder,
  ...props
}) => {
  useEffect(() => {
    if (!note) {
      props.onChange(name, initialNote);
    }
  }, [initialNote]);

  const revertText = (e) => {
    if (!includeNote) {
      props.removeFromPlan();
    }
    props.onChange(name, initialNote);
  };

  const removeFromPlan = () => {
    props.removeFromPlan();
  };

  const noteChange = (e) => {
    const { value } = e.target;
    props.onChange(name, value);
  };

  return (
    <div className="note-input">
      {includeNote && (
        <Input
          value={note}
          type="textarea"
          placeholder={placeholder || ""}
          name={name}
          onChange={noteChange}
        />
      )}
      <div className="note-input-btns">
        <IconButton Icon={RevertIcon} onClick={revertText}>
          Revert Text
        </IconButton>
        {includeNote && (
          <IconButton Icon={CloseIcon} onClick={removeFromPlan}>
            Remove from Plan
          </IconButton>
        )}
      </div>
    </div>
  );
};

const SupportiveCare = ({ note, intialNote, includeNote, ...props }) => {
  const onChange = (name, value) => {
    props.onSupportiveCareChange(value);
  };

  return (
    <div className="supportive-care">
      <h1 className="header">Supportive Care</h1>
      <NoteInput
        initialNote={intialNote}
        removeFromPlan={props.removeSupportiveCareFromPlan}
        note={note}
        includeNote={includeNote}
        onChange={onChange}
      />
    </div>
  );
};

const NewMedication = ({ medication, ...props }) => {
  const onInputChange = (field) => (e) => {
    const { value } = e.target;
    const updatedmedication = {
      ...medication,
      [field]: value,
    };

    props.onInputChange(updatedmedication, medication);
  };

  const onNoteChange = (field) => (_, value) => {
    const updatedmedication = { ...medication, [field]: value };

    props.onInputChange(updatedmedication, medication);
  };

  const removeFromPlan = () => {
    props.removeFromPlan(medication);
  };
  return (
    <div className="new-medication">
      <Row>
        <Col size={6}>
          <div className="new-medication-inputs">
            <input
              type="text"
              value={medication.name}
              className="name"
              placeholder="Medication name"
              onChange={onInputChange("name")}
            />
            <input
              type="text"
              value={medication.dosage}
              className="dosage"
              placeholder="Dosage"
              onChange={onInputChange("dosage")}
            />
          </div>
        </Col>
        <Col size={6}>
          <NoteInput
            name="Other"
            removeFromPlan={removeFromPlan}
            placeholder="Enter patient instructions"
            includeNote={true}
            note={medication.instructions || ""}
            initialNote={""}
            onChange={onNoteChange("instructions")}
          />
        </Col>
      </Row>
    </div>
  );
};

const getSymptomKeys = (sp) => {
  return sp.key;
};

const getSymptoms = (catalog, symptomsKeys) => {
  return catalog.filter(({ symptomKey }) => symptomsKeys.includes(symptomKey));
};

const SymptomBlock = (props) => {
  const sp = props.symptom;
  // const patient = props.patient;
  const symptomSupportiveCare = props.symptomSupportiveCare || {};
  const symptomKey = sp.symptomKey;
  const includeNote = symptomSupportiveCare[symptomKey] !== undefined ? true : false;
  const supportiveCareText = sp.supportiveCareText || "";

  const onChange = (value) => {
    props.onSupportiveCareChange(sp.symptomKey, value);
  };

  const removeSupportiveCareFromPlan = () => {
    props.removeSupportiveCareFromPlan(sp.symptomKey);
  };

  return (
    <ContentBlock title={sp.symptom}>
      <SupportiveCare
        intialNote={supportiveCareText}
        removeSupportiveCareFromPlan={removeSupportiveCareFromPlan}
        includeNote={includeNote}
        note={symptomSupportiveCare[symptomKey] || ""}
        onSupportiveCareChange={onChange}
      />
    </ContentBlock>
  );
};

const PrescriptionDetails = ({ pd }) => {
  return (
    <ul className="prescribed-items">
      {pd.map((v, idx) => (
        <li key={idx}>
          {v.name}
          {v.strength ? `, ${v.strength}` : ""}: {v.directions}
        </li>
      ))}
    </ul>
  );
};
const getAllergySymptoms = (intake) => {
  let sp = [];

  if (intake.allergyEyesSymptoms.symptoms) {
    sp = sp.concat(intake.allergyEyesSymptoms.symptoms);
  }

  if (intake.allergyNoseSymptoms.symptoms) {
    sp = sp.concat(intake.allergyNoseSymptoms.symptoms);
  }

  if (intake.allergyLungsSymptoms.symptoms) {
    sp = sp.concat(intake.allergyLungsSymptoms.symptoms);
  }

  if (intake.allergySkinSymptoms.symptoms) {
    sp = sp.concat(intake.allergySkinSymptoms.symptoms);
  }

  return sp;
};
const getCurrentSymptoms = (intake) => {
  let symptoms = [];
  if (intake) {
    const visitType = intake.visitType;
    if (visitType === "rash_and_allergy") {
      symptoms = getAllergySymptoms(intake);
    } else {
      symptoms = intake.currentSymptoms.symptoms;
    }
  }

  return symptoms || [];
};

type TreatmentPlanProps = {
  note: Partial<IProviderNote>;
  visitType: string;
  currentSymptoms: UserSymptomDetailsKeys;
  patient: IUserDetails;
  intake: IUserMedicalIntake;
  onUpdate: (field: string, value: any) => void;
  referenceID?: string;
  onSelectProvider: (provider: IAssignableProviderItem) => void;
  selectedProvider?: Partial<IAssignableProviderItem>;
  isScribe: boolean;
}
const TreatmentPlan: FC<TreatmentPlanProps> = (props) => {
  const note = props.note;
  const [ICD10CatalogTX, setICD10CatalogTX] = useState({});
  const [symptomSuppotiveCare, setSymptomSuppotiveCare] = useState([]);
  const patient = props.patient;
  const prescriptionDetails = note.prescriptionDetails || [];
  const selectedICD10 = props.note.diagnosis || [];
  const symptomsKeys = (getCurrentSymptoms(props.intake) || []).map(getSymptomKeys);
  const currentSymptoms = getSymptoms(symptomSuppotiveCare, symptomsKeys);
  const detailedTreatmentPlan = props.note.detailedTreatmentPlan || {};
  const isScribe = props.isScribe;
  useEffect(() => {
    loadCatalogs();
  }, []);

  const loadCatalogs = async () => {
    let ICD10Catalog = {};
    const ICD10Codes = selectedICD10.map((item) => item.split(" ")[0]);

    ICD10Codes.forEach(async (code) => {
      const result = await api.Catalog.icd10TX({ icd10: code });
      ICD10Catalog[code] = result;
      setICD10CatalogTX(ICD10Catalog);
    });

    await loadSupportiveCareCatalog();
  };

  const loadAllergyCatalogs = async () => {
    let result = [];
    let response = {};
    const intake = props.intake;

    if (intake?.allergyEyesSymptoms?.symptoms) {
      response = await api.Catalog.symptomSupportiveCare({
        visitType: "allergy-eye",
      });
      result = result.concat(response?.supportiveCare);
    }

    if (intake.allergyNoseSymptoms.symptoms) {
      response = await api.Catalog.symptomSupportiveCare({
        visitType: "allergy-nose",
      });
      result = result.concat(response?.supportiveCare);
    }

    if (intake.allergyLungsSymptoms.symptoms) {
      response = await api.Catalog.symptomSupportiveCare({
        visitType: "allergy-lungs",
      });
      result = result.concat(response?.supportiveCare);
    }

    if (intake.allergySkinSymptoms.symptoms) {
      response = await api.Catalog.symptomSupportiveCare({
        visitType: "allergy-skin",
      });
      result = result.concat(response?.supportiveCare);
    }

    return result;
  };

  const loadSupportiveCareCatalog = async () => {
    const intake = props.intake;

    let result = [];
    if (intake.visitType !== "rash_and_allergy") {
      try {
        const response = await api.Catalog.symptomSupportiveCare({
          visitType: intake.visitType,
        });
        result = result.concat(response?.supportiveCare);
      } catch (e) {
      }
    } else {
      result = await loadAllergyCatalogs();
    }
    setSymptomSuppotiveCare(result);
  };
  const onSupportiveCareChange = (symptomKey, value) => {
    if (!detailedTreatmentPlan["symptomSupportiveCare"]) {
      detailedTreatmentPlan["symptomSupportiveCare"] = {};
    }

    detailedTreatmentPlan["symptomSupportiveCare"][symptomKey] = value;
    props.onUpdate("detailedTreatmentPlan", detailedTreatmentPlan);
  };

  const getinitialNote = (field) => {
    let note = "";
    if (!isEmpty(ICD10CatalogTX) && selectedICD10.length > 0) {
      selectedICD10.forEach((item) => {
        const code = item.split(" ")[0];
        const text = ICD10CatalogTX[code] ? ICD10CatalogTX[code][field] : "";
        note += !!text ? text + "\n " : text;
      });
    }
    return note;
  };

  const removeFromPlan = (field) => () => {
    const value = detailedTreatmentPlan[field];
    detailedTreatmentPlan[field] = !value;
    props.onUpdate("detailedTreatmentPlan", detailedTreatmentPlan);
  };

  const handleOnChange = (field, value) => {
    detailedTreatmentPlan[field] = value;
    props.onUpdate("detailedTreatmentPlan", detailedTreatmentPlan);
  };

  const onAdditionalNoteChange = (field) => (e) => {
    const { value } = e.target;
    detailedTreatmentPlan[field] = value;
    props.onUpdate("detailedTreatmentPlan", detailedTreatmentPlan);
  };

  const removeSupportiveCareFromPlan = (symptomKey) => {
    delete detailedTreatmentPlan["symptomSupportiveCare"][symptomKey];
    props.onUpdate("detailedTreatmentPlan", detailedTreatmentPlan);
  };

  return (
    <div className="patient-treatment-plan">
      <ContentBlock title="Diagnosis">
        <>
          {selectedICD10.map((item) => {
            return <h1 className="header">{item}</h1>;
          })}
        </>
        <NoteInput
          name="information"
          note={detailedTreatmentPlan["information"]}
          includeNote={!detailedTreatmentPlan["includeInformation"]}
          onChange={handleOnChange}
          selectedICD10={selectedICD10}
          initialNote={getinitialNote("information")}
          removeFromPlan={removeFromPlan("includeInformation")}
          placeholder="Enter diagnosis information"
        />
      </ContentBlock>
      <ContentBlock title="Prescription">
        <Row>
          <Col size={6}>
            <PrescriptionDetails pd={prescriptionDetails} />
          </Col>

          <Col size={6}>
            <Input
              type="textarea"
              note={detailedTreatmentPlan["additionalPrescriptionNote"]}
              placeholder="Any additional prescription notes"
              onChange={onAdditionalNoteChange("additionalPrescriptionNote")}
              className="prescription-note"
              name="prescriptionNote"
              id="prescriptionNote"
            />
          </Col>
        </Row>
      </ContentBlock>
      <ContentBlock title="Recommended OTC Medication">
        <Input
          type="textarea"
          name="recommendedMedications"
          note={detailedTreatmentPlan["recommendedMedications"]}
          placeholder="Enter recommended OTC medication"
          onChange={(e) => handleOnChange(e.target.name, e.target.value)}
          value={note.detailedTreatmentPlan.recommendedMedications}
          initialNote={getinitialNote("recommendedMedications")}
          className="recommendedMedications"
        />
      </ContentBlock>
      <ContentBlock title="Warning">
        <NoteInput
          name="warning"
          note={detailedTreatmentPlan["warning"]}
          includeNote={!detailedTreatmentPlan["includeWarning"]}
          onChange={handleOnChange}
          initialNote={getinitialNote("warningSymptoms")}
          removeFromPlan={removeFromPlan("includeWarning")}
          placeholder="Enter warning details"
        />
      </ContentBlock>
      {currentSymptoms.map((symptom, idx) => {
        return (
          <SymptomBlock
            symptom={symptom}
            onSupportiveCareChange={onSupportiveCareChange}
            removeSupportiveCareFromPlan={removeSupportiveCareFromPlan}
            symptomSupportiveCare={detailedTreatmentPlan["symptomSupportiveCare"]}
            key={idx}
            patient={patient}
          />
        );
      })}

      <ContentBlock title="Prevent Spread">
        <NoteInput
          name="preventSpread"
          note={detailedTreatmentPlan["preventSpread"]}
          includeNote={!detailedTreatmentPlan["includePreventSpread"]}
          onChange={handleOnChange}
          initialNote={getinitialNote("preventSpread")}
          removeFromPlan={removeFromPlan("includePreventSpread")}
          placeholder="Enter prevent spread details"
        />
      </ContentBlock>
      <ContentBlock title="Expect Improvement">
        <NoteInput
          name="expectedImprovement"
          note={detailedTreatmentPlan["expectedImprovement"]}
          includeNote={!detailedTreatmentPlan["includeExpectedImprovement"]}
          onChange={handleOnChange}
          initialNote={getinitialNote("expectedImprovement")}
          removeFromPlan={removeFromPlan("includeExpectedImprovement")}
          placeholder="Enter notes of expected improvement"
        />
      </ContentBlock>
      <ContentBlock title="Return to School or Work">
        <NoteInput
          name="returnToActivities"
          note={detailedTreatmentPlan["returnToActivities"]}
          includeNote={!detailedTreatmentPlan["includeReturnToActivities"]}
          onChange={handleOnChange}
          initialNote={getinitialNote("returnToActivities")}
          removeFromPlan={removeFromPlan("includeReturnToActivities")}
          placeholder="Return to School or Work notes"
        />
      </ContentBlock>
      <ContentBlock title="Additional Patient Notes">
        <Input
          name="additionalNote"
          id="additionalNote"
          type="textarea"
          placeholder="Aditional notes for patients"
          includeNote={detailedTreatmentPlan["includeAdditionalNote"]}
          className="additional-note"
          onChange={onAdditionalNoteChange("additionalNote")}
        />
      </ContentBlock>
      {isScribe && (
        <ContentBlock title="Provider conducting encounter">
          <ScribeSection
            referenceID={props.referenceID}
            onSelectProvider={props.onSelectProvider}
            selectedProvider={props.selectedProvider}
          />
        </ContentBlock>
      )}
    </div>
  );
};

export default TreatmentPlan;
