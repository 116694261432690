import React, { useEffect, useState } from "react"
import { connect } from "react-redux";
import { Table } from "reactstrap"
import Octicon, { TriangleDown, TriangleUp } from "@githubprimer/octicons-react";
import Trash from "../../images/Trash.svg"
import "./Formulary.scss";
import moment from "moment"
import { NAVIGATE_TO, } from "../../constants/actionTypes";

const mapStateToProps = (state, ownProps) => { };

const mapDispatchToProps = (dispatch) => ({
    editFormulary: (id, kind) => dispatch({ type: NAVIGATE_TO, targetPath: `/formularies/${id}/${kind}` }),
    copyMedication: (id, kind) => dispatch({ type: NAVIGATE_TO, targetPath: `/formularies/copy/${id}/${kind}` })
});

const tableFields = [
    { label: "Active", field: "Active" },
    { label: "", field: "Duplicate" },
    { label: "", field: "Delete" },
    { label: "Condition", field: "Condition" },
    { label: "Product Name", field: "ProductName" },
    { label: "Product Name With Route", field: "ProductNameWithRoute" },
    { label: "Strength", field: "Strength" },
    { label: "RXCUI", field: "RXCUI" },
    { label: "NDC", field: "NDC" },
    { label: "Route", field: "Route" },
    { label: "Doseform", field: "Doseform" },
    { label: "Quantity", field: "Quantity" },
    { label: "Unit", field: "Unit" },
    { label: "Refills", field: "Refills" },
    { label: "Days Supply", field: "DaysSupply" },
    { label: "Usage Instructions", field: "UsageInstructions" },
    { label: "Protocol", field: "Protocol" },
    { label: "Reference Number", field: "ReferenceNumber" },
    { label: "Partner Price", field: "PartnerPrice" },
    { label: "Partner Product Name", field: "PartnerProductName" },
    { label: "Partner Service ID", field: "PartnerServiceID" },
    { label: "Partner Dosage", field: "PartnerDosage" },
    { label: "Partner Medication Route + Type", field: "PartnerMedicationRouteType" },
    { label: "Partner Default Status", field: "PartnerDefaultStatus" },
    { label: "Partner Hidden Status", field: "PartnerHiddenStatus" },
    { label: "Partner Change Notes", field: "PartnerChangeNotes" },
    { label: "Partner Field 9", field: "PartnerField9" },
    { label: "Partner Field 10", field: "PartnerField10" },
    { label: "Last Updated", field: "Updated" },
    { label: "Updated By", field: "UpdatedBy" },

]

//     <tr key={idx} className={item?.Active ? "form-tableRows" : "form-tableRows inActive"} onClick={() => handleEdit(item)}>
//         {Object.keys(item).map((field) => (
//             columns.includes(field) && <td>{item[field]}</td>
//         ))}
//     </tr>
// )


const ToggleSwitch = ({ active, item, handleUpdate }) => {
    const [on, setOn] = useState(active)

    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation()
        let updatedMed = { ...item, Active: !active }
        setOn(!active)
        handleUpdate(updatedMed)
    }

    return (
        <>
            <label className={on ? "switch on" : "switch off"} onClick={(e) => handleClick(e)}>
                <input type="checkbox" className="checkInput" />
                <span className={on ? "slider sOn" : "slider sOff"}></span>
            </label>
        </>
    )
}

const Sorter = ({ field, label, sortType, handleSort, setLoading, columns }) => {
    const noSort = ["Duplicate", "Delete"]
    const handleChange = (field, order) => {
        setLoading(true)
        handleSort(field, order)
    }

    if (!columns.includes(field)) {
        return null
    }

    return (
        <div className="form-sort-header">
            <div className="form-sort-label">{label}</div>
            {!noSort.includes(field) && <span className="sort">
                <span className={
                    sortType.field === field && sortType.order === "asc"
                        ? "sort-up sort-active "
                        : "sort-up"
                }
                    onClick={() => handleChange(field, "asc")}
                >
                    <Octicon icon={TriangleUp} size={12} />
                </span>
                <span className={
                    sortType.field === field && sortType.order === "desc"
                        ? "sort-down sort-active "
                        : "sort-down"
                }
                    onClick={() => handleChange(field, "desc")}
                >
                    <Octicon icon={TriangleDown} size={12} />
                </span>
            </span>}
        </div>
    )
}


const FormularyTable =
    ({
        editFormulary,
        copyMedication,
        loading,
        formItems,
        handleSort,
        sortType,
        setLoading,
        setEditing,
        setMedication,
        handleUpdate,
        columns,
        formulary,
        setCopying,
        setShowDeleteModal }) => {

        // const handleTableDivClick = (e, item, field) => {
        //     e.stopPropagation()
        //     if (field === "Delete") {
        //         handleTrashClick(item)
        //     } else if (field === "Duplicate") {
        //         handleCopy(item.Kind)
        //     }
        // }
        const handleEdit = (Kind) => {
            editFormulary(formulary.ID, Kind)
        }

        const handleCopy = (e, Kind) => {
            e.stopPropagation()
            copyMedication(formulary.ID, Kind)
        }

        const handleTrashClick = (e, med) => {
            e.stopPropagation()
            setShowDeleteModal(true)
            setMedication(med)
        }

        const visbleColumns = tableFields.filter((obj, idx) => (columns.includes(obj.field)))

        return (
            <>
                <Table className="align-content-stretch">
                    <thead className="form-tableHead">
                        <tr style={{ fontSize: "12px" }}>
                            {visbleColumns.map((item, idx) => {
                                return (
                                    <th key={`${idx}-${item.field}`}>
                                        <Sorter
                                            field={item.field}
                                            label={item.label}
                                            handleSort={handleSort}
                                            sortType={sortType}
                                            setLoading={setLoading}
                                            columns={columns}
                                        />
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {!loading && formItems && formItems?.map((item, idx) => {
                            return (
                                <tr key={idx} className={item?.Active ? "form-tableRows" : "form-tableRows inActive"} onClick={() => handleEdit(item.Kind)}>
                                    {columns.includes("Active") && <td key={`Active-${item.RXCUI}`}><ToggleSwitch active={item?.Active} item={item} handleUpdate={handleUpdate} /></td>}
                                    {columns.includes("Duplicate") && <td key={`Duplicate-${item.RXCUI}`} onClick={(e) => handleCopy(e, item.Kind)}>Duplicate</td>}
                                    {columns.includes("Delete") && <td key={`Delete-${item.RXCUI}`} onClick={(e) => handleTrashClick(e, item)}><Trash /></td>}
                                    {columns.includes("Condition") && <td key={`Condition-${item.RXCUI}`}>
                                        {item?.Condition?.map((con, idx) =>
                                            <span key={`condition-${idx}`}>{con}</span>
                                        )}
                                    </td>}
                                    {columns.includes("ProductName") && <td key={`ProductName-${item.RXCUI}`}>{item.ProductName || "--"}</td>}
                                    {columns.includes("ProductNameWithRoute") && <td key={`ProductNameWithRoute-${item.RXCUI}`}>{item.ProductNameWithRoute || "--"}</td>}
                                    {columns.includes("Strength") && <td key={`Strength-${item.RXCUI}`}>{item.Strength || "--"}</td>}
                                    {columns.includes("RXCUI") && <td key={`RXCUI-${item.RXCUI}`}>{item.RXCUI || "--"}</td>}
                                    {columns.includes("NDC") && <td key={`NDC-${item.RXCUI}`}>{item.NDC || "--"}</td>}
                                    {columns.includes("Route") && <td key={`Route-${item.RXCUI}`}>{item.Route || "--"}</td>}
                                    {columns.includes("Doseform") && <td key={`Doseform-${item.RXCUI}`}>{item.Doseform || "--"}</td>}
                                    {columns.includes("Quantity") && <td key={`Quantity-${item.RXCUI}`}>{item.Quantity || "--"}</td>}
                                    {columns.includes("Unit") && <td key={`Unit-${item.RXCUI}`}>{item.Unit || "--"}</td>}
                                    {columns.includes("Refills") && <td key={`Refills-${item.RXCUI}`}>{item.Refills || "--"}</td>}
                                    {columns.includes("DaysSupply") && <td key={`DaysSupply-${item.RXCUI}`}>{item.DaysSupply || "--"}</td>}
                                    {columns.includes("UsageInstructions") && <td key={`UsageInstructions-${item.RXCUI}`}>{item.UsageInstructions || "--"}</td>}
                                    {columns.includes("Protocol") && <td key={`Protocol-${item.RXCUI}`}>{item.Protocol}</td>}
                                    {columns.includes("ReferenceNumber") && <td key={`ReferenceNumber-${item.RXCUI}`}>{item.ReferenceNumber || "--"}</td>}
                                    {columns.includes("PartnerPrice") && <td key={`PartnerPrice-${item.PartnerPrice}`}>{item?.PartnerPrice ? item.PartnerPrice : "--"}</td>}
                                    {columns.includes("PartnerProductName") && <td key={`PartnerProductName-${item.PartnerProductName}`}>{item?.PartnerProductName ? item.PartnerProductName : "--"}</td>}
                                    {columns.includes("PartnerServiceID") && <td key={`PartnerServiceID-${item.PartnerServiceID}`}>{item?.PartnerServiceID ? item.PartnerServiceID : "--"}</td>}
                                    {columns.includes("PartnerDosage") && <td key={`PartnerDosage-${item.PartnerDosage}`}>{item?.PartnerDosage ? item.PartnerDosage : "--"}</td>}
                                    {columns.includes("PartnerMedicationRouteType") && <td key={`PartnerMedicationRouteType-${item.PartnerMedicationRouteType}`}>{item?.PartnerMedicationRouteType ? item.PartnerMedicationRouteType : "--"}</td>}
                                    {columns.includes("PartnerDefaultStatus") && <td key={`PartnerDefaultStatus-${item.PartnerDefaultStatus}`}>{item?.PartnerDefaultStatus ? item.PartnerDefaultStatus : "--"}</td>}
                                    {columns.includes("PartnerHiddenStatus") && <td key={`PartnerHiddenStatus-${item.PartnerHiddenStatus}`}>{item?.PartnerHiddenStatus ? item.PartnerHiddenStatus : "--"}</td>}
                                    {columns.includes("PartnerChangeNotes") && <td key={`PartnerChangeNotes-${item.PartnerChangeNotes}`}>{item?.PartnerChangeNotes ? item.PartnerChangeNotes : "--"}</td>}
                                    {columns.includes("PartnerField9") && <td key={`PartnerField9-${item.PartnerField9}`}>{item?.PartnerField9 ? item.PartnerField9 : "--"}</td>}
                                    {columns.includes("PartnerField10") && <td key={`PartnerField10-${item.PartnerField10}`}>{item?.PartnerField10 ? item.PartnerField10 : "--"}</td>}
                                    {columns.includes("Updated") && <td key={`Updated-${item.RXCUI}`}>{item?.Updated ? moment(item.Updated).format("MM-DD-YYYY") : "--"}</td>}
                                    {columns.includes("UpdatedBy") && <td key={`UpdatedBy-${item.RXCUI}`}>{item?.UpdatedBy ? item.UpdatedBy : "--"}</td>}

                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </>
        )
    }



export default connect(mapStateToProps, mapDispatchToProps)(FormularyTable);


